import { Page, SavedQueries, HistoryLog, Snippets } from '../components';
import { useTitle } from '../utilities';

export const QueryListPage = () => {
   useTitle('Saved Queries');

   return (
      <Page
         className="home-page"
         noScroll
         tabs={[
            {
               title: 'Saved Queries',
               content: (
                  <div className="h-100 px-4">
                     <SavedQueries initialFilterMe showDescription />
                  </div>
               ),
            },
            {
               title: 'History',
               content: (
                  <div className="h-100 px-4">
                     <HistoryLog initialFilterMe />
                  </div>
               ),
            },
            {
               title: 'Snippets',
               content: (
                  <div className="overflow-auto pb-4">
                     <Snippets />
                  </div>
               ),
            },
         ]}
         tabsClassName="justify-content-center mt-4"
      ></Page>
   );
};

export default QueryListPage;
