import { useState, useMemo, createContext, useContext, useCallback, memo } from 'react';
import { Button, Modal, Collapse, Spinner, Form, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useRunSystemQuery } from '../hooks';
import CodeViewer from '../components/UI/CodeViewer';
import { queryBuilderFactory } from '../utilities/QueryBuilder';
import { handleError } from '../utilities/error';
import { DBMS } from '../enums/dbms';
import { ConnectionAccessType } from '../entities';

type SchemaData = {
   dataConnection: { catalogName: string | null; dbms: DBMS; id: number };
   schemaName: string;
};

export const canCreateTable = ({
   connectionAccessType,
   dbms,
}: {
   connectionAccessType: ConnectionAccessType;
   dbms: DBMS;
}) => {
   if (
      ![ConnectionAccessType.INDIVIDUAL, ConnectionAccessType.SHARED].includes(connectionAccessType)
   ) {
      return false;
   }

   switch (dbms) {
      case DBMS.Big_Query:
      case DBMS.MSSQL:
      case DBMS.MySQL:
      case DBMS.Postgres:
      case DBMS.Redshift:
      case DBMS.Snowflake:
      case DBMS.Databricks:
      case DBMS.Oracle:
      case DBMS.Trino:
         return true;
      default:
         return false;
   }
};

type OnHideFn = (result?: { tableName: string }) => void;

const CreateTableModalBody = ({
   schemaData,
   onHide,
   workspaceId,
}: {
   onHide: OnHideFn;
   schemaData: SchemaData;
   workspaceId: number;
}): JSX.Element => {
   const [showQuery, setShowQuery] = useState(false);
   const [tableName, setTableName] = useState('new_table');

   const { run, isRunning } = useRunSystemQuery();

   const qb = useMemo(
      () => queryBuilderFactory(schemaData.dataConnection.dbms),
      [schemaData.dataConnection.dbms]
   );

   const query = useMemo(
      () =>
         qb.createTable({
            schema: schemaData.schemaName,
            table: tableName,
            catalog: schemaData.dataConnection.catalogName ?? undefined,
         }),
      [schemaData.schemaName, schemaData.dataConnection.catalogName, qb, tableName]
   );

   const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTableName(e.target.value);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
         const result = { tableName };

         await run({
            dataConnection: schemaData.dataConnection.id,
            query,
            workspaceId,
            updateSchema: {
               value: true,
               target: { schemaName: schemaData.schemaName, tableName, type: 'table' },
            },
         });

         toast.success('Table created successfully');
         onHide(result);
      } catch (e) {
         handleError(e);
      }
   };

   return (
      <Form id="createTableForm" onSubmit={handleSubmit}>
         <Modal.Header closeButton>
            <Modal.Title className="fs-14p">Create Table in {schemaData.schemaName}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row">
               <div className="col-12 fs-14p">
                  <Form.Group controlId="tableNameInput">
                     <Form.Label>Table Name</Form.Label>
                     <Form.Control
                        onChange={handleChangeName}
                        required
                        type="text"
                        value={tableName}
                     />
                  </Form.Group>
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <Stack className="justify-content-end" direction="horizontal" gap={2}>
               <Button
                  className="btn btn-sm btn-secondary me-1"
                  onClick={() => {
                     setShowQuery(!showQuery);
                  }}
               >
                  {showQuery ? `Hide` : `Show`} Query
               </Button>
               <Button
                  className="btn btn-sm btn-secondary me-1"
                  disabled={isRunning}
                  onClick={() => {
                     onHide();
                  }}
               >
                  Cancel
               </Button>
               <Button
                  className="btn btn-sm btn-primary"
                  disabled={!tableName || isRunning}
                  type="submit"
               >
                  {isRunning ? (
                     <>
                        <Spinner
                           animation="border"
                           aria-hidden="true"
                           as="span"
                           role="status"
                           size="sm"
                        />
                        <span className="visually-hidden">Loading...</span>&nbsp;
                     </>
                  ) : null}
                  Create Table
               </Button>
            </Stack>
            <Collapse in={showQuery}>
               <div className="card query-card border-1 mt-4 p-4 fs-14p">
                  <CodeViewer query={query} />
               </div>
            </Collapse>
         </Modal.Footer>
      </Form>
   );
};

type ModalProps = {
   onHide?: OnHideFn;
   schemaData: SchemaData | null;
   workspaceId?: number;
};
export const CreateTableModal = memo(
   ({ schemaData, onHide = () => {}, workspaceId }: ModalProps) => {
      return (
         <Modal
            onHide={() => {
               onHide();
            }}
            show={schemaData !== null}
         >
            {workspaceId !== undefined && schemaData !== null ? (
               <CreateTableModalBody
                  onHide={onHide}
                  schemaData={schemaData}
                  workspaceId={workspaceId}
               />
            ) : null}
         </Modal>
      );
   }
);

export default CreateTableModal;

type OpenModalInput = {
   onHide?: (result?: { tableName: string }) => Promise<void> | void;
   schemaData: SchemaData;
   workspaceId: number;
};
const CreateTableModalContext = createContext<
   { openModal: (params: OpenModalInput) => void } | undefined
>(undefined);

const defaultModalProps = {
   schemaData: null,
};
export const CreateTableModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
   const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);

   const openModal = useCallback(({ schemaData, workspaceId, onHide }: OpenModalInput) => {
      const onHideWrapped = async (result?: { tableName: string }) => {
         await onHide?.(result);
         setModalProps(defaultModalProps);
      };
      setModalProps({ schemaData, workspaceId, onHide: onHideWrapped });
   }, []);

   return (
      <CreateTableModalContext.Provider value={{ openModal }}>
         <>
            {children}
            <CreateTableModal {...modalProps} />
         </>
      </CreateTableModalContext.Provider>
   );
};

export const useCreateTableModal = () => {
   const context = useContext(CreateTableModalContext);

   if (context === undefined) {
      throw new Error('useCreateTableModal must be used within a CreateTableModalProvider');
   }

   return context.openModal;
};
