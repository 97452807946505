import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Collapse, Form, InputGroup, Button, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import { ConnectionAccessType, SSLMode } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { getErrorMessage, IconX } from '../../../../utilities';
import {
   connectionName,
   description,
   host,
   withHostRefinement,
   password,
   port,
   username,
} from '../validators';
import { AccessTypeField, HideDetailsField } from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   accountName: username,
   password: password,
});

const postgreSqlSchema = withHostRefinement(
   z
      .object({
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         dbms: z.literal(DBMS.Postgres),
         connectionName: connectionName,
         description: description,
         host: host,
         port: port,
         database: z
            .string()
            .min(1, 'required')
            .trim()
            .regex(/^[^.]*$/, 'Database cannot specify a schema'),
         sharedConnection: z.boolean().default(false),
         hideDetails: z.boolean().default(false),
         sslCaCert: z.any().optional(),
         sslCaCertId: z.number().optional(),
         useSSL: z.boolean().default(false),
      })
      .merge(credentialsSchema)
);

type PostgreSqlDetailFormData = z.infer<typeof postgreSqlSchema>;

function PostgreSqlDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const { handleSubmit, register, formState, reset, setValue, setError, watch } =
      useForm<PostgreSqlDetailFormData>({
         resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : postgreSqlSchema),
         mode: 'onTouched',
      });
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   const useSSL = !!watch('useSSL', false);
   const sslCaCertId = watch('sslCaCertId', undefined);
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const formData: PostgreSqlDetailFormData = {
            dbms: DBMS.Postgres,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? ''),
            database: selectedConnectionQuery.data.catalogName ?? '',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password:
               selectedConnectionQuery.data.dataCredentials?.[0]?.accountPassword === undefined
                  ? ''
                  : 'CURRENT',
            useSSL: selectedConnectionQuery.data.sslMode === SSLMode.VALIDATED,
            sslCaCertId: selectedConnectionQuery.data.sslCaCertId ?? undefined,
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: PostgreSqlDetailFormData) => {
      if (onSubmit) {
         onSubmit(data);
      }
   };

   const [clearedFields, setClearedFields] = useState<string[]>([]);

   const handleFocus =
      (checkValue: string, setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (e.target.value === checkValue) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof PostgreSqlDetailFormData, setValueOnFocus); // Update form state
            setClearedFields([...clearedFields, e.target.name]);
         }
      };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT', touchedField: string) =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (
            e.target.value === checkValue &&
            !(touchedField in touchedFields) &&
            clearedFields.includes(touchedField)
         ) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof PostgreSqlDetailFormData, setValueOnFocus); // Update form state
         } else if (!e.target.value) {
            setError(touchedField as keyof PostgreSqlDetailFormData, {
               type: 'manual',
               message: 'This field is required',
            });
            setValue(e.target.name as keyof PostgreSqlDetailFormData, undefined, {
               shouldDirty: true,
               shouldTouch: true,
            });
         }
      };

   // Render
   if (authPersonQuery.isLoading || selectedConnectionQuery.isLoading) return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
         <Stack gap={3}>
            {!onlyCreds && (
               <>
                  <input defaultValue={DBMS.Postgres} type="hidden" {...register('dbms')} />
                  <Form.Group>
                     <Form.Label>
                        Connection Name <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('connectionName')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.connectionName && !!errors.connectionName}
                        isValid={touchedFields.connectionName && !errors.connectionName}
                        placeholder="Connection Name"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.connectionName?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Description</Form.Label>
                     <Form.Control
                        {...register('description')}
                        as="textarea"
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.description && !!errors.description}
                        isValid={touchedFields.description && !errors.description}
                        placeholder="Description"
                        rows={3}
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.description?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>
                        Host <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('host')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.host && !!errors.host}
                        isValid={touchedFields.host && !errors.host}
                        placeholder="Host"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.host?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>
                        Port <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('port', { valueAsNumber: true })}
                        defaultValue="5432"
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.port && !!errors.port}
                        isValid={touchedFields.port && !errors.port}
                        placeholder="Port"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.port?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>
                        Database <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('database')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.database && !!errors.database}
                        isValid={touchedFields.database && !errors.database}
                        placeholder="Database"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.database?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>SSL Mode</Form.Label>
                     <Form.Select
                        disabled={editType !== 'connection'}
                        {...register('useSSL', {
                           setValueAs: (v) => v === true || v === 'true',
                        })}
                     >
                        <option value="false">Use SSL if available</option>
                        <option value="true">Require SSL and validate certificates</option>
                     </Form.Select>
                  </Form.Group>
                  <Collapse in={useSSL}>
                     <Form.Group>
                        <Form.Label>SSL CA Certificate</Form.Label>
                        {sslCaCertId ? (
                           <InputGroup>
                              <Form.Control placeholder="Uploaded file" readOnly type="text" />
                              <Button
                                 onClick={() =>
                                    setValue('sslCaCertId', undefined, { shouldDirty: true })
                                 }
                                 variant="secondary"
                              >
                                 <IconX />
                              </Button>
                           </InputGroup>
                        ) : (
                           <Form.Control {...register('sslCaCert')} type="file" />
                        )}
                     </Form.Group>
                  </Collapse>
                  <AccessTypeField
                     disabled={editType !== 'connection'}
                     {...register('connectionAccessType', {
                        setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                        onChange(event) {
                           if (parseInt(event.target.value) === ConnectionAccessType.INDIVIDUAL) {
                              setValue('hideDetails', false, { shouldDirty: true });
                           }

                           setValue('accountName', '', { shouldDirty: true });
                           setValue('password', '', { shouldDirty: true });
                        },
                     })}
                  />
                  {explorerIsAdmin && (
                     <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                        <div>
                           <HideDetailsField
                              {...register('hideDetails')}
                              disabled={editType !== 'connection'}
                           />
                        </div>
                     </Collapse>
                  )}
               </>
            )}
            <Form.Group>
               <Form.Label>User Name</Form.Label> <span className="text-danger">*</span>
               <Form.Control
                  {...register('accountName')}
                  disabled={editType === 'read-only'}
                  isInvalid={touchedFields.accountName && !!errors.accountName}
                  isValid={touchedFields.accountName && !errors.accountName}
                  placeholder="User Name"
                  required={true}
               />
               <Form.Control.Feedback type="invalid">
                  {errors.accountName?.message}
               </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
               <Form.Label>Password</Form.Label> <span className="text-danger">*</span>
               <Form.Control
                  {...register('password')}
                  disabled={editType === 'read-only'}
                  isInvalid={touchedFields.password && !!errors.password}
                  isValid={touchedFields.password && !errors.password}
                  onBlur={handleBlur('', 'CURRENT', 'password')}
                  onFocus={handleFocus('CURRENT', '')}
                  placeholder="Password"
                  required={true}
                  type="password"
               />
               <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
               </Form.Control.Feedback>
            </Form.Group>
         </Stack>
      </Form>
   );
}

export default PostgreSqlDetailForm;
