import { memo, useEffect, useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import SearchInput from './UI/SearchInput';
import { useDebounce } from '../hooks/debounce';
import { groupLogs, FacetItem } from './../entities';
import { QueryLogWidget, QueryDisplayOptions } from './QueryWidget';
import Paginator from './UI/Paginator';
import LoadingSpinner from './UI/LoadingSpinner';
import FacetDropdown from './UI/FacetDropdown';
import { useExplorer, usePerformLogSearch } from '../hooks';

const PAGE_SIZE = 10;

export const HistoryLog = memo(
   ({
      // myQueries = true only shows queries for the current user
      myQueries,
      workspaceId,
      // initialFilterMe = true sets an initial filter, but allows it to be
      // cleared
      initialFilterMe,
      ...displayOptions
   }: QueryDisplayOptions & {
      initialFilterMe?: boolean;
      myQueries?: boolean;
      workspaceId?: number;
   }): JSX.Element => {
      const explorer = useExplorer();
      const [searchText, setSearchText] = useState('');
      const debouncedSearchText = useDebounce(searchText, 750);
      const [skip, setSkip] = useState(0);

      const [dataConnectionIdsFilter, setDataConnectionIdsFilter] = useState<number[]>();
      const [schemaNamesFilter, setSchemaNamesFilter] = useState<string[]>();
      const [creatorIdsFilter, setCreatorIdsFilter] = useState<number[]>(
         (myQueries === true || initialFilterMe) && explorer ? [explorer.id] : []
      );
      const [tableNamesFilter, setTableNamesFilter] = useState<string[]>();

      const [allDataConnectionIds, setAllDataConnectionIds] = useState<FacetItem[]>();
      const [allSchemaNames, setAllSchemaNames] = useState<FacetItem[]>();
      const [allCreatorIds, setAllCreatorIds] = useState<FacetItem[]>();
      const [allTableNames, setAllTableNames] = useState<FacetItem[]>();
      const [latestTypeChange, setLatestTypeChange] = useState<string>();

      const [firstResponse, setFirstResponse] = useState<boolean>(true);
      const {
         data: log,
         isLoading: searchLoading,
         refetch,
      } = usePerformLogSearch({
         dataConnectionIds: dataConnectionIdsFilter ?? [],
         schemaNames: schemaNamesFilter ?? [],
         tableNames: tableNamesFilter ?? [],
         creatorIds: myQueries === true && explorer ? [explorer.id] : creatorIdsFilter,
         skipSize: skip,
         pageSize: PAGE_SIZE,
         workspaceIds: workspaceId !== undefined ? [workspaceId] : [],
         searchText: debouncedSearchText,
         callbacks: {
            onSuccess: (val) => {
               if (val?.facetInformation && (firstResponse || !latestTypeChange)) {
                  const { createdByPersonIds, dataConnectionIds, schemaNames, tableNames } =
                     val.facetInformation ?? {};

                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllDataConnectionIds(dataConnectionIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
                  setFirstResponse(false);
               }
            },
            onError: (error) => {
               console.error('Search Error:', error);
            },
         },
      });

      const cleanAndSetSearchText = (newValue: string) => {
         newValue = newValue.toLowerCase().trim();
         setSearchText(newValue);
         setSkip(0);
      };

      const sortLogs = !!searchText;

      const logs =
         useMemo(() => {
            return log?.items && groupLogs(log?.items, sortLogs);
         }, [log?.items, sortLogs]) ?? [];

      const loading = searchText !== debouncedSearchText || searchLoading || explorer === undefined;

      const filterOptions = (
         itemKey: any,
         filterValue: any[],
         setFilter: (value: any[]) => void
      ) => {
         const updatedFilter = filterValue?.includes(itemKey)
            ? filterValue.filter((id: any) => id !== itemKey)
            : [...(filterValue ?? []), itemKey];
         setFilter(updatedFilter);
      };

      useEffect(() => {
         const fetch = async () => {
            const refetchedData = await refetch();

            const newData = refetchedData.data;

            if (newData?.facetInformation) {
               const { createdByPersonIds, dataConnectionIds, schemaNames, tableNames } =
                  newData.facetInformation;
               if (latestTypeChange === 'Connection') {
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
               } else if (latestTypeChange === 'Schema') {
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllTableNames(tableNames ?? []);
               } else if (latestTypeChange === 'Table') {
                  setAllCreatorIds(createdByPersonIds ?? []);
               } else if (latestTypeChange === 'User') {
                  setAllDataConnectionIds(dataConnectionIds ?? []);
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
               } else {
                  setAllDataConnectionIds(dataConnectionIds ?? []);
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
               }
            }
         };
         fetch();
      }, [
         dataConnectionIdsFilter,
         schemaNamesFilter,
         tableNamesFilter,
         creatorIdsFilter,
         debouncedSearchText,

         latestTypeChange,
         skip,
         refetch,
      ]);

      const noFiltersSet =
         log === undefined
            ? true
            : !dataConnectionIdsFilter?.length &&
              !tableNamesFilter?.length &&
              !schemaNamesFilter?.length &&
              !(myQueries === true ? creatorIdsFilter?.length > 1 : creatorIdsFilter?.length);

      const initializeSearchUpdate = async (
         type: string,
         itemKey: any,
         filter: any[],
         setFilter: (value: any[]) => void
      ) => {
         filterOptions(itemKey, filter ?? [], setFilter);
         setLatestTypeChange(type);
         setSkip(0);
      };

      return (
         <Stack className="h-100">
            <div className="search-filters">
               <SearchInput
                  entityName={`${
                     myQueries ||
                     (creatorIdsFilter.length === 1 && creatorIdsFilter[0] === explorer?.id)
                        ? 'your query history'
                        : 'all query history'
                  }${workspaceId ? ' in this workspace' : ''}`}
                  onTextChanged={cleanAndSetSearchText}
               />
               <div className="dropdown-container">
                  <FacetDropdown
                     filteredOptions={dataConnectionIdsFilter ?? []}
                     options={allDataConnectionIds ?? []}
                     setFilteredOptions={(type, itemKey) => {
                        initializeSearchUpdate(
                           type,
                           itemKey,
                           dataConnectionIdsFilter ?? [],
                           setDataConnectionIdsFilter
                        );
                     }}
                     title={'Connection'}
                  />

                  {!myQueries && (
                     <FacetDropdown
                        filteredOptions={creatorIdsFilter ?? []}
                        options={allCreatorIds ?? []}
                        setFilteredOptions={(type, itemKey) => {
                           initializeSearchUpdate(
                              type,
                              itemKey,
                              creatorIdsFilter ?? [],
                              setCreatorIdsFilter
                           );
                        }}
                        title={'Runner'}
                     />
                  )}

                  <FacetDropdown
                     filteredOptions={schemaNamesFilter ?? []}
                     options={allSchemaNames ?? []}
                     setFilteredOptions={(type, itemKey) => {
                        initializeSearchUpdate(
                           type,
                           itemKey,
                           schemaNamesFilter ?? [],
                           setSchemaNamesFilter
                        );
                     }}
                     title={'Schema'}
                  />

                  <FacetDropdown
                     filteredOptions={tableNamesFilter ?? []}
                     options={allTableNames ?? []}
                     setFilteredOptions={(type, itemKey) => {
                        initializeSearchUpdate(
                           type,
                           itemKey,
                           tableNamesFilter ?? [],
                           setTableNamesFilter
                        );
                     }}
                     title={'Table'}
                  />

                  {!noFiltersSet && (
                     <div
                        className={`facet-dropdown-label flex-grow-1 ${
                           noFiltersSet ? 'disabled' : ''
                        }`}
                        data-label="Reset Filters"
                        onClick={async () => {
                           if (!noFiltersSet) {
                              setDataConnectionIdsFilter([]);
                              setSchemaNamesFilter([]);
                              setTableNamesFilter([]);
                              setCreatorIdsFilter([]);
                              setSkip(0);
                              setLatestTypeChange('User');
                           }
                        }}
                        style={{
                           textAlign: 'end',
                           cursor: noFiltersSet ? 'default' : 'pointer',
                        }}
                     >
                        Reset Filters
                     </div>
                  )}
               </div>
            </div>
            {loading && <LoadingSpinner />}
            {!loading && searchText && logs.length === 0 && <div>No matches</div>}
            {!loading && !searchText && logs.length === 0 && (
               <div className="fs-11p text-muted">
                  <span>It looks like you haven't run any queries in this workspace yet.</span>
               </div>
            )}
            {!loading && logs.length > 0 && (
               <>
                  <div
                     className="hide-scrollbar pb-3"
                     style={{ flex: 1, overflowY: 'auto', height: '100%' }}
                  >
                     <Stack className="dimmed-queries" gap={3}>
                        {logs.map((logItems) => (
                           <QueryLogWidget
                              collapse
                              key={`${logItems[0].id}`}
                              queryLogs={logItems}
                              showTags
                              {...displayOptions}
                           />
                        ))}
                     </Stack>
                  </div>
                  <div className="dimmed d-flex justify-content-center">
                     <Paginator
                        onChange={(page) => setSkip(page * PAGE_SIZE)}
                        page={Math.floor(skip / PAGE_SIZE)}
                        pageSize={PAGE_SIZE}
                        totalItems={log?.totalItems ?? 0}
                     />
                  </div>
               </>
            )}
         </Stack>
      );
   }
);

export default HistoryLog;
