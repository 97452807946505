import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Person, Workspace } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';
import { GetOptionsWorkspace, ListOptionsWorkspace, WorkspaceService } from '../../services';
import { TYPES } from '../../types';

const useWorkspaceService = () => {
   return useInjection<WorkspaceService>(TYPES.workspaceService);
};

export const useListWorkspaceQuery = (
   options?: ListOptionsWorkspace,
   callbacks?: { onError?: (err: unknown) => void; onSuccess?: (data: Workspace[]) => void }
) => {
   const workspaceService = useWorkspaceService();
   return useQuery<Workspace[]>(
      getWorkspaceQueryKey({ type: QueryKeyType.LIST, options: options }),
      () => workspaceService.listOptions(options),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export const useListEligibleCollaborators = ({
   callbacks,
   workspaceId,
}: {
   callbacks?: { onError?: (err: unknown) => void; onSuccess?: (data: Person[]) => void };
   workspaceId: number;
}) => {
   const service = useWorkspaceService();
   return useQuery<Person[]>(
      [QueryKey.EligibleCollaborators, workspaceId],
      () => service.listEligibleCollaborators(workspaceId),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onError: callbacks?.onError,
         onSuccess: callbacks?.onSuccess,
      }
   );
};

export const useUploadFileToWorkspaceMutator = () => {
   const queryClient = useQueryClient();
   const service = useWorkspaceService();

   return useMutation({
      mutationFn: async (vars: { id: number; uploadFile: FormData }) => {
         return service.uploadFile(vars.id, vars.uploadFile);
      },
      onSuccess(data, variables, context) {
         queryClient.invalidateQueries([QueryKey.Queries, 'list', variables.id]);
      },
   });
};

export function getWorkspaceQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsWorkspace | GetOptionsWorkspace;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.Workspace, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}
