"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RunaMode = void 0;
var RunaMode;
(function (RunaMode) {
    RunaMode["Runa"] = "runa";
    RunaMode["Optimize"] = "optimize";
    RunaMode["InlineComments"] = "inlineComments";
    RunaMode["FixError"] = "error";
    RunaMode["Explain"] = "explain";
})(RunaMode || (exports.RunaMode = RunaMode = {}));
