import { memo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useListQueryQuery } from '../../hooks';
import BusinessPageQuery from './BusinessPageQuery';
import BusinessSearchInput from '../../components/UI/BusinessSearchInput';
import { useDebounce } from '../../hooks/debounce';
import Paginator from '../../components/UI/Paginator';
import LoadingSpinner from '../../components/UI/LoadingSpinner';

const PAGE_SIZE = 10;

const BusinessPageQueries = memo(({ workspaceId }: { workspaceId?: number }): JSX.Element => {
   const [searchText, setSearchText] = useState('');
   const debouncedSearchText = useDebounce(searchText, 750);
   const [skip, setSkip] = useState(0);

   const cleanAndSetSearchText = (newValue: string) => {
      newValue = newValue.toLowerCase().trim();
      setSearchText(newValue);
      setSkip(0);
   };

   const savedQueriesQuery = useListQueryQuery({
      listOptions: {
         allWorkspaces: false,
         expandedPersonData: true,
         includeDataConnectionDetails: true,
         workspaceId: workspaceId,
         query: debouncedSearchText || undefined,
         skip,
         take: PAGE_SIZE,
         version: 'shared',
      },
   });

   if (savedQueriesQuery.isError) {
      return <>Error</>;
   }

   const loading =
      searchText !== debouncedSearchText ||
      savedQueriesQuery.isLoading ||
      savedQueriesQuery.isFetching;
   const results = savedQueriesQuery.data?.items ?? [];
   return (
      <Stack gap={3}>
         <div className="d-flex align-items-center">
            <span className="fs-14p me-1 text-dark">Business Answers</span>{' '}
            <span className="badge bg-primary">beta</span>
            {!workspaceId && (
               <span className="fs-11p text-muted ms-2">
                  Here you can ask questions for all the data you have access to.
               </span>
            )}
         </div>
         <BusinessSearchInput entityName="saved queries" onTextChanged={cleanAndSetSearchText} />
         {loading && <LoadingSpinner />}
         {!loading && searchText && results.length === 0 && (
            <div className="fs-11p">No matches</div>
         )}
         {!loading && !searchText && results.length === 0 && (
            <div className="fs-11p">
               <span className="">There are no answers for you to view at this time.</span>
            </div>
         )}
         {!loading && results.length > 0 && (
            <Stack className="query-review-page-queries" gap={3}>
               {results.map((query) => (
                  <BusinessPageQuery key={`${query.id}-saved-query`} query={query} />
               ))}
               <div className="mt-2 mb-4 d-flex justify-content-center">
                  <Paginator
                     onChange={(page) => setSkip(page * PAGE_SIZE)}
                     page={Math.floor(skip / PAGE_SIZE)}
                     pageSize={PAGE_SIZE}
                     totalItems={savedQueriesQuery.data?.totalItems ?? 0}
                  />
               </div>
            </Stack>
         )}
      </Stack>
   );
});

export default BusinessPageQueries;
