import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';

import { ApiService } from '../../../services';
import { TYPES } from '../../../types';

import type { SchemaRoot } from '../../../components/SchemaTree';

type FetchSchemaRootsQueryKeyParamsDataBase = {
   dataConnectionId: number;
};
type FetchSchemaRootsQueryKeyParamsFetch = {
   data: FetchSchemaRootsQueryKeyParamsDataBase & { includeHidden: boolean };
   type: 'fetch';
};
type FetchSchemaRootsQueryKeyParamsInvalidate = {
   data: FetchSchemaRootsQueryKeyParamsDataBase;
   type: 'invalidate';
};
type FetchSchemaRootsQueryKeyParams =
   | FetchSchemaRootsQueryKeyParamsFetch
   | FetchSchemaRootsQueryKeyParamsInvalidate;
export const fetchSchemaRootsQueryKey = ({ type, data }: FetchSchemaRootsQueryKeyParams) => {
   const baseKey = ['dataConnection', data.dataConnectionId, 'schemaRoots'];
   if (type === 'fetch') {
      return [...baseKey, data.includeHidden ? 'all' : 'visible'];
   }

   return baseKey;
};
export const useSchemaRoots = ({
   dataConnectionId,
   includeHidden = false,
}: {
   dataConnectionId: number;
   includeHidden?: boolean;
}) => {
   const apiService = useInjection<ApiService>(TYPES.apiService);

   return useQuery<SchemaRoot[]>(
      fetchSchemaRootsQueryKey({ type: 'fetch', data: { dataConnectionId, includeHidden } }),
      async () => {
         const results = await apiService.get<SchemaRoot[]>(
            `/v1/dataConnection/${dataConnectionId}/schemaRoots`,
            { ...(includeHidden ? { includeHidden: 'true' } : {}) }
         );
         return results ?? [];
      },
      { retry: false, refetchOnMount: 'always', staleTime: 1000 * 60 * 5 }
   );
};
