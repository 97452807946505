import { useInjection } from 'inversify-react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { WorkspaceStatus } from '../entities';
import { QueryKey } from '../enums';
import { WorkspaceService } from '../services';
import { TYPES } from '../types';
import { handleError } from '../utilities';

import type {
   DataConnection,
   QueryStep,
   QueryVersion,
   SchemaOverride,
   Workspace as WorkspaceEntity,
} from '../entities';
import type { FetchWorkspaceResult } from '../services/WorkspaceService';

type CurrentStep = {
   insert: (snippet: string) => void;
   queryStep: QueryStep;
   queryVersion: QueryVersion;
   replaceAll: (steps: QueryStep[]) => void;
   selection?: string;
};

export type ResultTableFilterType = Record<number, Record<number, Record<string, string>>>;

type WorkspaceContext = {
   currentStep?: CurrentStep;
   hasUnsavedTab: (tabId: number | undefined) => boolean;
   overrideSchema?: SchemaOverride;
   resultTableFilters?: ResultTableFilterType;
   setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep | undefined>>;
   setOverrideSchema?: (
      value:
         | {
              dataConnection: number | DataConnection;
              schemaName?: string;
           }
         | undefined
   ) => void;
   setResultTableFilters: React.Dispatch<React.SetStateAction<ResultTableFilterType | undefined>>;
   setUnsavedTab: (tabId: number | undefined, unsaved: boolean) => void;
   workspace: FetchWorkspaceResult;
};

const Ctx = createContext<WorkspaceContext | undefined>(undefined);

const workspaceFilters = {
   savedQueryCount: true,
   includeOrgPeople: true,
   connectionDetails: true,
};
export const fetchWorkspaceQueryKey = ({ workspaceId }: { workspaceId: number }) => [
   QueryKey.Workspace,
   'get',
   workspaceId,
   workspaceFilters,
];
export const WorkspaceProvider = ({
   workspaceId,
   children,
}: React.PropsWithChildren<{ workspaceId: number }>) => {
   const [currentStep, setCurrentStep] = useState<CurrentStep>();
   const [unsavedTabIds, setUnsavedTabIds] = useState<Set<number>>(new Set());
   const [resultTableFilters, setResultTableFilters] = useState<ResultTableFilterType>();
   const [overrideSchema, _setOverrideSchema] = useState<SchemaOverride>();
   const location = useLocation();
   useEffect(() => {
      if (location.state) {
         setResultTableFilters(location.state.filters ?? {});
      }
   }, [location.state]);

   const hasUnsavedTab = useCallback(
      (tabId: number | undefined) => {
         return tabId ? unsavedTabIds.has(tabId) : false;
      },
      [unsavedTabIds]
   );

   const addUnsavedTab = useCallback(
      (tabId: number | undefined) => {
         if (tabId && !hasUnsavedTab(tabId)) setUnsavedTabIds((prev) => new Set(prev).add(tabId));
      },
      [hasUnsavedTab]
   );

   const clearUnsavedTab = useCallback((tabId: number | undefined) => {
      if (tabId)
         setUnsavedTabIds((prev) => {
            const newSet = new Set(prev);
            newSet.delete(tabId);
            return newSet;
         });
   }, []);

   const setUnsavedTab = useCallback(
      (tabId: number | undefined, unsaved: boolean) => {
         if (unsaved) {
            addUnsavedTab(tabId);
         } else {
            clearUnsavedTab(tabId);
         }
      },
      [addUnsavedTab, clearUnsavedTab]
   );

   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const workspaceQueryResult = useQuery<FetchWorkspaceResult>(
      fetchWorkspaceQueryKey({ workspaceId }),
      () => workspaceService.fetchOptions(workspaceId, workspaceFilters),

      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );

   const setOverrideSchema = useCallback(
      (value: { dataConnection: number | DataConnection; schemaName?: string } | undefined) => {
         if (typeof value?.dataConnection === 'number') {
            const schemaConnection = workspaceQueryResult.data?.schemaConnections?.find(
               (c) => c.dataConnectionId === value.dataConnection
            );
            if (schemaConnection?.dataConnection) {
               _setOverrideSchema({
                  dataConnection: schemaConnection.dataConnection,
                  schemaName: value.schemaName,
               });
            } else {
               console.error('Data connection not found');
            }
         } else if (value?.dataConnection) {
            _setOverrideSchema({
               dataConnection: value.dataConnection,
               schemaName: value.schemaName,
            });
         } else {
            _setOverrideSchema(undefined);
         }
      },
      [workspaceQueryResult.data?.schemaConnections]
   );

   if (workspaceQueryResult.isError) {
      throw workspaceQueryResult.error;
   }

   const workspace = workspaceQueryResult.data;

   if (!workspace) {
      // Loading...
      return null;
   }

   if (workspace.status === WorkspaceStatus.DELETED) {
      throw new Error('Workspace has been deleted');
   }

   return (
      <Ctx.Provider
         value={{
            workspace,
            currentStep,
            hasUnsavedTab,
            setCurrentStep,
            setUnsavedTab,
            resultTableFilters,
            setResultTableFilters,
            overrideSchema,
            setOverrideSchema,
         }}
      >
         {children}
      </Ctx.Provider>
   );
};

export const useWorkspace = () => {
   const context = useContext(Ctx);

   if (context === undefined) {
      throw new Error('useWorkspace must be used within a WorkspaceContextProvider');
   }

   return context.workspace;
};

export const useCurrentStep = (): [
   CurrentStep | undefined,
   React.Dispatch<React.SetStateAction<CurrentStep | undefined>>
] => {
   const context = useContext(Ctx);

   return [context?.currentStep, context?.setCurrentStep ?? (() => {})];
};

export const useResultTableFilters = (): [
   ResultTableFilterType | undefined,
   React.Dispatch<React.SetStateAction<ResultTableFilterType | undefined>>
] => {
   const context = useContext(Ctx);

   if (context === undefined) {
      throw new Error('useResultTableFilters must be used within a WorkspaceContextProvider');
   }

   return [context.resultTableFilters, context.setResultTableFilters];
};

export const useHasUnsavedTab = () => {
   const context = useContext(Ctx);

   if (context === undefined) {
      throw new Error('useHasUnsavedTab must be used within a WorkspaceContextProvider');
   }

   return context.hasUnsavedTab;
};

export const useSetUnsavedTab = () => {
   const context = useContext(Ctx);

   if (context === undefined) {
      throw new Error('useSetUnsavedTab must be used within a WorkspaceContextProvider');
   }

   return context.setUnsavedTab;
};

export const useOverrideSchema = () => {
   const context = useContext(Ctx);

   if (context === undefined) {
      return undefined;
   }

   return context.overrideSchema;
};

export const useSetOverrideSchema = () => {
   const context = useContext(Ctx);

   if (context === undefined) {
      throw new Error('useSetOverrideSchema must be used within a WorkspaceContextProvider');
   }

   return context.setOverrideSchema;
};

export const useWorkspaces = ({ enabled }: { enabled?: boolean } = {}):
   | WorkspaceEntity[]
   | undefined => {
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const workspaceFilters = {
      savedQueryCount: true.toString(),
      includeCollaborators: true.toString(),
      includeDataSources: true.toString(),
   };
   const workspaceQuery = useQuery<WorkspaceEntity[]>(
      [QueryKey.Workspace, 'list', workspaceFilters],
      () =>
         workspaceService.list(workspaceFilters).catch((err) => {
            handleError(err);
            return [];
         }),
      {
         enabled: enabled ?? true,
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );
   return workspaceQuery.data;
};

export const useRecentWorkspaces = (): WorkspaceEntity[] | undefined => {
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const workspaceQuery = useQuery<WorkspaceEntity[]>(
      [QueryKey.Workspace, 'recent'],
      () =>
         workspaceService.recent().catch((err) => {
            handleError(err);
            return [];
         }),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );
   return workspaceQuery.data;
};
