import { forwardRef, memo, useState } from 'react';
import {
   Badge,
   Button,
   Collapse,
   Nav,
   NavItem,
   NavLink,
   TabContainer,
   TabContent,
   TabPane,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { PersonUtilities, QueryVersion } from '../../entities';
import { useRunQuery } from '../../hooks';
import { getShortDateTimeString } from '../../utilities/formatters';
import { QueryResults } from '../../components/QueryResults';
import { QueryChart } from '../../components/QueryChart';
import { IconSingleCertRequest } from '../../utilities';

const BusinessQueryVersion = memo(
   forwardRef(
      (
         {
            highlight,
            newerVersions,
            queryVersion,
            showTitle,
            title,
            workspaceId,
         }: {
            highlight?: boolean;
            newerVersions?: number;
            queryVersion: QueryVersion;
            showTitle?: boolean;
            title?: string;
            workspaceId?: number;
         },
         ref: React.ForwardedRef<HTMLDivElement>
      ): JSX.Element => {
         // State variables
         const [showResults, setShowResults] = useState(false);
         const [tab, setTab] = useState('results');

         // Data Services
         const runQuery = useRunQuery(queryVersion);
         const params = useParams();
         workspaceId ??= params.workspaceId ? Number(params.workspaceId) : undefined;
         if (showTitle !== false) {
            title ??= queryVersion?.title;
         }
         showTitle ??= !!title;
         if (!title) {
            if (queryVersion.created) {
               title = getShortDateTimeString(queryVersion.created);
            } else {
               title = 'Untitled';
            }
            if (queryVersion.createdByPerson) {
               title += ` - ${PersonUtilities.getFullName(queryVersion.createdByPerson)}`;
            }
         }

         const activeTabs = { results: 'Results', chart: 'Chart' };

         return (
            <div className="">
               <div className="px-3 py-3 d-flex align-items-center">
                  <span className="card-title fs-5 text-dark fw-bold mb-0">{title}</span>
                  <span className="ms-1">
                     <Badge bg="info" pill>
                        <span>
                           v{queryVersion.version}&nbsp;
                           {queryVersion.query?.approvedVersionId === queryVersion.id && (
                              <IconSingleCertRequest
                                 className="text-success"
                                 size="10px"
                                 style={{ marginLeft: '2px', marginBottom: '1px' }}
                              />
                           )}
                        </span>
                     </Badge>
                  </span>
                  <span className="ms-1">
                     {!!newerVersions && (
                        <Badge bg="primary" className="ms-2">
                           {newerVersions} newer
                        </Badge>
                     )}
                  </span>
                  <span className="ms-1 text-muted fs-11p">
                     {queryVersion.createdByPerson && (
                        <div className={highlight ? '' : ''}>
                           Query Author: {PersonUtilities.getFullName(queryVersion.createdByPerson)}
                        </div>
                     )}
                  </span>
                  <div className="ms-1 text-muted fs-11p">
                     {queryVersion.created && (
                        <>
                           &middot;&nbsp;
                           {queryVersion.created
                              ? getShortDateTimeString(queryVersion.created)
                              : ''}
                        </>
                     )}
                  </div>
               </div>
               <div className="px-3 pt-2 pb-0">
                  {queryVersion?.question && (
                     <p className="card-text fs-14p">
                        <strong>{queryVersion?.question}</strong>
                     </p>
                  )}
               </div>
               <div className="px-3 pb-2 pt-0">
                  {showTitle && queryVersion?.description && (
                     <p className="card-text">{queryVersion.description}</p>
                  )}
               </div>

               <div className="row">
                  <div className="col-8"></div>
                  <div className="col-4 d-flex justify-content-end pe-4 pb-2">
                     {runQuery.isRunning ? (
                        <LoadingSpinner />
                     ) : runQuery.results ? (
                        <Button onClick={() => setShowResults(!showResults)} variant="dark">
                           {showResults ? 'Hide Results' : 'Show Results'}
                        </Button>
                     ) : (
                        <Button
                           disabled={runQuery.isRunning}
                           onClick={() => {
                              runQuery.run();
                              setShowResults(true);
                           }}
                           variant="dark"
                        >
                           Get Answer
                        </Button>
                     )}
                  </div>
               </div>
               {runQuery.results ? (
                  <div>
                     <TabContainer
                        activeKey={tab}
                        onSelect={(t) => t && setTab(t)}
                        transition={false}
                     >
                        <Collapse in={showResults}>
                           <div>
                              <Nav
                                 className="bottom-explore-tabs explore-tabs bg-secondary d-flex justify-content-between mt-2"
                                 id="exploreTabNavQuery"
                                 role="tablist"
                              >
                                 {Object.entries(activeTabs).map(([id, name]) =>
                                    name ? (
                                       <NavItem
                                          bsPrefix="explore-tab"
                                          className={`bottom-explore-tab ${
                                             tab === id ? 'active' : ''
                                          }`}
                                          key={id}
                                       >
                                          <NavLink
                                             className={`position-relative override-active-pointer plausible-event-name--queryPanel${name}`}
                                             eventKey={id}
                                             role={'tab'}
                                          >
                                             {name}
                                          </NavLink>
                                       </NavItem>
                                    ) : (
                                       <div className="flex-grow-1" key={id} />
                                    )
                                 )}
                              </Nav>
                              <TabContent className="flex-grow-1 overflow-hidden pt-3 bottom-query-panel-tab-content">
                                 <TabPane
                                    className="h-100 overflow-hidden"
                                    eventKey="results"
                                    key="results"
                                 >
                                    <Collapse className="" in={showResults}>
                                       <div>
                                          <QueryResults
                                             queryReturn={
                                                runQuery.results[runQuery.results.length - 1]
                                             }
                                          />
                                       </div>
                                    </Collapse>
                                 </TabPane>
                                 <TabPane
                                    className="h-100 overflow-auto"
                                    eventKey="chart"
                                    key="chart"
                                 >
                                    <QueryChart
                                       containsPython={false}
                                       isCurrentPanel={true}
                                       queryReturn={runQuery.results[runQuery.results.length - 1]}
                                       queryVersion={queryVersion}
                                    />
                                    )
                                 </TabPane>
                              </TabContent>
                           </div>
                        </Collapse>
                     </TabContainer>
                  </div>
               ) : (
                  <></>
               )}
            </div>
         );
      }
   )
);
export default BusinessQueryVersion;
