import { inject, injectable } from 'inversify';
import { Base } from '../entities/Base';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { RunaMode } from '@runql/util';

const PATH = '/v1/ask';

export interface Prompt extends Base {
   dataConnectionId?: number;
   prompt: string;
}

export type RunaStep = {
   dataConnectionId?: number;
   defaultSchema?: string;
   order?: number;
   query?: string;
   schemas?: string[];
};

export type RunaStepData = {
   currentQuery?: string;
   dataConnectionId?: number;
   defaultSchema?: string;
   order?: number;
};

export interface AskRunaRequest {
   mode: RunaMode;
   previous?: AskRunaResponse[];
   prompt?: string;
   steps: RunaStep[];
}

export interface AskRunaResponse {
   answer?: string;
   isUpgradePrompt?: string;
   label: string;
   prompt: string;
   steps: RunaStepData[];
   timestamp: Date;
}

@injectable()
export class AskService {
   constructor(@inject(TYPES.apiService) private apiService: ApiService) {}

   async askRuna(
      body: AskRunaRequest,
      params?: Record<string, string>
   ): Promise<AskRunaResponse | null> {
      return (await this.apiService.post<AskRunaResponse>(`${PATH}/askRuna`, body, params)) ?? null;
   }
}
