import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
   LoadingSpinner,
   ResultTable,
   ResultTableRef,
   RqlAction,
   Button,
} from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { DBMS } from '../../../../enums';
import {
   useExploreTab,
   useFetchTableMetaQuery,
   useResultTableFilters,
   useRunSystemQuery,
   useUpdateSchema,
   useWorkspace,
   useFetchTableDataQuery,
   getDataConnectionSchemaQueryKey,
} from '../../../../hooks';
import {
   buildChangeQueries,
   ClientDataChange,
   getErrorMessage,
   handleError,
} from '../../../../utilities';
import { ConfirmChangesModal } from './ConfirmChangesModal';
import { FilterModel } from 'ag-grid-community';
import { fetchTableContentQueryKey } from '../../../../components/SchemaTree';

export function DataEditTab({
   onStatusChange,
   readOnly = false,
}: {
   onStatusChange?: (isDirty: boolean) => void;
   readOnly?: boolean;
}): JSX.Element {
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeCount, setChangeCount] = useState(0);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
   const formattedDate = lastRefresh.toLocaleString();
   const [pendingUpdate, _setPendingUpdate] = useState(false);
   const [pendingFilterModel, setPendingFilterModel] = useState<FilterModel | undefined>(undefined);
   const [filterModel, setFilterModel] = useState<FilterModel | undefined>(undefined);
   const [tableRefreshing, setTableRefreshing] = useState(false);
   const [isSchemaDataReady, setEnableContentFetch] = useState(false);
   const workspace = useWorkspace();
   const [resultTableFilters] = useResultTableFilters();
   const setPendingUpdate = useCallback(
      (isDirty: boolean) => {
         _setPendingUpdate(isDirty);
         onStatusChange?.(isDirty);
      },
      [onStatusChange]
   );
   const gridRef = useRef<ResultTableRef>(null);

   // `exploreTab` should always be set at this point. See
   // packages/web/src/pages/Workspace/Explore/Table/TableTabContent.tsx
   const exploreTab = useExploreTab();

   const navigatorTableContentQueryKey = fetchTableContentQueryKey({
      type: 'invalidate',
      data: {
         dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
         schemaName: exploreTab?.tableSchema?.schemaName!,
         tableName: exploreTab?.tableSchema?.tableName!,
      },
   });

   const dataConnectionSchemaQueryKey = getDataConnectionSchemaQueryKey({
      dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
   });

   const { tableMetaQuery, invalidate: invalidateTableMeta } = useFetchTableMetaQuery({
      tableSchema: exploreTab?.tableSchema!,
   });

   const { updateSchema, state: updateSchemaState } = useUpdateSchema({
      async onSuccessCallback() {
         await invalidateTableMeta([navigatorTableContentQueryKey, dataConnectionSchemaQueryKey]);
      },
   });

   const partialSchemaRefresh = useCallback(async (): Promise<void> => {
      if (exploreTab?.tableSchema?.dataConnectionId && exploreTab?.tableSchema?.tableName) {
         try {
            // Using updateSchemaMutator from the other file
            await updateSchema(exploreTab.tableSchema.dataConnectionId, {
               type: 'table',
               tableName: exploreTab.tableSchema.tableName,
               schemaName: exploreTab.tableSchema.schemaName,
            });
            setLastRefresh(new Date());
         } catch (error) {
            handleError(getErrorMessage(error));
         }
      }
   }, [exploreTab, updateSchema]);

   useEffect(() => {
      async function refreshSchema() {
         if (!isSchemaDataReady) {
            await partialSchemaRefresh();
            setEnableContentFetch(true);
         }
      }
      refreshSchema();
   }, [isSchemaDataReady, partialSchemaRefresh]);

   useEffect(() => {
      const filters = resultTableFilters?.[workspace.id || -1]?.[exploreTab?.tableSchemaId || -1];
      if (filters) {
         setFilterModel((prev: FilterModel) => {
            const newFilters = { ...prev };
            for (const [key, value] of Object.entries(filters)) {
               newFilters[key] = { type: 'set', values: [value] };
            }
            return newFilters;
         });
      }
   }, [resultTableFilters, exploreTab?.tableSchemaId, workspace.id]);

   const { run, isRunning } = useRunSystemQuery();

   const primaryKeyColumns = useMemo<string[]>(() => {
      const primaryKeys =
         tableMetaQuery.data?.columns
            ?.filter((col) => col.columnIsPrimaryKey)
            .map((col) => col.columnName) || [];
      return primaryKeys;
   }, [tableMetaQuery.data]);

   const { result: tableContentResult, invalidate: invalidateTableData } = useFetchTableDataQuery({
      tableSchema: exploreTab?.tableSchema!,
      workspaceId: exploreTab?.workspaceId!,
      enable: isSchemaDataReady,
   });

   function handleSaveChanges(): void {
      if (!tableMetaQuery.data?.columns || !tableMetaQuery.data?.tableCache) {
         handleError('No columns or table cache found');
         return;
      }
      const result = gridRef.current?.getUpdatedRows();
      if (result) {
         const { updates, filterModel: model } = result;
         setChangeCount(updates.length);
         const clientChanges: ClientDataChange[] = [];
         updates.forEach((update) => {
            let { rqlAction, ...data } = update as { rqlAction: RqlAction };
            const keyValues: Record<string, any> = {};

            primaryKeyColumns.forEach((key) => {
               if (rqlAction.key?.[key] !== undefined) {
                  keyValues[key] = rqlAction.key[key];
               } else {
                  keyValues[key] = update[key];
               }
            });

            if (!rqlAction) {
               handleError('Error creating update');
               return;
            }

            if (rqlAction.type === 'update') {
               data =
                  rqlAction.columns?.reduce((acc: Record<string, any>, column: string) => {
                     if (column in update) {
                        acc[column] = update[column];
                     }
                     return acc;
                  }, {}) ?? {};
            }

            clientChanges.push({
               data,
               key: keyValues,
               type: rqlAction.type,
               table: tableMetaQuery.data?.tableCache.tableName ?? '',
               schema: tableMetaQuery.data?.tableCache.schemaName ?? '',
               catalog: tableMetaQuery.data?.tableCache.catalogName ?? '',
            });
         });
         const queries = buildChangeQueries(
            clientChanges,
            tableMetaQuery.data?.columns ?? [],
            tableMetaQuery.data?.tableCache.dataConnection.dbms ?? DBMS.MySQL
         );
         setChangeQueries(queries);
         setShowConfirmModal(true);
         setPendingFilterModel(model);
      } else {
         toast.warn('No changes found');
      }
   }

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (exploreTab?.tableSchema?.dataConnectionId === undefined) {
         handleError('No data connection found');
         return;
      }
      try {
         await run({
            query: changeQueries.join('\n'),
            dataConnection: exploreTab.tableSchema.dataConnection,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
         });
         // We do not need to invalidate the navigator queries since we are only editing data.
         await Promise.all([invalidateTableMeta(), invalidateTableData()]);
         setLastRefresh(new Date());
         setShowConfirmModal(false);
         setPendingUpdate(false);
         setFilterModel(pendingFilterModel);
         setPendingFilterModel(undefined);
      } catch (error) {
         handleError(error);
         return;
      }

      toast.success('Changes saved successfully');
   }

   const handleCellEdit = useCallback(
      (event: { valueChanged: boolean }) => {
         if (event.valueChanged) setPendingUpdate(true);
      },
      [setPendingUpdate]
   );

   const refreshing =
      tableMetaQuery.data === undefined ||
      tableContentResult.data === undefined ||
      tableRefreshing ||
      updateSchemaState.isLoading;

   const primaryKeyMissing = tableMetaQuery.data && primaryKeyColumns.length === 0 && !readOnly;

   if (tableMetaQuery.isError) {
      return <LoadingError message={getErrorMessage(tableMetaQuery.error)} />;
   }
   if (tableContentResult.isError) {
      return <LoadingError message={getErrorMessage(tableContentResult.error)} />;
   }

   return (
      <div className="h-100">
         {primaryKeyMissing && (
            <Alert variant="warning">No primary keys found, table is in read only mode</Alert>
         )}
         {tableContentResult.data?.error && (
            <LoadingError message={getErrorMessage(tableContentResult.data.error)} />
         )}
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         <Stack className="h-100">
            {!readOnly && !primaryKeyMissing && !refreshing && (
               <Stack className="p-2 justify-content-between w-100" direction="horizontal">
                  <Stack direction="horizontal" gap={2}>
                     <Button
                        className="btn btn-sm btn-secondary "
                        colorScheme="secondary"
                        message="Refresh"
                        onClick={() => {
                           partialSchemaRefresh();
                        }}
                        size="sm"
                     />
                     <div
                        style={{
                           fontSize: '10px',
                           color: 'gray',
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                        }}
                     >
                        Last Refresh: {formattedDate}
                     </div>
                  </Stack>
                  {!readOnly && (
                     <Stack direction="horizontal" gap={2}>
                        <Button
                           colorScheme="secondary"
                           message="Add"
                           onClick={() => {
                              setPendingUpdate(true);
                              gridRef.current?.addNewRow();
                           }}
                           size="sm"
                        />
                        <Button
                           colorScheme="secondary"
                           message="Delete"
                           onClick={() => {
                              if (gridRef.current?.markRowsAsDeleted()) setPendingUpdate(true);
                           }}
                           size="sm"
                        />
                        <Button
                           colorScheme="secondary"
                           disabled={!pendingUpdate}
                           message="Cancel"
                           onClick={async () => {
                              setPendingUpdate(false);
                              await Promise.all([invalidateTableMeta(), invalidateTableData()]);
                           }}
                           size="sm"
                        />
                        <Button
                           disabled={!pendingUpdate}
                           message="Apply Changes"
                           onClick={handleSaveChanges}
                           size="sm"
                        />
                     </Stack>
                  )}
               </Stack>
            )}
            {!tableContentResult.data?.error && (
               <>
                  {(refreshing || updateSchemaState.isLoading) && <LoadingSpinner />}
                  <div className="flex-grow-1 overflow-hidden" hidden={refreshing}>
                     {tableContentResult.data?.rows ? (
                        <ResultTable
                           allowEditing={!readOnly}
                           filterModel={filterModel}
                           keyColumns={primaryKeyColumns}
                           onCellEdit={handleCellEdit}
                           onRefreshChange={setTableRefreshing}
                           queryReturn={tableContentResult.data}
                           ref={gridRef}
                        />
                     ) : (
                        <div className="ps-3">
                           {' '}
                           No Rows in {tableMetaQuery.data?.tableCache.tableName}. Click Add to
                           create a new row.
                        </div>
                     )}
                  </div>
               </>
            )}
         </Stack>
      </div>
   );
}
