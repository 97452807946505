import { memo, useState, useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useExplorer, usePerformSearch, useWorkspaces } from '../hooks';
import QueryWidget, { QueryDisplayOptions } from './QueryWidget';
import SearchInput from './UI/SearchInput';
import { useDebounce } from '../hooks/debounce';
import Paginator from './UI/Paginator';
import LoadingSpinner from './UI/LoadingSpinner';
import { VersionType } from '../services/QueryService';
import { FacetItem } from '../entities';
import FacetDropdown from './UI/FacetDropdown';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { walkthroughStep } from '../entities/Walkthrough';
import { AiPulse } from '../components';

const PAGE_SIZE = 10;

export const SavedQueries = memo(
   ({
      versions,
      workspaceId,
      initialFilterMe,
      ...displayOptions
   }: QueryDisplayOptions & {
      initialFilterMe?: boolean;
      versions?: VersionType;
      workspaceId?: number;
   }): JSX.Element => {
      versions ??= 'all';

      const [skip, setSkip] = useState(0);
      const explorer = useExplorer();

      const [search, setSearch] = useState('');
      const debouncedSearchText = useDebounce(search, 750);

      const [dataConnectionIdsFilter, setDataConnectionIdsFilter] = useState<number[]>();
      const [schemaNamesFilter, setSchemaNamesFilter] = useState<string[]>();
      const [creatorIdsFilter, setCreatorIdsFilter] = useState<number[]>(
         (versions === 'mine' || initialFilterMe) && explorer ? [explorer.id] : []
      );
      const [tableNamesFilter, setTableNamesFilter] = useState<string[]>();

      const [allDataConnectionIds, setAllDataConnectionIds] = useState<FacetItem[]>();
      const [allSchemaNames, setAllSchemaNames] = useState<FacetItem[]>();
      const [allCreatorIds, setAllCreatorIds] = useState<FacetItem[]>([]);
      const [allTableNames, setAllTableNames] = useState<FacetItem[]>();
      const [firstResponse, setFirstResponse] = useState<boolean>(true);
      const [latestTypeChange, setLatestTypeChange] = useState<string>();

      const [stepOne, setStepOne] = useWalkthroughStep(walkthroughStep.PERSONAL_DEMO_WORKSPACE);
      const workspaces = useWorkspaces({ enabled: stepOne && !workspaceId });
      const personalDemoWorkspace = workspaces?.filter((w) => w.name === 'Demo Playground')?.[0];

      const {
         data: searchResults,
         isLoading: searchLoading,
         refetch,
      } = usePerformSearch({
         dataConnectionIds: dataConnectionIdsFilter ?? [],
         schemaNames: schemaNamesFilter ?? [],
         tableNames: tableNamesFilter ?? [],
         creatorIds: versions === 'mine' && explorer ? [explorer.id] : creatorIdsFilter,
         skipSize: skip,
         pageSize: PAGE_SIZE,
         workspaceIds: workspaceId !== undefined ? [workspaceId] : [],
         searchText: debouncedSearchText,
         callbacks: {
            onSuccess: (val) => {
               if (val?.facetInformation && (firstResponse || !latestTypeChange)) {
                  const { createdByPersonIds, dataConnectionIds, schemaNames, tableNames } =
                     val.facetInformation ?? {};

                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllDataConnectionIds(dataConnectionIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
                  setFirstResponse(false);
               }
            },
            onError: (error) => {
               console.error('Search Error:', error);
            },
         },
      });

      useEffect(() => {
         const fetch = async () => {
            const refetchedData = await refetch();

            const newData = refetchedData.data;

            if (newData?.facetInformation) {
               const { createdByPersonIds, dataConnectionIds, schemaNames, tableNames } =
                  newData.facetInformation;
               if (latestTypeChange === 'Connection') {
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
               } else if (latestTypeChange === 'Schema') {
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllTableNames(tableNames ?? []);
               } else if (latestTypeChange === 'Table') {
                  setAllCreatorIds(createdByPersonIds ?? []);
               } else if (latestTypeChange === 'User') {
                  setAllDataConnectionIds(dataConnectionIds ?? []);
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
               } else {
                  setAllDataConnectionIds(dataConnectionIds ?? []);
                  setAllCreatorIds(createdByPersonIds ?? []);
                  setAllSchemaNames(schemaNames ?? []);
                  setAllTableNames(tableNames ?? []);
               }
            }
         };
         fetch();
      }, [
         dataConnectionIdsFilter,
         schemaNamesFilter,
         tableNamesFilter,
         creatorIdsFilter,
         latestTypeChange,
         debouncedSearchText,
         skip,
         refetch,
      ]);

      const cleanAndSetSearchText = (newValue: string) => {
         newValue = newValue.toLowerCase().trim();
         setSearch(newValue);
         setSkip(0);
      };
      const noFiltersSet =
         searchResults === undefined
            ? true
            : !dataConnectionIdsFilter?.length &&
              !tableNamesFilter?.length &&
              !schemaNamesFilter?.length &&
              !(versions === 'mine' ? creatorIdsFilter?.length > 1 : creatorIdsFilter?.length);

      const loading = search !== debouncedSearchText || searchLoading;

      const filterOptions = (
         itemKey: any,
         filterValue: any[],
         setFilter: (value: any[]) => void
      ) => {
         const updatedFilter = filterValue?.includes(itemKey)
            ? filterValue.filter((id: any) => id !== itemKey)
            : [...(filterValue ?? []), itemKey];
         setFilter(updatedFilter);
      };

      const initializeSearchUpdate = async (
         type: string,
         itemKey: any,
         filter: any[],
         setFilter: (value: any[]) => void
      ) => {
         filterOptions(itemKey, filter ?? [], setFilter);
         setLatestTypeChange(type);
         setSkip(0);
      };

      return (
         <Stack className="h-100">
            <div className="search-filters">
               <SearchInput
                  entityName={`${
                     versions === 'mine' ||
                     (creatorIdsFilter.length === 1 && creatorIdsFilter[0] === explorer?.id)
                        ? 'your saved queries'
                        : versions === 'shared'
                        ? 'all shared queries'
                        : 'all queries'
                  }${workspaceId ? ' in this workspace' : ''}`}
                  onTextChanged={cleanAndSetSearchText}
               />

               <div className="dropdown-container">
                  {!(versions === 'mine') && (
                     <FacetDropdown
                        filteredOptions={creatorIdsFilter ?? []}
                        options={allCreatorIds ?? []}
                        setFilteredOptions={(type, itemKey) => {
                           initializeSearchUpdate(
                              type,
                              itemKey,
                              creatorIdsFilter ?? [],
                              setCreatorIdsFilter
                           );
                        }}
                        title={'Author'}
                     />
                  )}

                  <FacetDropdown
                     filteredOptions={dataConnectionIdsFilter ?? []}
                     options={allDataConnectionIds ?? []}
                     setFilteredOptions={(type, itemKey) => {
                        initializeSearchUpdate(
                           type,
                           itemKey,
                           dataConnectionIdsFilter ?? [],
                           setDataConnectionIdsFilter
                        );
                     }}
                     title={'Connection'}
                  />

                  <FacetDropdown
                     filteredOptions={schemaNamesFilter ?? []}
                     options={allSchemaNames ?? []}
                     setFilteredOptions={(type, itemKey) => {
                        initializeSearchUpdate(
                           type,
                           itemKey,
                           schemaNamesFilter ?? [],
                           setSchemaNamesFilter
                        );
                     }}
                     title={'Schema'}
                  />

                  <FacetDropdown
                     filteredOptions={tableNamesFilter ?? []}
                     options={allTableNames ?? []}
                     setFilteredOptions={(type, itemKey) => {
                        initializeSearchUpdate(
                           type,
                           itemKey,
                           tableNamesFilter ?? [],
                           setTableNamesFilter
                        );
                     }}
                     title={'Table'}
                  />

                  {!noFiltersSet && (
                     <div
                        className={`facet-dropdown-label flex-grow-1 ${
                           noFiltersSet ? 'disabled' : ''
                        }`}
                        data-label="Reset Filters"
                        onClick={async () => {
                           if (!noFiltersSet) {
                              setDataConnectionIdsFilter([]);
                              setSchemaNamesFilter([]);
                              setTableNamesFilter([]);
                              setCreatorIdsFilter([]);
                              setSkip(0);
                              setLatestTypeChange('User');
                           }
                        }}
                        style={{
                           textAlign: 'end',
                           cursor: noFiltersSet ? 'default' : 'pointer',
                        }}
                     >
                        Reset Filters
                     </div>
                  )}
               </div>
            </div>

            {(searchLoading || loading) && <LoadingSpinner />}
            {!searchLoading && search && searchResults?.items?.length === 0 && (
               <div className="fs-11p">No matches</div>
            )}
            {!searchLoading && !loading && searchResults?.items?.length === 0 && (
               <div className="fs-11p">
                  <span className="">It looks like you don't have any saved queries yet.</span>
               </div>
            )}
            {!searchLoading &&
               !loading &&
               searchResults?.items &&
               searchResults?.items?.length > 0 && (
                  <>
                     <div
                        className="hide-scrollbar pb-3"
                        style={{ flex: 1, overflowY: 'auto', height: '100%' }}
                     >
                        <Stack className="dimmed-queries" gap={3}>
                           {stepOne && personalDemoWorkspace && (
                              <AiPulse on={stepOne} onClick={() => setStepOne()} sparkleAfter>
                                 <Link
                                    className="text-decoration-none"
                                    to={`/workspaces/${personalDemoWorkspace?.id}`}
                                 >
                                    <div className="display-block p-3 query-card text-center fs-14p fw-600 ">
                                       Click to start a one-minute tour of runQL.
                                       <br />
                                       Follow the rainbow to experience the top three use cases for
                                       runQL.
                                    </div>
                                 </Link>
                              </AiPulse>
                           )}
                           {searchResults.items.map((query) => (
                              <QueryWidget
                                 collapse
                                 key={`${query.query.id}-saved-query`}
                                 popoverPlacement="right"
                                 queryVersion={query.query}
                                 showTags
                                 source="saved"
                                 {...displayOptions}
                              />
                           ))}
                        </Stack>
                     </div>
                     <div className="dimmed d-flex justify-content-center">
                        <Paginator
                           onChange={(page) => setSkip(page * PAGE_SIZE)}
                           page={Math.floor(skip / PAGE_SIZE)}
                           pageSize={PAGE_SIZE}
                           totalItems={searchResults?.totalItems ?? 0}
                        />
                     </div>
                  </>
               )}
         </Stack>
      );
   }
);

export default SavedQueries;
