import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaHome } from 'react-icons/fa';
import { Stack } from 'react-bootstrap';

import Button from '../components/Button';

export const Error404 = (): JSX.Element => {
   const navigate = useNavigate();
   return (
      <div className="vh-100 w-100 d-flex flex-column align-items-center justify-content-center">
         <div className="parallax parallax-custom-1 mx-auto">
            <div className="parallax-layer parallax-layer-custom-1" data-depth="-0.15"></div>
            <div className="parallax-layer parallax-layer-custom-2" data-depth="0.12"></div>
            <div
               className="parallax-layer parallax-layer-custom-3 zindex-5"
               data-depth="-0.12"
            ></div>
         </div>
         <h2 className="display-5">This page does not exist</h2>
         <span style={{ fontSize: '18px' }}>
            Check the spelling of the URL and try again. If there should be a page here, please
            contact us at contact us at <a href="mailto:support@runql.com">support@runql.com</a>.
         </span>
         <Stack className="mt-4 justify-content-center" direction="horizontal" gap={3}>
            <Button colorScheme="primary" onClick={() => navigate(-1)} size="md">
               <Stack as="span" direction="horizontal" gap={2}>
                  <IoMdArrowRoundBack size="20px" />
                  <span style={{ fontSize: '18px' }}>Go Back</span>
               </Stack>
            </Button>
            <Button colorScheme="primary" onClick={() => navigate('/')} size="md">
               <Stack as="span" direction="horizontal" gap={2}>
                  <FaHome size="20px" />
                  <span style={{ fontSize: '18px' }}>Go Home</span>
               </Stack>
            </Button>
         </Stack>
      </div>
   );
};

export default Error404;
