import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { Alert, Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LoadingSpinner, TypeSelect, Button } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { ColumnNull } from '../../../../entities';
import { DBMS } from '../../../../enums';
import {
   useExploreTab,
   useFetchTableMetaQuery,
   useRunSystemQuery,
   useUpdateSchema,
   getDataConnectionSchemaQueryKey,
} from '../../../../hooks';
import { GetTableMetaResponse } from '../../../../services';
import {
   buildChangeQueries,
   ClientDataChange,
   ColumnModify,
   getDataTypes,
   handleError,
   IconKey,
   MsSQLColumnModify,
   MySQLColumnModify,
   RedshiftColumnModify,
} from '../../../../utilities';
import { ColumnFormData, columnSchema } from './columnSchema';
import { ConfirmChangesModal } from './ConfirmChangesModal';
import { fetchTableContentQueryKey } from '../../../../components/SchemaTree';

function createEmptyColumn(dbms: DBMS | undefined): ColumnFormData['columns'][0] {
   const baseColumn = {
      runqlId: undefined,
      columnName: '',
      columnType: '',
      columnNullable: ColumnNull.NOT_NULL,
      columnDefault: '',
      columnIsPrimaryKey: false,
      markDeleted: false,
      isNew: true,
   };

   if (dbms === DBMS.MySQL || dbms === DBMS.Redshift) {
      return {
         ...baseColumn,
         columnComment: '',
      };
   } else if (dbms === DBMS.MSSQL) {
      return {
         ...baseColumn,
         columnDescription: '',
      };
   }
   return baseColumn as ColumnFormData['columns'][0];
}

function getResponseAsFormData(data: GetTableMetaResponse, dbms: DBMS) {
   const baseData = {
      id: data.tableCache.id!,
      table: data.tableCache.tableName,
      columns:
         data.columns
            ?.map((column) => {
               const baseColumn = {
                  runqlId: column.id,
                  columnName: column.columnName,
                  columnType: column.columnType.toUpperCase(),
                  columnNullable: column.columnNullable ?? ColumnNull.NOT_NULL,
                  columnDefault: column.columnDefault ?? '',
                  columnExtra: column.columnExtra ?? '',
                  columnComment: column.columnComment ?? '',
                  columnIsPrimaryKey: column.columnIsPrimaryKey ?? false,
                  markDeleted: false,
                  isNew: false,
               };

               if (dbms === DBMS.MySQL || dbms === DBMS.Redshift) {
                  return {
                     ...baseColumn,
                     columnComment: column.columnComment ?? '',
                  };
               } else if (dbms === DBMS.MSSQL) {
                  return {
                     ...baseColumn,
                     columnDescription: column.columnComment ?? '',
                  };
               } else if (dbms === DBMS.Snowflake || dbms === DBMS.Databricks) {
                  return {
                     ...baseColumn,
                     columnDescription: column.columnComment ?? '',
                  };
               } else if (dbms === DBMS.Oracle) {
                  return {
                     ...baseColumn,
                     columnDescription: column.columnComment ?? '',
                  };
               }

               return baseColumn;
            })
            .filter((column) => column !== undefined) ?? [],
   };
   if (
      dbms === DBMS.MySQL ||
      dbms === DBMS.Redshift ||
      dbms === DBMS.MSSQL ||
      dbms === DBMS.Snowflake ||
      dbms === DBMS.Postgres ||
      dbms === DBMS.Oracle ||
      dbms === DBMS.Databricks
   ) {
      const formData = { ...baseData, dbms } as ColumnFormData;
      return formData;
   }

   return {};
}

export function SchemaColumnTab({
   onStatusChange,
   readOnly = false,
}: {
   onStatusChange?: (isDirty: boolean) => void;
   readOnly?: boolean;
}) {
   const [changeCount, setChangeCount] = useState(0);
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const { run, isRunning } = useRunSystemQuery();
   const [options, setOptions] = useState<string[]>();
   const {
      handleSubmit,
      register,
      control,
      reset,
      formState: { isDirty, dirtyFields, defaultValues, isSubmitted, errors },
   } = useForm<ColumnFormData>({
      resolver: zodResolver(columnSchema),
      mode: 'onTouched',
      defaultValues: {},
   });
   const { fields, append, update, remove } = useFieldArray({
      control,
      name: 'columns',
   });

   const previousIsDirty = useRef(isDirty);

   //Context
   const exploreTab = useExploreTab();

   // Queries
   const { tableMetaQuery, invalidate: invalidateTableMeta } = useFetchTableMetaQuery({
      tableSchema: exploreTab?.tableSchema!,
   });
   const dbms = tableMetaQuery.data?.tableCache.dataConnection.dbms;

   const navigatorTableContentQueryKey = fetchTableContentQueryKey({
      type: 'invalidate',
      data: {
         dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
         schemaName: exploreTab?.tableSchema?.schemaName!,
         tableName: exploreTab?.tableSchema?.tableName!,
      },
   });

   const dataConnectionSchemaQueryKey = getDataConnectionSchemaQueryKey({
      dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
   });

   const { updateSchema, state: updateSchemaState } = useUpdateSchema({
      async onSuccessCallback() {
         await invalidateTableMeta([navigatorTableContentQueryKey, dataConnectionSchemaQueryKey]);
      },
   });

   // Effects
   useEffect(() => {
      if (!tableMetaQuery.data) return;

      // set options
      setOptions(getDataTypes(tableMetaQuery.data?.tableCache.dataConnection.dbms));
      const dbms = tableMetaQuery.data?.tableCache.dataConnection.dbms ?? DBMS.MySQL;
      const formData = getResponseAsFormData(tableMetaQuery.data, dbms);
      reset(formData);
   }, [reset, tableMetaQuery.data]);

   useEffect(() => {
      if (previousIsDirty.current !== isDirty) {
         previousIsDirty.current = isDirty;
         onStatusChange?.(isDirty);
      }
   }, [isDirty, onStatusChange]);

   // Handlers
   function handleSave(data: ColumnFormData) {
      if (dirtyFields.columns === undefined) return;
      if (defaultValues?.table === undefined) {
         handleError('Table name is missing');
         return;
      }

      const tableChanges: ClientDataChange = {
         type: 'modify',
         catalog: tableMetaQuery.data?.tableCache.catalogName,
         table: defaultValues.table,
         schema: tableMetaQuery.data?.tableCache.schemaName ?? '',
         columns: dirtyFields.columns.reduce<Record<string, ColumnModify>>((acc, value, index) => {
            const column = data.columns[index];
            const originalColumn = tableMetaQuery.data?.columns?.find(
               (col) => col.id === column.runqlId
            );
            if (!column) {
               handleError('Column not found');
               return acc;
            }
            const columnName = originalColumn?.columnName ?? column.columnName;
            let hasChanges = false;

            function setCommonChanges(modify: ColumnModify) {
               if (value.columnName && originalColumn?.columnName !== column.columnName) {
                  modify.rename = column.columnName;
                  hasChanges = true;
               }
               if (value.columnType && originalColumn?.columnType !== column.columnType) {
                  modify.dataType = column.columnType;
                  hasChanges = true;
               }
               if (
                  value.columnNullable &&
                  originalColumn?.columnNullable !== column.columnNullable
               ) {
                  modify.nullable = column.columnNullable === ColumnNull.NULL;
                  hasChanges = true;
               }
               if (value.columnDefault && originalColumn?.columnDefault !== column.columnDefault) {
                  modify.default = column.columnDefault;
                  hasChanges = true;
               }
               if (column.isNew) {
                  modify.isNew = true;
                  hasChanges = true;
               }
               if (column.markDeleted) {
                  modify.drop = true;
                  hasChanges = true;
               }
            }
            if (dbms === DBMS.MySQL || dbms === DBMS.Postgres || dbms === DBMS.Oracle) {
               const modify: MySQLColumnModify = {};
               setCommonChanges(modify);
               if (
                  'columnExtra' in value &&
                  value.columnExtra &&
                  'columnExtra' in column &&
                  originalColumn?.columnExtra !== column.columnExtra
               ) {
                  modify.extra = column.columnExtra;
                  hasChanges = true;
               }
               if (
                  'columnComment' in value &&
                  value.columnComment &&
                  'columnComment' in column &&
                  originalColumn?.columnComment !== column.columnComment
               ) {
                  modify.comment = column.columnComment;
                  hasChanges = true;
               }

               if (hasChanges) {
                  acc[columnName] = modify;
               }
            }
            if (dbms === DBMS.MSSQL || dbms === DBMS.Postgres || DBMS.Snowflake) {
               const modify: MsSQLColumnModify = {};
               setCommonChanges(modify);

               if (
                  'columnDescription' in value &&
                  value.columnDescription &&
                  'columnDescription' in column &&
                  originalColumn?.columnComment !== column.columnDescription
               ) {
                  modify.description = column.columnDescription;
                  hasChanges = true;
               }

               if (hasChanges) {
                  acc[columnName] = modify;
               }
            }
            if (dbms === DBMS.Redshift || DBMS.Databricks) {
               const modify: RedshiftColumnModify = {};
               setCommonChanges(modify);
               if (
                  'columnComment' in value &&
                  value.columnComment &&
                  'columnComment' in column &&
                  originalColumn?.columnComment !== column.columnComment
               ) {
                  modify.comment = column.columnComment;
                  hasChanges = true;
               }

               if (hasChanges) {
                  acc[columnName] = modify;
               }
            }
            return acc;
         }, {}),
      };

      if (!tableMetaQuery.data?.tableCache.dataConnection.dbms) {
         handleError('No DBMS found');
         return;
      }
      const queries = buildChangeQueries(
         [tableChanges],
         tableMetaQuery.data?.columns ?? [],
         tableMetaQuery.data?.tableCache.dataConnection.dbms
      );
      setChangeQueries(queries);
      setChangeCount(queries.length);
      setShowConfirmModal(true);
   }

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (exploreTab?.tableSchema?.dataConnectionId === undefined) {
         handleError('No data connection found');
         return;
      }
      try {
         const queryReturn = await run({
            query: changeQueries.join('\n'),
            dataConnection: exploreTab.tableSchema.dataConnection,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
            updateSchema: {
               value: true,
               target: {
                  type: 'table',
                  schemaName: exploreTab.tableSchema.schemaName,
                  tableName: exploreTab.tableSchema.tableName,
               },
            },
         });
         if (queryReturn.error) {
            handleError(queryReturn.error);
            return;
         }
         await invalidateTableMeta([navigatorTableContentQueryKey, dataConnectionSchemaQueryKey]);
         setShowConfirmModal(false);
      } catch (error) {
         handleError(error);
         return;
      }

      toast.success('Changes saved successfully');
   }

   function handleColumnRemove(index: number): void {
      if (fields[index].isNew) {
         remove(index);
      } else {
         update(index, {
            ...fields[index],
            markDeleted: !fields[index].markDeleted,
         });
      }
   }

   // Render
   if (!exploreTab) return <LoadingError />;
   if (tableMetaQuery.isLoading || updateSchemaState.isLoading) return <LoadingSpinner />;
   if (tableMetaQuery.data === undefined) return <div>No data</div>;
   return (
      <div className="container h-100">
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         <Form className="h-100" onSubmit={handleSubmit(handleSave)}>
            <Stack className="h-100">
               {' '}
               <Stack className="pt-2" direction="horizontal">
                  {' '}
                  {dbms === DBMS.Databricks && (
                     <Alert className=" w-100" variant="warning">
                        Viewing default column values is currently not supported in Databricks
                     </Alert>
                  )}
                  <Stack
                     className="container-fluid mt-3 mb-3 justify-content-end"
                     direction="horizontal"
                     gap={1}
                  >
                     <Button
                        colorScheme="secondary"
                        onClick={async () => {
                           const dataConnectionId =
                              tableMetaQuery.data?.tableCache.dataConnectionId;
                           if (!dataConnectionId) {
                              handleError('No data connection found');
                              return;
                           }

                           if (
                              typeof tableMetaQuery.data?.tableCache.tableName !== 'string' ||
                              typeof tableMetaQuery.data?.tableCache.schemaName !== 'string'
                           ) {
                              // Should never happen.
                              return;
                           }

                           await updateSchema(dataConnectionId, {
                              type: 'table',
                              tableName: tableMetaQuery.data?.tableCache.tableName,
                              schemaName: tableMetaQuery.data?.tableCache.schemaName,
                           });
                        }}
                        size="sm"
                     >
                        Refresh
                     </Button>

                     {!readOnly && (
                        <>
                           <Button
                              colorScheme="secondary"
                              onClick={() => {
                                 append(createEmptyColumn(dbms));
                              }}
                              size="sm"
                           >
                              Add
                           </Button>
                           <Button
                              colorScheme="secondary"
                              disabled={!isDirty}
                              onClick={() => {
                                 reset();
                              }}
                              size="sm"
                           >
                              Cancel
                           </Button>
                           <Button disabled={!isDirty} size="sm" type="submit">
                              Apply Changes
                           </Button>
                        </>
                     )}
                  </Stack>
               </Stack>
               {/* Print column headers */}
               <div className="p-3 pb-0 pt-1 fs-10p">
                  <Row className="justify-content-center">
                     <Col xs={1}>{/* Icon */}</Col>
                     <Col xs={2}>
                        <span>Column</span>
                     </Col>
                     <Col xs={2}>
                        <span>Data Type</span>
                     </Col>
                     <Col xs={1}>Null?</Col>
                     <Col xs={2}>
                        <span>Default</span>
                     </Col>

                     {dbms === DBMS.MySQL && (
                        <Col xs={1}>
                           <span>Extra</span>
                        </Col>
                     )}
                     {(dbms === DBMS.MySQL ||
                        dbms === DBMS.Redshift ||
                        dbms === DBMS.Databricks) && (
                        <Col xs={2}>
                           <span>Comment</span>
                        </Col>
                     )}
                     {dbms === DBMS.MSSQL && (
                        <Col xs={2}>
                           <span>Description</span>
                        </Col>
                     )}

                     <Col xs={1}>{/* Actions */}</Col>
                  </Row>
               </div>
               <div className="flex-grow-1 overflow-auto">
                  {fields.map((column, index) => (
                     <Card
                        className={`border-1 mt-2 mb-2 p-3 shadow-sm fs-10p ${
                           column.markDeleted ? 'bg-danger text-white' : ''
                        }`}
                        key={index}
                     >
                        <Row className="justify-content-center">
                           <Col xs={1}>{column.columnIsPrimaryKey ? <IconKey /> : <></>}</Col>
                           <Col xs={2}>
                              <Form.Control
                                 {...register(`columns.${index}.columnName`)}
                                 isInvalid={isSubmitted && !!errors.columns?.[index]?.columnName}
                                 isValid={isSubmitted && !errors.columns?.[index]?.columnName}
                                 readOnly={readOnly}
                              />
                           </Col>
                           <Col xs={2}>
                              <Controller
                                 control={control}
                                 name={`columns.${index}.columnType`}
                                 render={({ field }) => (
                                    <TypeSelect
                                       allowCustom
                                       disabled={
                                          readOnly || (dbms === DBMS.Databricks && !column.isNew)
                                       }
                                       isInvalid={
                                          isSubmitted && !!errors.columns?.[index]?.columnType
                                       }
                                       onChange={(value) => field.onChange(value)}
                                       options={options ?? []}
                                       placeHolder="Data Type"
                                       value={field.value}
                                    />
                                 )}
                              />
                           </Col>
                           <Col xs={1}>
                              <Form.Control
                                 as="select"
                                 {...register(`columns.${index}.columnNullable`)}
                                 disabled={readOnly || (dbms === DBMS.Redshift && !column.isNew)}
                                 isInvalid={
                                    isSubmitted && !!errors.columns?.[index]?.columnNullable
                                 }
                                 isValid={isSubmitted && !errors.columns?.[index]?.columnNullable}
                              >
                                 <option value={ColumnNull.NULL}>YES</option>
                                 <option value={ColumnNull.NOT_NULL}>NO</option>
                              </Form.Control>
                           </Col>
                           <Col xs={2}>
                              <Form.Control
                                 {...register(`columns.${index}.columnDefault`)}
                                 isInvalid={isSubmitted && !!errors.columns?.[index]?.columnDefault}
                                 isValid={isSubmitted && !errors.columns?.[index]?.columnDefault}
                                 readOnly={readOnly || (dbms === DBMS.Redshift && !column.isNew)}
                              />
                           </Col>
                           {dbms === DBMS.MySQL && (
                              <Col xs={1}>
                                 <Form.Control
                                    {...register(`columns.${index}.columnExtra`)}
                                    isInvalid={
                                       isSubmitted &&
                                       !!(errors.columns?.[index] as any)?.columnExtra
                                    }
                                    isValid={
                                       isSubmitted && !(errors.columns?.[index] as any)?.columnExtra
                                    }
                                    readOnly={readOnly}
                                 />
                              </Col>
                           )}
                           {(dbms === DBMS.MySQL ||
                              dbms === DBMS.Redshift ||
                              dbms === DBMS.Databricks) && (
                              <Col xs={2}>
                                 <Form.Control
                                    {...register(`columns.${index}.columnComment`)}
                                    isInvalid={
                                       isSubmitted &&
                                       !!(errors.columns?.[index] as any)?.columnComment
                                    }
                                    isValid={
                                       isSubmitted &&
                                       !(errors.columns?.[index] as any)?.columnComment
                                    }
                                    readOnly={readOnly}
                                 />
                              </Col>
                           )}
                           {dbms === DBMS.MSSQL && (
                              <Col xs={2}>
                                 <Form.Control
                                    {...register(`columns.${index}.columnDescription`)}
                                    readOnly={readOnly}
                                 />
                              </Col>
                           )}

                           <Col xs={1}>
                              {!readOnly && (
                                 <Button
                                    colorScheme="danger"
                                    onClick={() => handleColumnRemove(index)}
                                    size="sm"
                                 >
                                    X
                                 </Button>
                              )}
                           </Col>
                        </Row>
                     </Card>
                  ))}
               </div>
            </Stack>
         </Form>
      </div>
   );
}
