import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Stack } from 'react-bootstrap';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaHome } from 'react-icons/fa';

import Button from '../components/Button';

export const Error5xx = ({ homeTo = '/' }: { homeTo?: string }): JSX.Element => {
   const error = useRouteError() as Error;
   useEffect(() => {
      Sentry.captureException(error);
   }, [error]);

   const navigate = useNavigate();

   return (
      <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
         <h2 className="display-5">An error occurred</h2>
         <span style={{ fontSize: '18px' }}>
            If this error persists, please contact us at{' '}
            <a href="mailto:support@runql.com">support@runql.com</a>.
         </span>
         <Stack className="mt-4 justify-content-center" direction="horizontal" gap={3}>
            <Button colorScheme="primary" onClick={() => navigate(-1)} size="md">
               <Stack as="span" direction="horizontal" gap={2}>
                  <IoMdArrowRoundBack size="20px" />
                  <span style={{ fontSize: '18px' }}>Go Back</span>
               </Stack>
            </Button>
            <Button colorScheme="primary" onClick={() => navigate(homeTo)} size="md">
               <Stack as="span" direction="horizontal" gap={2}>
                  <FaHome size="20px" />
                  <span style={{ fontSize: '18px' }}>Go Home</span>
               </Stack>
            </Button>
         </Stack>
      </div>
   );
};

export default Error5xx;
