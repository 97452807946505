import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import {
   connectionName,
   description,
   host,
   withHostRefinement,
   password,
   port,
   username,
} from '../validators';
import { ConnectionAccessType } from '../../../../entities';
import { LoadingSpinner } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { getErrorMessage } from '../../../../utilities';
import { Collapse, Form, Stack } from 'react-bootstrap';
import { AccessTypeField, HideDetailsField } from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   accountName: username,
   password: password,
});

const trinoSchema = withHostRefinement(
   z
      .object({
         dbms: z.literal(DBMS.Trino),
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         connectionName: connectionName,
         description: description,
         host: host,
         port: port,
         catalog: z
            .string()
            .min(1, 'Required')
            .regex(/^[^.]+$/, 'Catalog cannot specify a schema')
            .trim(),
         sharedConnection: z.boolean(),
         hideDetails: z.boolean(),
      })
      .merge(credentialsSchema)
);

type TrinoDetailFormData = z.infer<typeof trinoSchema>;

function TrinoDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const {
      handleSubmit,
      register,
      formState: { errors, touchedFields, dirtyFields },
      reset,
      watch,
      setValue,
      setError,
   } = useForm<TrinoDetailFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : trinoSchema),
      mode: 'onTouched',
      defaultValues: {
         dbms: DBMS.Trino,
         connectionName: '',
         description: '',
         host: '',
         port: 8080,
         catalog: '',
         accountName: '',
         password: '',
         sharedConnection: false,
         hideDetails: false,
      },
   });

   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState<boolean>(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      if (selectedConnectionQuery.data) {
         const formData: TrinoDetailFormData = {
            dbms: DBMS.Trino,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? '8080'),
            catalog: selectedConnectionQuery.data.catalogName ?? '',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password:
               selectedConnectionQuery.data.dataCredentials?.[0]?.accountPassword === undefined
                  ? ''
                  : 'CURRENT',
         };

         reset(formData);
      }
   }, [reset, selectedConnectionQuery.data]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [dirtyFields, onSaveStateChange]);

   // Event handlers
   const onSubmitHandler = (data: TrinoDetailFormData) => {
      onSubmit?.(data);
   };

   const [clearedFields, setClearedFields] = useState<string[]>([]);

   const handleFocus =
      (checkValue: string, setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (e.target.value === checkValue) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof TrinoDetailFormData, setValueOnFocus); // Update form state
            setClearedFields([...clearedFields, e.target.name]);
         }
      };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT', touchedField: string) =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (
            e.target.value === checkValue &&
            !(touchedField in touchedFields) &&
            clearedFields.includes(touchedField)
         ) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof TrinoDetailFormData, setValueOnFocus); // Update form state
         } else if (!e.target.value) {
            setError(touchedField as keyof TrinoDetailFormData, {
               type: 'manual',
               message: 'This field is required',
            });
            setValue(e.target.name as keyof TrinoDetailFormData, undefined, {
               shouldDirty: true,
               shouldTouch: true,
            });
         }
      };

   // Render
   if (selectedConnectionQuery.isLoading || authPersonQuery.isLoading) return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <Form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
         <Stack gap={3}>
            {!onlyCreds && (
               <>
                  <Form.Group controlId="connectionName">
                     <Form.Label>
                        Connection Name <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('connectionName')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.connectionName && !!errors.connectionName}
                        isValid={touchedFields.connectionName && !errors.connectionName}
                        placeholder="Connection Name"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.connectionName?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="description">
                     <Form.Label>Description</Form.Label>
                     <Form.Control
                        {...register('description')}
                        as="textarea"
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.description && !!errors.description}
                        isValid={touchedFields.description && !errors.description}
                        placeholder="Description"
                        rows={3}
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.description?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="host">
                     <Form.Label>
                        Host <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('host')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.host && !!errors.host}
                        isValid={touchedFields.host && !errors.host}
                        placeholder="Host"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.host?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="port">
                     <Form.Label>
                        Port <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('port', { valueAsNumber: true })}
                        defaultValue="5432"
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.port && !!errors.port}
                        isValid={touchedFields.port && !errors.port}
                        placeholder="Port"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.port?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="database">
                     <Form.Label>
                        Catalog <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('catalog')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.catalog && !!errors.catalog}
                        isValid={touchedFields.catalog && !errors.catalog}
                        placeholder="Catalog"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.catalog?.message}
                     </Form.Control.Feedback>
                  </Form.Group>

                  <AccessTypeField
                     disabled={editType !== 'connection'}
                     {...register('connectionAccessType', {
                        setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                        onChange(event) {
                           if (parseInt(event.target.value) === ConnectionAccessType.INDIVIDUAL) {
                              setValue('hideDetails', false, { shouldDirty: true });
                           }

                           setValue('accountName', '', { shouldDirty: true });
                           setValue('password', '', { shouldDirty: true });
                        },
                     })}
                  />
                  {explorerIsAdmin && (
                     <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                        <div>
                           <HideDetailsField
                              {...register('hideDetails')}
                              disabled={editType !== 'connection'}
                           />
                        </div>
                     </Collapse>
                  )}
               </>
            )}
            <Form.Group controlId="accountName">
               <Form.Label>
                  User Name <span className="text-danger">*</span>
               </Form.Label>
               <Form.Control
                  {...register('accountName')}
                  disabled={editType === 'read-only'}
                  isInvalid={touchedFields.accountName && !!errors.accountName}
                  isValid={touchedFields.accountName && !errors.accountName}
                  placeholder="User Name"
                  required={true}
               />
               <Form.Control.Feedback type="invalid">
                  {errors.accountName?.message}
               </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password">
               <Form.Label>
                  Password <span className="text-danger">*</span>
               </Form.Label>
               <Form.Control
                  {...register('password')}
                  disabled={editType === 'read-only'}
                  isInvalid={touchedFields.password && !!errors.password}
                  isValid={touchedFields.password && !errors.password}
                  onBlur={handleBlur('', 'CURRENT', 'password')}
                  onFocus={handleFocus('CURRENT', '')}
                  placeholder="Password"
                  required={true}
                  type="password"
               />
               <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
               </Form.Control.Feedback>
            </Form.Group>
         </Stack>
      </Form>
   );
}

export default TrinoDetailForm;
