import { Navigate, useParams } from 'react-router-dom';

import { Page } from '../../components';
import { WorkspaceProvider } from '../../hooks';
import Explore from './Explore/Explore';

export enum historyTabs {
   QueryLog = 'query-log',
   Stats = 'stats',
   WorkspaceLog = 'workspace-log',
}

export const WorkspacePage = (): JSX.Element => {
   const params = useParams();
   const workspaceId = Number(params.workspaceId);

   if (workspaceId === undefined || Number.isNaN(workspaceId)) {
      return <Navigate replace to="/" />;
   }

   return (
      <WorkspaceProvider workspaceId={workspaceId}>
         <Page bodyPadding={false}>
            <Explore workspaceId={workspaceId} />
         </Page>
      </WorkspaceProvider>
   );
};

export default WorkspacePage;
