//DataBase Management System
export enum DBMS {
   Big_Query = 'Big Query',
   CHAT_GPT = 'Chat GPT',
   Databricks = 'Databricks',
   Federated = 'Federated',
   Hugging_Face = 'Hugging Face',
   MSSQL = 'MSSQL',
   MongoDB = 'MongoDB',
   MySQL = 'MySQL',
   Neo4j = 'Neo4j',
   Oracle = 'Oracle',
   Postgres = 'Postgres',
   Python = 'Python',
   Redshift = 'Redshift',
   Snowflake = 'Snowflake',
   Trino = 'Trino',
}

export const DBMS_TYPES = [
   DBMS.Big_Query,
   DBMS.MSSQL,
   DBMS.MySQL,
   DBMS.Neo4j,
   DBMS.Postgres,
   DBMS.Redshift,
   DBMS.Snowflake,
   DBMS.Databricks,
   DBMS.Oracle,
   DBMS.Trino,
   DBMS.Federated,
];

export const FEDERATED_DBMS = [
   DBMS.MySQL,
   DBMS.Postgres,
   DBMS.MSSQL,
   DBMS.Redshift,
   DBMS.Big_Query,
   DBMS.MongoDB,
   DBMS.Oracle,
   DBMS.Snowflake,
];

export const CATALOG_DBMS = [
   DBMS.MSSQL,
   DBMS.Big_Query,
   DBMS.Databricks,
   DBMS.Redshift,
   DBMS.Snowflake,
   DBMS.Trino,
   DBMS.Postgres,
];

export const dbmsKey = (value: string): string | undefined =>
   Object.entries(DBMS).find(([_, v]) => v === value)?.[0];

export const getDemoQuery = (dbms: DBMS, table: string, database?: string): string => {
   switch (dbms) {
      case DBMS.Big_Query:
         return `SELECT * FROM ${database ? `${database}.` : ``}${table} LIMIT 10;`;
      case DBMS.MSSQL:
         return `SELECT TOP 10 * FROM ${database ? `[${database}].` : ``}[${table}]`;
      case DBMS.Postgres:
      case DBMS.Redshift:
      case DBMS.Snowflake:
      case DBMS.Databricks:
         return `SELECT * FROM ${database ? `"${database}".` : ``}"${table}" LIMIT 10;`;
      case DBMS.MySQL:
      case DBMS.Trino:
      case DBMS.Federated:
         return `SELECT * FROM ${database ? `\`${database}\`.` : ``}\`${table}\` LIMIT 10;`;
      case DBMS.MongoDB:
         return `SELECT * FROM ${table} LIMIT 10;`;
      case DBMS.Oracle:
         return `SELECT * FROM ${database ? `${database}.` : ``}${table} WHERE ROWNUM <= 10;`;
      default:
         return '';
   }
};

export enum ColumnNull {
   NOT_NULL = 'NOT NULL',
   NULL = 'NULL',
}
