import { Stack, Button, Spinner } from 'react-bootstrap';
import { HiSparkles } from 'react-icons/hi2';
import { MdModeEdit, MdOutlineSave } from 'react-icons/md';
import { Button as CustomButton } from '../components';
import Markdown from 'react-markdown';
import { AiPulse, AiSparkles, InlineInput } from '../components';
import { QueryVersion } from '../entities';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSubscribe } from '../utilities';

const ButtonSpinner = () => (
   <>
      &nbsp;
      <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
      &nbsp;
   </>
);

export const QueryDocs = ({
   allowDocGeneration,
   isGenerating,
   isGeneratingExplanation,
   onChangeDocumentation,
   onClickGenerateDocumentation,
   onClickGenerateExplanation,
   onSetExplanation,
   queryVersion,
   readOnly,
   setShowUpgradeModal,
}: {
   allowDocGeneration?: boolean;
   isGenerating?: boolean;
   isGeneratingExplanation?: boolean;
   onChangeDocumentation?: (params: {
      description?: string;
      explanation?: string;
      question?: string;
      title?: string;
   }) => void;
   onClickGenerateDocumentation?: React.MouseEventHandler<HTMLButtonElement>;
   onClickGenerateExplanation?: React.MouseEventHandler<HTMLButtonElement>;
   onSetExplanation?: (explanation: string) => void;
   queryVersion: QueryVersion;
   readOnly?: boolean;
   setShowUpgradeModal?: () => void;
}) => {
   const [editingExplanation, setEditingExplanation] = useState(false);
   const [explanation, setExplanation] = useState(queryVersion?.explanation ?? '');
   const inputRef = useRef<HTMLInputElement | null>(null);
   const scrollRef = useRef<HTMLDivElement | null>(null);

   const scrollToExplanation = useCallback(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
   }, []);

   const handleEdit = useCallback(() => {
      setEditingExplanation(true);
      scrollToExplanation();
   }, [setEditingExplanation, scrollToExplanation]);

   const handleSave = useCallback(() => {
      setEditingExplanation(false);
      onSetExplanation?.(explanation);
   }, [setEditingExplanation, explanation, onSetExplanation]);

   useEffect(() => {
      if (queryVersion?.explanation) setExplanation(queryVersion?.explanation);
   }, [queryVersion?.explanation, setExplanation]);

   useSubscribe(`scrollToExplanation-${queryVersion.id}`, scrollToExplanation, [
      scrollToExplanation,
   ]);

   return (
      <Stack className="h-100 overflow-auto px-2 fs-12p" gap={4}>
         {readOnly || !allowDocGeneration ? null : (
            <div>
               <Button
                  onClick={onClickGenerateDocumentation}
                  size="sm"
                  style={{ minWidth: '174px' }}
                  variant="secondary"
               >
                  {isGenerating ? (
                     <ButtonSpinner />
                  ) : (
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <HiSparkles className="me-2" size={15} />
                        <p style={{ alignSelf: 'center', marginBottom: 0 }}>
                           Generate Documentation
                        </p>
                     </div>
                  )}
               </Button>
            </div>
         )}{' '}
         {!allowDocGeneration && (
            <div
               className="pt-2"
               style={{
                  fontSize: '12px',
                  color: '#4c82f7',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3px',
               }}
            >
               Loving the magic of automatic documentation?{' '}
               <Button
                  onClick={() => {
                     if (setShowUpgradeModal) {
                        setShowUpgradeModal();
                     }
                  }}
                  style={{
                     backgroundColor: 'transparent',
                     border: 'none',
                     color: 'inherit',
                     padding: 0,
                     margin: 0,
                     textDecoration: 'underline',
                     cursor: 'pointer',
                     fontSize: '12px',
                     fontWeight: 'bold',
                  }}
               >
                  Upgrade now
               </Button>{' '}
               to keep it going!
            </div>
         )}
         <div>
            <div>
               <div className="fs-12p fw-500">Title</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={isGenerating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={
                        !!queryVersion?.generatedTitle &&
                        !!queryVersion?.title &&
                        queryVersion?.title !== 'New query'
                     }
                     triggerOnChange={queryVersion?.title}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              onChange={(title) => {
                                 onChangeDocumentation?.({ title });
                              }}
                              placeholder="Add a title…"
                              readOnly={readOnly}
                              value={queryVersion?.title ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div>
            <div>
               <div className="fs-12p fw-500">Description</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={isGenerating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={!!queryVersion?.description && !!queryVersion?.generatedDescription}
                     triggerOnChange={queryVersion?.description}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              multiLine
                              onChange={(description) => {
                                 onChangeDocumentation?.({ description });
                              }}
                              placeholder="Add a description…"
                              readOnly={readOnly}
                              value={queryVersion?.description ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div>
            <div>
               <div className="fs-12p fw-500">Question</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={isGenerating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={!!queryVersion?.question && !!queryVersion?.generatedQuestion}
                     triggerOnChange={queryVersion?.question}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              multiLine
                              onChange={(question) => {
                                 onChangeDocumentation?.({ question });
                              }}
                              placeholder="Add the question this query answers…"
                              readOnly={readOnly}
                              value={queryVersion?.question ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div ref={scrollRef} style={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
               <div className="fs-12p fw-500">Analyst Explanation</div>
               <CustomButton colorScheme="secondary" onClick={onClickGenerateExplanation} size="sm">
                  {isGeneratingExplanation ? (
                     <ButtonSpinner />
                  ) : (
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <HiSparkles className="me-2" size={15} />
                        <p style={{ alignSelf: 'center', marginBottom: 0 }}>Generate Explanation</p>
                     </div>
                  )}
               </CustomButton>
               {editingExplanation ? (
                  <CustomButton colorScheme="secondary" onClick={handleSave} size="sm">
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <MdOutlineSave className="me-2" size={15} />
                        <p style={{ alignSelf: 'center', marginBottom: 0 }}>Save</p>
                     </div>
                  </CustomButton>
               ) : (
                  <CustomButton colorScheme="secondary" onClick={handleEdit} size="sm">
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <MdModeEdit className="me-2" size={15} />
                        <p style={{ alignSelf: 'center', marginBottom: 0 }}>Edit</p>
                     </div>
                  </CustomButton>
               )}
            </div>
            <div className="flex-grow-1">
               <AiPulse on={isGenerating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={!!queryVersion?.explanation && !!queryVersion?.generatedExplanation}
                     triggerOnChange={queryVersion?.explanation}
                  >
                     {(targetRef) => (
                        <div
                           ref={targetRef}
                           style={
                              editingExplanation
                                 ? {}
                                 : {
                                      border: '1px solid rgba(255, 255, 255, 0.18)',
                                      borderRadius: '0.25rem',
                                      minHeight: '32px',
                                      padding: '1px 4px 1px 8px',
                                   }
                           }
                        >
                           {editingExplanation ? (
                              <InlineInput
                                 multiLine
                                 onChange={(explanation) => {
                                    onChangeDocumentation?.({ explanation });
                                    setExplanation(explanation);
                                 }}
                                 placeholder="Add an explanation of what this query does..."
                                 readOnly={readOnly}
                                 ref={inputRef}
                                 value={queryVersion?.explanation ?? ''}
                              />
                           ) : (
                              <Markdown>{queryVersion?.explanation ?? ''}</Markdown>
                           )}
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
      </Stack>
   );
};

export default QueryDocs;
