"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractTablesFromSQL = extractTablesFromSQL;
exports.extractReferencesFromSQL = extractReferencesFromSQL;
function unnestBrackets(input) {
    const sections = [];
    let deBracketed = input;
    const innerMostBracketRegex = /\(([^()]+)\)/g;
    let match;
    // Repeatedly find the innermost bracketed expression and replace with {}
    while ((match = innerMostBracketRegex.exec(deBracketed)) !== null) {
        sections.push(match[1]); // Capture the innermost content
        deBracketed = deBracketed.replace(match[0], "{}");
        // Reset regex lastIndex to search from the beginning each time since it modifies the string
        innerMostBracketRegex.lastIndex = 0;
    }
    return [deBracketed].concat(sections);
}
function extractTablesFromSQL(query, 
// If defaultSchema isn't provided, we expect all references to be qualified
// with the scema.
defaultSchema, 
// If defaultCatalog isn't provided, we expect this is a non-catalog data
// source, or references will be full qualified.
defaultCatalog) {
    const tableReferencesMap = new Map();
    // Truncate strings so any special characters in them don't affect matching
    query = query.replaceAll(/'(?:\\'|[^'])*'/g, "''");
    // Remove comments
    query = query.replaceAll(/--.*$/gm, "").replace(/\/\*.*?\*\//gs, "");
    // We first split into FROM clauses without trying to stop when the clause
    // ends.
    const fromClauses = query
        .split(";")
        .flatMap((q) => unnestBrackets(q))
        .flatMap((q) => q.split(/\bFROM\s+/i).slice(1));
    for (let clause of fromClauses) {
        // Remove ONLY and LATERAL keywords
        clause = clause.replaceAll(/\b(ONLY|LATERAL)\b/gi, "").trim();
        // Treat comma-separated cross-joins and JOIN clauses the same.
        clause = clause.replace(/\bJOIN\b/gi, ",");
        // Truncate once we see another clause
        clause = clause.replace(/\b(WHERE|GROUP BY|HAVING|WINDOW|UNION|INTERSECT|EXCEPT|ORDER BY|LIMIT|OFFSET|FETCH|FOR)\b.*/, "");
        for (let table of clause.split(",")) {
            table = table.trim();
            // Skip FROM (suq-query)
            if (table[0] === "{")
                continue;
            // Match 1-3 dot-separated segments, where each is either unquoted and
            // doesn't contain whitespace, or is quoted.
            const extraTable = /^([^\s"`][^\s.)]*|["`][^"`]+["`])(\.([^\s"`.]+|["`][^"`]+["`]))?(\.([^\s"`.]+|["`][^"`]+["`]))?/;
            const tableMatch = extraTable.exec(table);
            if (!tableMatch)
                continue;
            table = tableMatch[0].trim();
            // Remove quotes for consistency; they can be added back in later if
            // used in a query.
            table = table.replaceAll(/["`]/g, "");
            // Replace {} placeholder for function calls
            table = table.replace("{}", "()");
            // Fully qualify the name using the defaults
            const segments = table.split(".");
            switch (segments.length) {
                case 1:
                    // Skip unqualified references if no default schema is provided
                    if (!defaultSchema)
                        continue;
                    table = [defaultCatalog, defaultSchema, segments[0]]
                        .filter(Boolean)
                        .join(".");
                    break;
                case 2:
                    table = [defaultCatalog, segments[0], segments[1]]
                        .filter(Boolean)
                        .join(".");
                    break;
                case 3:
                    table = segments.join(".");
                    break;
                default:
                    // More than 3 segments shouldn't be valid, so ignore
                    continue;
            }
            const lowerCaseTable = table.toLowerCase();
            if (!tableReferencesMap.has(lowerCaseTable)) {
                tableReferencesMap.set(lowerCaseTable, table);
            }
        }
    }
    return Array.from(tableReferencesMap.values());
}
function extractReferencesFromSQL(query, 
// If defaultSchema isn't provided, we expect all references to be qualified
// with the scema.
defaultSchema, 
// If defaultCatalog isn't provided, we expect this is a non-catalog data
// source, or references will be full qualified.
defaultCatalog) {
    const catalogs = new Map();
    const schemas = new Map();
    const tables = extractTablesFromSQL(query, defaultSchema, defaultCatalog);
    for (const table of tables) {
        const segments = table.split(".");
        let catalog, schema;
        switch (segments.length) {
            case 3:
                [catalog, schema] = segments;
                schema = `${catalog}.${schema}`;
                break;
            case 2:
                [schema] = segments;
                break;
            default:
                console.warn(`Invalid table reference: ${table}`);
                continue;
        }
        if (catalog && !catalogs.has(catalog)) {
            catalogs.set(catalog, catalog);
        }
        if (!schemas.has(schema)) {
            schemas.set(schema, schema);
        }
    }
    return {
        catalogs: Array.from(catalogs.values()),
        schemas: Array.from(schemas.values()),
        tables,
    };
}
