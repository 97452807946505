import { useState, useMemo, useEffect, useCallback, createContext, useContext, memo } from 'react';
import classNames from 'classnames';
import { Stack, Popover } from 'react-bootstrap';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import {
   MdCable,
   MdKeyboardArrowDown,
   MdKeyboardArrowRight,
   MdOpenInNew,
   MdTableChart,
   MdTableView,
} from 'react-icons/md';
import { FaDatabase, FaLock } from 'react-icons/fa';
import { BiRefresh, BiTrash, BiPlus } from 'react-icons/bi';
import {
   ContextMenu as RctxContextMenu,
   ContextMenuItem as RctxContextMenuItem,
   ContextMenuTrigger as RctxContextMenuTrigger,
} from 'rctx-contextmenu';
import {
   IconKey,
   IconStoredProc,
   IconTrigger,
   IconAlert,
   getErrorMessage,
   handleError,
} from '../utilities';
import {
   useWorkspace,
   useUpdateSchema,
   fetchTableMetaQueryKey,
   getDataConnectionSchemaQueryKey,
   useSetOverrideSchema,
   useOverrideSchema,
} from '../hooks';
import { StepType, ConnectionAccessType, useOpenQuery } from '../entities';
import { useCreateTableModal, canCreateTable } from './CreateTableModal';
import { useDeleteTableModal, canDeleteTable } from './DeleteTableModal';
import { TableEditTab, schemaEditSupported } from '../pages/Workspace/Explore/Table';
import { TYPES } from '../types';
import { ApiService } from '../services';
import { DBMS, getDemoQuery, ColumnNull } from '../enums';
import { ToggleTip } from './ToggleTip';

export type DataConnection = {
   catalogName: string | null;
   connectionAccessType: ConnectionAccessType;
   dbms: DBMS;
   federatedCatalogName: string;
   id: number;
   name: string;
};

export type SchemaRoot = {
   dataConnection: DataConnection;
   id: number;
   isVisible: boolean;
   schemaName: string;
   type: 'schema';
};

type ContentItemBase = {
   id: number;
   name: string;
};

export type SchemaContentItemTable = ContentItemBase & {
   metadata: { isView: boolean };
   type: 'table';
};
type SchemaContentItemStoredProcedure = ContentItemBase & {
   metadata: { definition: string };
   type: 'storedProcedure';
};
type SchemaContentItem = SchemaContentItemTable | SchemaContentItemStoredProcedure;

type TableContentItemColumn = ContentItemBase & {
   metadata: {
      charset: string | null;
      collation: string | null;
      comment: string;
      count: number | null;
      dataType: string;
      extra: string;
      keyType: string | null;
      nullable: string | null;
   };
   type: 'column';
};
type TableContentItemTrigger = ContentItemBase & {
   metadata: { definition: string };
   type: 'trigger';
};
type TableContentItem = TableContentItemColumn | TableContentItemTrigger;

const commonUseQueryOptions: { refetchOnMount: 'always'; retry: false } = {
   retry: false,
   refetchOnMount: 'always',
};

// Assumes searchQueryLower is trimmed and lowercased.
const useSearch = (searchQueryLower: string, testString: string) => {
   const testStringLower = useMemo(() => testString.toLowerCase(), [testString]);
   return useMemo(
      () => searchQueryLower === '' || testStringLower.includes(searchQueryLower),
      [searchQueryLower, testStringLower]
   );
};

const useExpand = (): [boolean, (isExpanded: boolean) => void] => {
   const [isNodeExpanded, setIsNodeExpanded] = useState(false);
   const handleChangeExpanded = useCallback((isExpanded: boolean) => {
      setIsNodeExpanded(isExpanded);
   }, []);

   return [isNodeExpanded, handleChangeExpanded];
};

type HasName = { name: string };
export const sortItems = (a: HasName, b: HasName) =>
   a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });

const HStack = ({ direction: notUsed, ...props }: React.ComponentProps<typeof Stack>) => (
   <Stack {...props} direction="horizontal" />
);

const isContextMenuEnabled = ({ dbms }: { dbms: DBMS }) => ![DBMS.Neo4j].includes(dbms);

const ContextMenu = ({
   className = '',
   ...props
}: React.ComponentProps<typeof RctxContextMenu>) => (
   <span
      onClick={(e) => {
         e.stopPropagation();
      }}
   >
      <RctxContextMenu
         className={`schema-tree-context-menu${className === '' ? '' : ` ${className}`}`}
         hideOnLeave={true}
         {...props}
      />
   </span>
);

const ContextMenuTrigger = (props: React.ComponentProps<typeof RctxContextMenuTrigger>) => (
   <RctxContextMenuTrigger disableWhileShiftPressed={true} {...props} />
);

const ContextMenuItem = ({
   className = '',
   ...props
}: React.ComponentProps<typeof RctxContextMenuItem>) => (
   <RctxContextMenuItem {...props} className={`d-flex${className === '' ? '' : ` ${className}`}`} />
);

const contextMenuLabelIconProps = {
   className: 'flex-shrink-0 text-muted',
   size: 14,
};
const ContextMenuLabel = ({ children }: React.PropsWithChildren<{}>) => {
   return (
      <HStack className="fs-10p" gap={1}>
         {children}
      </HStack>
   );
};

const nodeLabelIconProps = ({
   additionalClasses = '',
   isMuted = true,
}: { additionalClasses?: string; isMuted?: boolean } = {}) => ({
   className: `${isMuted ? 'text-muted ' : ''}flex-shrink-0${
      additionalClasses !== '' ? ` ${additionalClasses}` : ''
   }`,
   size: 14,
});
const defaultNodeLabelIconProps = nodeLabelIconProps();
const NodeLabel = ({ children }: React.PropsWithChildren<{}>) => {
   return <HStack gap={1}>{children}</HStack>;
};

const LeafNode = ({ children }: React.PropsWithChildren<{}>) => {
   return (
      <div
         className="d-inline-flex ms-3 px-1 fw-500 fs-11p text-nowrap text-truncate"
         style={{ marginBottom: '2px' }}
      >
         {children}
      </div>
   );
};

const DelayedLoader = ({ delay = 300 }: { delay?: number }) => {
   const [showContent, setShowContent] = useState(false);

   useEffect(() => {
      const timeout = setTimeout(() => {
         setShowContent(true);
      }, delay);

      return () => clearTimeout(timeout);
   }, [delay]);

   if (!showContent) {
      return null;
   }

   return <span>Loading...</span>;
};

type ListItem = {
   item: JSX.Element;
   key: string;
};
const ExpandableNode = ({
   emptyListPlaceholder = 'No items',
   initialIsExpanded = false,
   isError = false,
   isHighlighted = false,
   isLoading = false,
   listItems = [],
   onExpand = () => {},
   children,
}: React.PropsWithChildren<{
   emptyListPlaceholder?: string;
   initialIsExpanded?: boolean;
   isError?: boolean;
   isHighlighted?: boolean;
   isLoading?: boolean;
   listItems?: ListItem | ListItem[];
   onExpand?: (isExpanded: boolean) => void;
}>) => {
   const [isExpanded, setIsExpanded] = useState(initialIsExpanded);
   useEffect(() => {
      onExpand(isExpanded);
   }, [isExpanded, onExpand]);

   if (!Array.isArray(listItems)) {
      listItems = [listItems];
   }

   let listContent: JSX.Element | JSX.Element[];
   if (isError) {
      listContent = (
         <li>
            <LeafNode>Error!</LeafNode>
         </li>
      );
   } else if (isLoading) {
      listContent = (
         <li>
            <LeafNode>{isExpanded ? <DelayedLoader /> : null}</LeafNode>
         </li>
      );
   } else if (listItems.length === 0) {
      listContent = (
         <li>
            <LeafNode>{emptyListPlaceholder}</LeafNode>
         </li>
      );
   } else {
      listContent = listItems.map(({ item, key }) => <li key={key}>{item}</li>);
   }

   const ArrowIcon = isExpanded ? MdKeyboardArrowDown : MdKeyboardArrowRight;
   return (
      <div className={`schema-tree-expandable-node`}>
         <button
            className={classNames(
               'border-0 bg-transparent text-reset focus-outline d-inline-flex align-items-center py-0 px-1 text-nowrap text-truncate',
               { 'schema-highlighted': isHighlighted }
            )}
            onClick={() => {
               setIsExpanded((prev) => !prev);
            }}
         >
            <ArrowIcon className="flex-shrink-0" size={14} />
            {children}
         </button>
         <ul className={`list-unstyled ms-3 ${isExpanded ? 'd-block' : 'd-none'}`}>
            {listContent}
         </ul>
      </div>
   );
};

export type OnClickTableLeafData = {
   dataConnection: DataConnection;
   schemaContentItemTable: SchemaContentItemTable;
   schemaName: string;
};

const SchemaTreeContext = createContext<
   | {
        areTablesExpandable: boolean;
        hasContextMenu: boolean;
        onClickTableLeaf?: (data: OnClickTableLeafData) => void;
        schemaContentTypes?: string[];
     }
   | undefined
>(undefined);

export const SchemaTreeProvider = ({
   areTablesExpandable = false,
   hasContextMenu = false,
   onClickTableLeaf,
   schemaContentTypes,
   children,
}: React.PropsWithChildren<{
   areTablesExpandable?: boolean;
   hasContextMenu?: boolean;
   onClickTableLeaf?: (data: OnClickTableLeafData) => void;
   schemaContentTypes?: string[];
}>) => {
   return (
      <SchemaTreeContext.Provider
         value={{
            areTablesExpandable,
            hasContextMenu,
            onClickTableLeaf,
            schemaContentTypes,
         }}
      >
         {children}
      </SchemaTreeContext.Provider>
   );
};

const useAreTablesExpandable = () => {
   const context = useContext(SchemaTreeContext);

   if (context === undefined) {
      throw new Error('useExpandTables must be used within a SchemaTreeProvider');
   }

   return context.areTablesExpandable;
};

const useHasContextMenu = () => {
   const context = useContext(SchemaTreeContext);

   if (context === undefined) {
      throw new Error('useHasContextMenu must be used within a SchemaTreeProvider');
   }

   return context.hasContextMenu;
};

const useOnClickTableLeaf = () => {
   const context = useContext(SchemaTreeContext);

   if (context === undefined) {
      throw new Error('useOnClickTableLeaf must be used within a SchemaTreeProvider');
   }

   return context.onClickTableLeaf;
};

const useSchemaContentTypes = () => {
   const context = useContext(SchemaTreeContext);

   if (context === undefined) {
      throw new Error('useSchemaContentTypes must be used within a SchemaTreeProvider');
   }

   return context.schemaContentTypes;
};

const ColumnNode = ({
   tableContentItem,
   dataConnection,
}: {
   dataConnection: DataConnection;
   tableContentItem: TableContentItemColumn;
}) => {
   const iconElement =
      tableContentItem.metadata.keyType === 'PRI' ? (
         <IconKey {...nodeLabelIconProps({ isMuted: false })} style={{ color: '#ffba08' }} />
      ) : tableContentItem.metadata.keyType === 'MUL' ? (
         <IconKey {...nodeLabelIconProps({ isMuted: false })} style={{ color: '#e2e5f1' }} />
      ) : (
         <IconKey {...nodeLabelIconProps({ additionalClasses: 'invisible' })} />
      );

   const columnDataType = tableContentItem.metadata.dataType.trim();
   const toggleTipBody = useMemo(
      () => (
         <>
            <p className="m-0">Data Type: {columnDataType}</p>
            {tableContentItem.metadata.charset !== null ? (
               <p className="m-0">Charset: {tableContentItem.metadata.charset}</p>
            ) : null}
            {tableContentItem.metadata.collation !== null ? (
               <p className="m-0">Collation: {tableContentItem.metadata.collation}</p>
            ) : null}
            {tableContentItem.metadata.nullable !== null ? (
               <p className="m-0">
                  Nullable:{' '}
                  {tableContentItem.metadata.nullable === ColumnNull.NULL ? 'True' : 'False'}
               </p>
            ) : null}
            {tableContentItem.metadata.keyType !== null ? (
               <p className="m-0">Collation: {tableContentItem.metadata.keyType}</p>
            ) : null}
            <p className="m-0">Extra: {tableContentItem.metadata.extra}</p>
            <p className="m-0">Comment: {tableContentItem.metadata.comment}</p>
         </>
      ),
      [tableContentItem.metadata, columnDataType]
   );

   const labelContent = (
      <HStack gap={1}>
         <span>
            {tableContentItem.name}
            {`${
               tableContentItem.metadata.count !== null && dataConnection.dbms === DBMS.Neo4j
                  ? ` (${tableContentItem.metadata.count})`
                  : ''
            }`}
         </span>
         {columnDataType !== '' ? (
            <>
               <span className="text-muted">&#183;</span>
               <span className="text-muted fs-10p">{columnDataType}</span>
            </>
         ) : null}
         <ToggleTip placement="right">
            <Popover id={`column-meta-toggle-tip-${tableContentItem.id}`}>
               <Popover.Header className="fs-12p">{tableContentItem.name}</Popover.Header>
               <Popover.Body className="text-muted fs-11p">{toggleTipBody}</Popover.Body>
            </Popover>
         </ToggleTip>
      </HStack>
   );

   return (
      <LeafNode>
         <NodeLabel>
            {iconElement}
            {labelContent}
         </NodeLabel>
      </LeafNode>
   );
};

// TODO: Don't assume within a workspace.
const TriggerContextMenu = ({
   dataConnection,
   schemaName,
   tableContentItem,
   children,
}: React.PropsWithChildren<{
   dataConnection: DataConnection;
   schemaName: string;
   tableContentItem: TableContentItemTrigger;
}>) => {
   const openQuery = useOpenQuery();
   const handleClickLoadTrigger = () => {
      openQuery({
         source: 'schema',
         newTab: true,
         queryVersion: {
            steps: [
               {
                  dataConnectionId: dataConnection.id,
                  schemaName,
                  order: 0,
                  queryText: tableContentItem.metadata.definition,
                  type: StepType.DATA_CONNECTION,
               },
            ],
            generatedTitle: true,
         },
      });
   };

   const contextMenuId = `contextMenu-triggerNode-${tableContentItem.id}`;

   return (
      <>
         <ContextMenuTrigger className="d-flex" id={contextMenuId}>
            <span style={{ cursor: 'context-menu' }}>{children}</span>
         </ContextMenuTrigger>
         <ContextMenu id={contextMenuId}>
            <ContextMenuItem onClick={handleClickLoadTrigger}>
               <ContextMenuLabel>
                  <MdOpenInNew {...contextMenuLabelIconProps} />
                  <span>Load</span>
               </ContextMenuLabel>
            </ContextMenuItem>
         </ContextMenu>
      </>
   );
};

const TriggerNode = ({
   tableContentItem,
   schemaName,
   dataConnection,
}: {
   dataConnection: DataConnection;
   schemaName: string;
   tableContentItem: TableContentItemTrigger;
}) => {
   const hasContextMenu = useHasContextMenu() && isContextMenuEnabled(dataConnection);

   return (
      <>
         <LeafNode>
            <NodeLabel>
               {/* Add invisible icon so alignment matches with other node types */}
               <IconKey {...nodeLabelIconProps({ additionalClasses: 'invisible' })} />
               {hasContextMenu ? (
                  <TriggerContextMenu
                     dataConnection={dataConnection}
                     schemaName={schemaName}
                     tableContentItem={tableContentItem}
                  >
                     {tableContentItem.name}
                  </TriggerContextMenu>
               ) : (
                  tableContentItem.name
               )}
            </NodeLabel>
         </LeafNode>
      </>
   );
};

const TriggerGroupNode = ({
   data,
   dataConnection,
   schemaName,
}: {
   data: TableContentItemTrigger[];
   dataConnection: DataConnection;
   schemaName: string;
}) => {
   // Don't display this node when there are no triggers.
   if (data.length === 0) {
      return null;
   }

   const listItems = data.map((item) => ({
      key: item.id.toString(),
      item: (
         <TriggerNode
            dataConnection={dataConnection}
            schemaName={schemaName}
            tableContentItem={item}
         />
      ),
   }));

   return (
      <ExpandableNode listItems={listItems}>
         <NodeLabel>
            <IconTrigger {...defaultNodeLabelIconProps} />
            <span className="schema-tree-expandable-node-label">Triggers</span>
         </NodeLabel>
      </ExpandableNode>
   );
};

type FetchTableContentQueryKeyParamsDataBase = {
   dataConnectionId: number;
};
type FetchTableContentQueryKeyParamsFetch = {
   data: FetchTableContentQueryKeyParamsDataBase & {
      schemaName: string;
      tableName: string;
   };
   type: 'fetch';
};
type FetchTableContentQueryKeyParamsInvalidate = {
   data: FetchTableContentQueryKeyParamsDataBase & {
      schemaName?: string;
      tableName?: string;
   };
   type: 'invalidate';
};
type FetchTableContentQueryKeyParams =
   | FetchTableContentQueryKeyParamsFetch
   | FetchTableContentQueryKeyParamsInvalidate;
export const fetchTableContentQueryKey = ({ type, data }: FetchTableContentQueryKeyParams) => {
   const baseKey = ['dataConnection', data.dataConnectionId, 'tableContent'];

   if (type === 'fetch') {
      return [...baseKey, data.schemaName, data.tableName];
   }

   if (data.schemaName === undefined) {
      return baseKey;
   }

   const schemaKey = [...baseKey, data.schemaName];

   if (data.tableName === undefined) {
      return schemaKey;
   }

   return [...schemaKey, data.tableName];
};
const useTableContent = ({
   dataConnection,
   workspaceId,
   schemaName,
   tableName,
   enabled,
}: {
   dataConnection: DataConnection;
   enabled: boolean;
   schemaName: string;
   tableName: string;
   workspaceId?: number;
}) => {
   const apiService = useInjection<ApiService>(TYPES.apiService);

   return useQuery<TableContentItem[]>(
      fetchTableContentQueryKey({
         type: 'fetch',
         data: {
            dataConnectionId: dataConnection.id,
            schemaName,
            tableName,
         },
      }),
      async () => {
         const results = await apiService.get<TableContentItem[]>(
            `/v1/dataConnection/${dataConnection.id}/tableContent`,
            {
               tableName,
               schemaName,
               ...(workspaceId !== undefined ? { workspaceId: workspaceId.toString() } : {}),
            }
         );
         return results ?? [];
      },
      { ...commonUseQueryOptions, enabled, staleTime: 1000 * 60 * 15 }
   );
};

const TableNodeExpandable = memo(
   ({
      schemaContentItem,
      dataConnection,
      schemaName,
      workspaceId,
      isSearchMatch = false,
      children,
   }: React.PropsWithChildren<{
      dataConnection: DataConnection;
      isSearchMatch?: boolean;
      schemaContentItem: SchemaContentItemTable;
      schemaName: string;
      workspaceId?: number;
   }>) => {
      const [isExpandableNodeExpanded, handleChangeExpanded] = useExpand();
      const { isError, data } = useTableContent({
         dataConnection,
         schemaName,
         tableName: schemaContentItem.name,
         workspaceId,
         enabled: isExpandableNodeExpanded,
      });

      const dataSortedAndPartitioned = useMemo(
         () =>
            data
               ? data.sort(sortItems).reduce<[TableContentItemColumn[], TableContentItemTrigger[]]>(
                    ([columns, triggers], value) => {
                       if (value.type === 'column') {
                          columns.push(value);
                       } else if (value.type === 'trigger') {
                          triggers.push(value);
                       }

                       return [columns, triggers];
                    },
                    [[], []]
                 )
               : undefined,
         [data]
      );

      const listItems = dataSortedAndPartitioned
         ? [
              // Columns
              ...dataSortedAndPartitioned[0].map((item) => ({
                 key: item.id.toString(),
                 item: <ColumnNode dataConnection={dataConnection} tableContentItem={item} />,
              })),
              // Triggers
              ...(dataSortedAndPartitioned[1].length > 0
                 ? [
                      {
                         key: `triggersGroupNode-${schemaContentItem.id}`,
                         item: (
                            <div className={`${isSearchMatch ? 'd-inline-block' : 'd-none'}`}>
                               <TriggerGroupNode
                                  data={dataSortedAndPartitioned[1].sort(sortItems)}
                                  dataConnection={dataConnection}
                                  schemaName={schemaName}
                               />
                            </div>
                         ),
                      },
                   ]
                 : []),
           ]
         : undefined;

      return (
         <ExpandableNode
            emptyListPlaceholder="No Columns"
            isError={isError}
            isLoading={!data}
            listItems={listItems}
            onExpand={handleChangeExpanded}
         >
            {children}
         </ExpandableNode>
      );
   }
);

// TODO: Don't assume within a workspace.
const TableContextMenu = ({
   schemaContentItem,
   dataConnection,
   schemaName,
   children,
}: React.PropsWithChildren<{
   dataConnection: DataConnection;
   schemaContentItem: SchemaContentItemTable;
   schemaName: string;
}>) => {
   const openQuery = useOpenQuery();
   const navigate = useNavigate();
   const workspace = useWorkspace();
   const openDeleteTableModal = useDeleteTableModal();
   const queryClient = useQueryClient();

   const handleClickLoadDemoQuery = () => {
      openQuery({
         source: 'schema',
         newTab: true,
         queryVersion: {
            steps: [
               {
                  dataConnectionId: dataConnection.id,
                  schemaName,
                  order: 0,
                  queryText: getDemoQuery(dataConnection.dbms, schemaContentItem.name, schemaName),
                  type: StepType.DATA_CONNECTION,
               },
            ],
            generatedTitle: true,
         },
      });
   };

   const handleClickViewData = () => {
      const path = `/workspaces/${workspace.id}/table/${schemaContentItem.id}`;
      navigate(path);
   };

   const handleClickViewStructure = () => {
      const searchParams = new URLSearchParams({ subTab: TableEditTab.COLUMNS });
      const path = `/workspaces/${workspace.id}/table/${
         schemaContentItem.id
      }?${searchParams.toString()}`;
      navigate(path);
   };

   const handleClickDeleteTable = () => {
      const onHide = async (success?: boolean) => {
         if (!success) {
            return;
         }

         const queryKey = fetchSchemaContentQueryKey({
            type: 'invalidate',
            data: {
               dataConnectionId: dataConnection.id,
               schemaName: schemaName,
            },
         });

         await queryClient.invalidateQueries(queryKey);
      };

      openDeleteTableModal({
         tableData: {
            dataConnection,
            schemaName,
            tableName: schemaContentItem.name,
         },
         workspaceId: workspace.id,
         onHide,
      });
   };

   const items = (
      <>
         <ContextMenuItem onClick={handleClickLoadDemoQuery}>
            <ContextMenuLabel>
               <MdOpenInNew {...contextMenuLabelIconProps} />
               <span>{`SELECT * FROM ${schemaContentItem.name} LIMIT 10;`}</span>
            </ContextMenuLabel>
         </ContextMenuItem>
         <ContextMenuItem
            disabled={[DBMS.MongoDB, DBMS.Trino].includes(dataConnection.dbms)}
            onClick={handleClickViewData}
         >
            <ContextMenuLabel>
               <MdOpenInNew {...contextMenuLabelIconProps} />
               <span>View Data</span>
            </ContextMenuLabel>
         </ContextMenuItem>
         <ContextMenuItem
            disabled={
               !schemaEditSupported(dataConnection.dbms) ||
               ![ConnectionAccessType.INDIVIDUAL, ConnectionAccessType.SHARED].includes(
                  dataConnection.connectionAccessType
               )
            }
            onClick={handleClickViewStructure}
         >
            <ContextMenuLabel>
               <MdOpenInNew {...contextMenuLabelIconProps} />
               <span>View Structure</span>
            </ContextMenuLabel>
         </ContextMenuItem>
         <ContextMenuItem
            disabled={!canDeleteTable(dataConnection)}
            onClick={handleClickDeleteTable}
         >
            <ContextMenuLabel>
               <BiTrash {...contextMenuLabelIconProps} />
               <span>Delete Table</span>
            </ContextMenuLabel>
         </ContextMenuItem>
      </>
   );

   const contextMenuId = `contextMenu-tableNode-${schemaContentItem.id}`;

   return (
      <>
         <ContextMenuTrigger className="d-flex" id={contextMenuId}>
            {children}
         </ContextMenuTrigger>
         <ContextMenu id={contextMenuId}>{items}</ContextMenu>
      </>
   );
};

const TableNode = memo(
   ({
      schemaContentItem,
      dataConnection,
      schemaName,
      workspaceId,
      searchQuery,
      isParentSearchMatch,
      onChangeVisibility,
      isExpandable = false,
   }: {
      dataConnection: DataConnection;
      isExpandable?: boolean;
      isParentSearchMatch: boolean;
      onChangeVisibility: (tableName: string, isVisible: boolean) => void;
      schemaContentItem: SchemaContentItemTable;
      schemaName: string;
      searchQuery: string;
      workspaceId?: number;
   }) => {
      const isSearchMatch = useSearch(searchQuery, schemaContentItem.name) || isParentSearchMatch;
      const isVisible = isSearchMatch;
      useEffect(() => {
         onChangeVisibility(schemaContentItem.name, isVisible);
      }, [isVisible, onChangeVisibility, schemaContentItem.name]);

      const TableLabelIcon = schemaContentItem.metadata.isView ? MdTableView : MdTableChart;

      const content = (
         <NodeLabel>
            <TableLabelIcon {...defaultNodeLabelIconProps} />
            <span className={isExpandable ? 'schema-tree-expandable-node-label' : ''}>
               {schemaContentItem.name}
            </span>
         </NodeLabel>
      );

      const hasContextMenu = useHasContextMenu() && isContextMenuEnabled(dataConnection);

      const contentWithOptionalContextMenu = hasContextMenu ? (
         <TableContextMenu
            dataConnection={dataConnection}
            schemaContentItem={schemaContentItem}
            schemaName={schemaName}
         >
            {content}
         </TableContextMenu>
      ) : (
         content
      );

      const onClickTableLeaf = useOnClickTableLeaf();

      return (
         <div className={`${isVisible ? 'd-block' : 'd-none'}`}>
            {isExpandable ? (
               <TableNodeExpandable
                  dataConnection={dataConnection}
                  isSearchMatch={isSearchMatch}
                  schemaContentItem={schemaContentItem}
                  schemaName={schemaName}
                  workspaceId={workspaceId}
               >
                  {contentWithOptionalContextMenu}
               </TableNodeExpandable>
            ) : (
               <LeafNode>
                  {onClickTableLeaf ? (
                     <button
                        className="border-0 bg-transparent text-reset focus-outline"
                        onClick={() =>
                           onClickTableLeaf?.({
                              dataConnection,
                              schemaName,
                              schemaContentItemTable: schemaContentItem,
                           })
                        }
                     >
                        {contentWithOptionalContextMenu}
                     </button>
                  ) : (
                     <span style={{ cursor: hasContextMenu ? 'context-menu' : 'auto' }}>
                        contentWithOptionalContextMenu
                     </span>
                  )}
               </LeafNode>
            )}
         </div>
      );
   }
);

// TODO: Don't assume within a workspace.
const StoredProcedureContextMenu = ({
   dataConnection,
   schemaName,
   schemaContentItem,
   children,
}: React.PropsWithChildren<{
   dataConnection: DataConnection;
   schemaContentItem: SchemaContentItemStoredProcedure;
   schemaName: string;
}>) => {
   const openQuery = useOpenQuery();
   const handleClickLoadStoredProcedure = () => {
      openQuery({
         source: 'schema',
         newTab: true,
         queryVersion: {
            steps: [
               {
                  dataConnectionId: dataConnection.id,
                  schemaName,
                  order: 0,
                  queryText: schemaContentItem.metadata.definition,
                  type: StepType.DATA_CONNECTION,
               },
            ],
            generatedTitle: true,
         },
      });
   };

   const contextMenuId = `contextMenu-storedProcedureNode-${schemaContentItem.id}`;

   return (
      <>
         <ContextMenuTrigger className="d-flex" id={contextMenuId}>
            <span style={{ cursor: 'context-menu' }}>{children}</span>
         </ContextMenuTrigger>
         <ContextMenu id={contextMenuId}>
            <ContextMenuItem onClick={handleClickLoadStoredProcedure}>
               <ContextMenuLabel>
                  <MdOpenInNew {...contextMenuLabelIconProps} />
                  <span>Load</span>
               </ContextMenuLabel>
            </ContextMenuItem>
         </ContextMenu>
      </>
   );
};

const StoredProcedureNode = ({
   schemaContentItem,
   dataConnection,
   schemaName,
}: {
   dataConnection: DataConnection;
   schemaContentItem: SchemaContentItemStoredProcedure;
   schemaName: string;
}) => {
   const hasContextMenu = useHasContextMenu() && isContextMenuEnabled(dataConnection);

   return (
      <div>
         <LeafNode>
            <NodeLabel>
               {/* Add invisible icon so alignment matches with other node types */}
               <IconKey {...nodeLabelIconProps({ additionalClasses: 'invisible' })} />
               {hasContextMenu ? (
                  <StoredProcedureContextMenu
                     dataConnection={dataConnection}
                     schemaContentItem={schemaContentItem}
                     schemaName={schemaName}
                  >
                     {schemaContentItem.name}
                  </StoredProcedureContextMenu>
               ) : (
                  schemaContentItem.name
               )}
            </NodeLabel>
         </LeafNode>
      </div>
   );
};

const StoredProcedureGroupNode = ({
   data,
   dataConnection,
   schemaName,
}: {
   data: SchemaContentItemStoredProcedure[];
   dataConnection: DataConnection;
   schemaName: string;
}) => {
   if (data.length === 0) {
      return null;
   }

   const listItems = data.map((item) => ({
      key: item.id.toString(),
      item: (
         <StoredProcedureNode
            dataConnection={dataConnection}
            schemaContentItem={item}
            schemaName={schemaName}
         />
      ),
   }));

   return (
      <ExpandableNode listItems={listItems}>
         <NodeLabel>
            <IconStoredProc {...defaultNodeLabelIconProps} />
            <span className="schema-tree-expandable-node-label">Stored Procedures</span>
         </NodeLabel>
      </ExpandableNode>
   );
};

type FetchSchemaContentQueryKeyParamsDataBase = {
   dataConnectionId: number;
};
type FetchSchemaContentQueryKeyParamsFetch = {
   data: FetchSchemaContentQueryKeyParamsDataBase & {
      schemaName: string;
      types?: string[];
   };
   type: 'fetch';
};
type FetchSchemaContentQueryKeyParamsInvalidate = {
   data: FetchSchemaContentQueryKeyParamsDataBase & {
      schemaName?: string;
   };
   type: 'invalidate';
};
type FetchSchemaContentQueryKeyParams =
   | FetchSchemaContentQueryKeyParamsFetch
   | FetchSchemaContentQueryKeyParamsInvalidate;
export const fetchSchemaContentQueryKey = ({ type, data }: FetchSchemaContentQueryKeyParams) => {
   const baseKey = ['dataConnection', data.dataConnectionId, 'schemaContent'];

   if (type === 'fetch') {
      return [...baseKey, data.schemaName, data.types ?? 'all'];
   }

   if (data.schemaName === undefined) {
      return baseKey;
   }

   return [...baseKey, data.schemaName];
};
// TODO: conditionally load stored procedures
const useSchemaContent = ({
   dataConnection,
   enabled,
   schemaName,
   types,
   workspaceId,
}: {
   dataConnection: DataConnection;
   enabled: boolean;
   schemaName: string;
   types?: string[];
   workspaceId?: number;
}) => {
   const apiService = useInjection<ApiService>(TYPES.apiService);

   return useQuery<SchemaContentItem[]>(
      fetchSchemaContentQueryKey({
         type: 'fetch',
         data: {
            dataConnectionId: dataConnection.id,
            schemaName,
            types,
         },
      }),
      async () => {
         const results = await apiService.get<SchemaContentItem[]>(
            `/v1/dataConnection/${dataConnection.id}/schemaContent`,
            {
               schemaName,
               ...(types !== undefined ? { types: JSON.stringify(types) } : {}),
               ...(workspaceId !== undefined ? { workspaceId: workspaceId.toString() } : {}),
            }
         );
         return results ?? [];
      },
      { ...commonUseQueryOptions, enabled, staleTime: 1000 * 60 * 15 }
   );
};

// TODO: Don't assume within a workspace.
const SchemaContextMenu = ({
   schemaRoot,
   children,
   schemaOverrideEnabled,
}: React.PropsWithChildren<{ schemaOverrideEnabled?: boolean; schemaRoot: SchemaRoot }>) => {
   const workspace = useWorkspace();
   const queryClient = useQueryClient();
   const navigate = useNavigate();
   const openCreateTableModal = useCreateTableModal();
   const setOverrideSchema = useSetOverrideSchema();
   const overrideSchema = useOverrideSchema();
   const handleClickCreateTable = () => {
      const onHide = async (result?: { tableName: string }) => {
         if (!result) {
            return;
         }

         try {
            const queryKey = fetchSchemaContentQueryKey({
               type: 'fetch',
               data: {
                  dataConnectionId: schemaRoot.dataConnection.id,
                  schemaName: schemaRoot.schemaName,
               },
            });

            await queryClient.refetchQueries({
               queryKey: queryKey,
               exact: true,
            });

            const data = queryClient.getQueryData<SchemaContentItem[]>(queryKey);

            const match = data?.find(
               (val) => val.type === 'table' && val.name === result.tableName
            );

            if (match) {
               const path = `/workspaces/${workspace.id}/table/${match.id}?subTab=${TableEditTab.COLUMNS}`;
               navigate(path);
            }
         } catch (err) {}
      };

      openCreateTableModal({ schemaData: schemaRoot, workspaceId: workspace.id, onHide });
   };

   const contextMenuId = `contextMenu-schemaRootNode-${schemaRoot.id}`;

   return (
      <>
         <ContextMenuTrigger className="d-flex" id={contextMenuId}>
            {children}
         </ContextMenuTrigger>
         <ContextMenu id={contextMenuId}>
            <ContextMenuItem
               disabled={!canCreateTable(schemaRoot.dataConnection)}
               onClick={handleClickCreateTable}
            >
               <ContextMenuLabel>
                  <BiPlus {...contextMenuLabelIconProps} />
                  <span>Create Table</span>
               </ContextMenuLabel>
            </ContextMenuItem>
            {schemaOverrideEnabled ? (
               <>
                  {overrideSchema?.dataConnection.id === schemaRoot.dataConnection.id &&
                  overrideSchema?.schemaName === schemaRoot.schemaName ? (
                     <ContextMenuItem onClick={() => setOverrideSchema?.(undefined)}>
                        <ContextMenuLabel>
                           <BiTrash {...contextMenuLabelIconProps} />
                           <span>Clear Schema Override</span>
                        </ContextMenuLabel>
                     </ContextMenuItem>
                  ) : (
                     <ContextMenuItem
                        onClick={() =>
                           setOverrideSchema?.({
                              dataConnection: schemaRoot.dataConnection.id,
                              schemaName: schemaRoot.schemaName,
                           })
                        }
                     >
                        <ContextMenuLabel>
                           <FaLock {...contextMenuLabelIconProps} />
                           <span>Override Schema Default</span>
                        </ContextMenuLabel>
                     </ContextMenuItem>
                  )}
               </>
            ) : (
               <></>
            )}
         </ContextMenu>
      </>
   );
};

export const SchemaNode = memo(
   ({
      schemaRoot,
      workspaceId,
      initialIsExpanded = false,
      fetchSchemaContentOverride = false,
      searchQuery = '',
      isParentSearchMatch = false,
      onChangeVisibility = () => {},
      schemaOverrideEnabled,
   }: {
      fetchSchemaContentOverride?: boolean;
      initialIsExpanded?: boolean;
      isParentSearchMatch?: boolean;
      onChangeVisibility?: (id: number, isVisible: boolean) => void;
      schemaOverrideEnabled?: boolean;
      schemaRoot: SchemaRoot;
      searchQuery?: string;
      workspaceId?: number;
   }) => {
      const isSearchMatch = useSearch(searchQuery, schemaRoot.schemaName) || isParentSearchMatch;
      const [tableChildVisibility, setTableChildVisibility] = useState<
         Record<string, 'visible' | 'hidden'>
      >({});

      const [isDataQueryEnabled, setIsDataQueryEnabled] = useState(fetchSchemaContentOverride);
      // Pre-fetch the data when the user hovers over the node.
      const handleMouseEnter = useCallback(() => {
         setIsDataQueryEnabled(true);
      }, []);
      const [isExpandableNodeExpanded, handleChangeExpanded] = useExpand();
      useEffect(() => {
         setIsDataQueryEnabled(
            (prev) => prev || fetchSchemaContentOverride || isExpandableNodeExpanded
         );
      }, [isExpandableNodeExpanded, fetchSchemaContentOverride]);
      const schemaContentTypes = useSchemaContentTypes();
      const { isError, data } = useSchemaContent({
         ...schemaRoot,
         enabled: isDataQueryEnabled,
         types: schemaContentTypes,
         workspaceId,
      });

      const dataSortedAndPartitioned = useMemo(
         () =>
            data
               ? data
                    .sort(sortItems)
                    .reduce<[SchemaContentItemTable[], SchemaContentItemStoredProcedure[]]>(
                       ([tables, storedProcedures], value) => {
                          if (value.type === 'table') {
                             tables.push(value);
                          } else if (value.type === 'storedProcedure') {
                             storedProcedures.push(value);
                          }

                          return [tables, storedProcedures];
                       },
                       [[], []]
                    )
               : undefined,
         [data]
      );

      const isVisible = useMemo(
         () =>
            isSearchMatch ||
            // If the child data is not loaded yet, then keep the node visible.
            !dataSortedAndPartitioned ||
            dataSortedAndPartitioned[0].some(
               ({ name }) => (tableChildVisibility[name] ?? 'visible') === 'visible'
            ),
         [isSearchMatch, dataSortedAndPartitioned, tableChildVisibility]
      );
      useEffect(() => {
         onChangeVisibility(schemaRoot.id, isVisible);
      }, [isVisible, onChangeVisibility, schemaRoot.id]);
      const handleChildTableChangeVisibility = useCallback(
         (tableName: string, isVisible: boolean) => {
            setTableChildVisibility((prev) => ({
               ...prev,
               [tableName]: isVisible ? 'visible' : 'hidden',
            }));
         },
         []
      );

      const overrideSchema = useOverrideSchema();
      const isHighlighted = useMemo(
         () =>
            overrideSchema?.dataConnection.id === schemaRoot.dataConnection.id &&
            overrideSchema?.schemaName === schemaRoot.schemaName,
         [overrideSchema, schemaRoot.dataConnection.id, schemaRoot.schemaName]
      );

      const areTablesExpandable = useAreTablesExpandable();

      const listItems = dataSortedAndPartitioned
         ? [
              // Tables
              ...dataSortedAndPartitioned[0].map((item) => ({
                 key: item.id.toString(),
                 item: (
                    <TableNode
                       {...schemaRoot}
                       isExpandable={areTablesExpandable}
                       isParentSearchMatch={isSearchMatch}
                       onChangeVisibility={handleChildTableChangeVisibility}
                       schemaContentItem={item}
                       searchQuery={searchQuery}
                       workspaceId={workspaceId}
                    />
                 ),
              })),
              // Stored Procedures
              ...(dataSortedAndPartitioned[1].length > 0
                 ? [
                      {
                         key: `storedProceduresGroupNode-${schemaRoot.id}`,
                         item: (
                            <div className={`${isSearchMatch ? 'd-inline-block' : 'd-none'}`}>
                               <StoredProcedureGroupNode
                                  data={dataSortedAndPartitioned[1].sort(sortItems)}
                                  dataConnection={schemaRoot.dataConnection}
                                  schemaName={schemaRoot.schemaName}
                               />
                            </div>
                         ),
                      },
                   ]
                 : []),
           ]
         : undefined;

      const hasContextMenu = useHasContextMenu() && isContextMenuEnabled(schemaRoot.dataConnection);

      const content = (
         <NodeLabel>
            <FaDatabase {...defaultNodeLabelIconProps} />
            <span className="schema-tree-expandable-node-label">{schemaRoot.schemaName}</span>
         </NodeLabel>
      );

      return (
         <div
            className={`${isVisible ? 'd-inline-block' : 'd-none'}`}
            onMouseEnter={handleMouseEnter}
         >
            <ExpandableNode
               emptyListPlaceholder="No Tables"
               initialIsExpanded={initialIsExpanded}
               isError={isError}
               isHighlighted={isHighlighted}
               isLoading={!data}
               listItems={listItems}
               onExpand={handleChangeExpanded}
            >
               {hasContextMenu ? (
                  <SchemaContextMenu
                     schemaOverrideEnabled={schemaOverrideEnabled}
                     schemaRoot={schemaRoot}
                  >
                     {content}
                  </SchemaContextMenu>
               ) : (
                  content
               )}
            </ExpandableNode>
         </div>
      );
   }
);

export const fetchSchemaRootsQueryKey = ({
   type,
   data,
}: {
   data: { workspaceId: number };
   type: 'fetch' | 'invalidate';
}) => ['workspace', data.workspaceId, 'schemaRoots'];
export const useSchemaRoots = ({ workspaceId }: { workspaceId: number }) => {
   const apiService = useInjection<ApiService>(TYPES.apiService);

   return useQuery<SchemaRoot[]>(
      fetchSchemaRootsQueryKey({ type: 'fetch', data: { workspaceId } }),
      async () => {
         const results = await apiService.get<SchemaRoot[]>(
            `/v1/workspace/${workspaceId}/schemaRoots`
         );
         return results ?? [];
      },
      { ...commonUseQueryOptions, staleTime: 1000 * 60 * 5 }
   );
};

// TODO: Don't assume within a workspace.
const SchemaGroupContextMenu = ({
   id,
   dataConnection,
   enableConnectionOverride = true,
   onRefresh = () => {},
   children,
}: React.PropsWithChildren<{
   dataConnection: DataConnection;
   enableConnectionOverride?: boolean;
   enableSchemaOverride?: boolean;
   id: string;
   onRefresh?: (isRefreshing: boolean) => void;
}>) => {
   const workspace = useWorkspace();
   const queryClient = useQueryClient();
   const setOverrideSchema = useSetOverrideSchema();
   const overrideSchema = useOverrideSchema();
   const { updateSchema } = useUpdateSchema({
      async onSuccessCallback() {
         const keysToInvalidate = [
            // Navigator schemaRoots
            fetchSchemaRootsQueryKey({ type: 'invalidate', data: { workspaceId: workspace.id } }),
            // Navigator schemaContent
            fetchSchemaContentQueryKey({
               type: 'invalidate',
               data: {
                  dataConnectionId: dataConnection.id,
               },
            }),
            // Navigator tableContent
            fetchTableContentQueryKey({
               type: 'invalidate',
               data: {
                  dataConnectionId: dataConnection.id,
               },
            }),
            // Table Tabs
            fetchTableMetaQueryKey({
               type: 'invalidate',
               data: { dataConnectionId: dataConnection.id },
            }),
            // ResultTable, Table Tabs (Relations)
            getDataConnectionSchemaQueryKey({ dataConnectionId: dataConnection.id }),
         ];
         await Promise.all(keysToInvalidate.map((key) => queryClient.invalidateQueries(key)));
      },
   });

   const handleClickRefreshSchema = async () => {
      try {
         onRefresh(true);
         await updateSchema(dataConnection.id);
      } catch (error) {
         handleError(getErrorMessage(error));
      } finally {
         onRefresh(false);
      }
   };

   const contextMenuId = `contextMenu-schemaRootGroupNode-${id}`;

   return (
      <>
         <ContextMenuTrigger className="d-flex" id={contextMenuId}>
            {children}
         </ContextMenuTrigger>
         <ContextMenu id={contextMenuId}>
            <ContextMenuItem
               disabled={
                  ![ConnectionAccessType.INDIVIDUAL, ConnectionAccessType.SHARED].includes(
                     dataConnection.connectionAccessType
                  )
               }
               onClick={handleClickRefreshSchema}
            >
               <ContextMenuLabel>
                  <BiRefresh {...contextMenuLabelIconProps} />
                  <span>Refresh Schema</span>
               </ContextMenuLabel>
            </ContextMenuItem>
            {enableConnectionOverride ? (
               <>
                  {overrideSchema?.dataConnection.id === dataConnection.id &&
                  !overrideSchema?.schemaName ? (
                     <ContextMenuItem onClick={() => setOverrideSchema?.(undefined)}>
                        <ContextMenuLabel>
                           <BiTrash {...contextMenuLabelIconProps} />
                           <span>Clear Schema Override</span>
                        </ContextMenuLabel>
                     </ContextMenuItem>
                  ) : (
                     <ContextMenuItem
                        onClick={() =>
                           setOverrideSchema?.({
                              dataConnection: dataConnection.id,
                           })
                        }
                     >
                        <ContextMenuLabel>
                           <FaLock {...contextMenuLabelIconProps} />
                           <span>Override Schema Default</span>
                        </ContextMenuLabel>
                     </ContextMenuItem>
                  )}
               </>
            ) : (
               <></>
            )}
         </ContextMenu>
      </>
   );
};

export const SchemaGroupNode = memo(
   ({
      id,
      name,
      dataConnection,
      enableConnectionOverride,
      enableSchemaOverride,
      data,
      workspaceId,
      schemaNodesAreExpandedInitially = false,
      fetchSchemaContentOverride = false,
      searchQuery = '',
      showFederatedWarning = false,
   }: {
      data: SchemaRoot[];
      dataConnection: DataConnection;
      enableConnectionOverride?: boolean;
      enableSchemaOverride?: boolean;
      fetchSchemaContentOverride?: boolean;
      id: string;
      name: string;
      schemaNodesAreExpandedInitially?: boolean;
      searchQuery?: string;
      showFederatedWarning?: boolean;
      workspaceId?: number;
   }) => {
      const isSearchMatch = useSearch(searchQuery, name);
      const [childVisibility, setChildVisibility] = useState<Record<number, 'visible' | 'hidden'>>(
         {}
      );
      const isVisible = useMemo(
         () =>
            isSearchMatch ||
            data.some(({ id }) => (childVisibility[id] ?? 'visible') === 'visible'),
         [isSearchMatch, data, childVisibility]
      );
      const handleChildChangeVisibility = useCallback((id: number, isVisible: boolean) => {
         setChildVisibility((prev) => ({
            ...prev,
            [id]: isVisible ? 'visible' : 'hidden',
         }));
      }, []);

      const overrideSchema = useOverrideSchema();
      const isHighlighted = useMemo(
         () =>
            overrideSchema?.dataConnection.id === dataConnection.id && !overrideSchema?.schemaName,
         [dataConnection.id, overrideSchema]
      );

      const listItems = data.map((schemaRoot) => ({
         key: schemaRoot.id.toString(),
         item: (
            <SchemaNode
               fetchSchemaContentOverride={fetchSchemaContentOverride}
               initialIsExpanded={schemaNodesAreExpandedInitially}
               isParentSearchMatch={isSearchMatch}
               onChangeVisibility={handleChildChangeVisibility}
               schemaOverrideEnabled={enableSchemaOverride}
               schemaRoot={schemaRoot}
               searchQuery={searchQuery}
               workspaceId={workspaceId}
            />
         ),
      }));

      const [isRefreshingSchema, setIsRefreshingSchema] = useState(false);
      const handleRefresh = useCallback((isRefreshing: boolean) => {
         setIsRefreshingSchema(isRefreshing);
      }, []);

      const hasContextMenu = useHasContextMenu() && isContextMenuEnabled(dataConnection);

      const content = (
         <NodeLabel>
            <MdCable {...defaultNodeLabelIconProps} />
            <span className="schema-tree-expandable-node-label">{name}</span>
            {showFederatedWarning ? (
               <ToggleTip icon={IconAlert} size={14}>
                  <Popover id={`federated-warning-${id}`}>
                     <Popover.Body>Not supported for federated queries</Popover.Body>
                  </Popover>
               </ToggleTip>
            ) : null}
         </NodeLabel>
      );

      return (
         <div className={`${isVisible ? 'd-block' : 'd-none'}`}>
            <ExpandableNode
               initialIsExpanded={true}
               isHighlighted={isHighlighted}
               isLoading={isRefreshingSchema}
               listItems={listItems}
            >
               {hasContextMenu ? (
                  <SchemaGroupContextMenu
                     dataConnection={dataConnection}
                     enableConnectionOverride={enableConnectionOverride}
                     id={id}
                     onRefresh={handleRefresh}
                  >
                     {content}
                  </SchemaGroupContextMenu>
               ) : (
                  content
               )}
            </ExpandableNode>
         </div>
      );
   }
);
