import { useCallback, useMemo, useState } from 'react';
import { Button, Collapse, Nav, Stack } from 'react-bootstrap';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { LoadingSpinner, Page, QueryHeader, Tab } from '../components';
import QueryReviewItem from '../components/QueryReviewItem';
import { QueryVersion } from '../entities';
import { SERVICE_PLANS } from '@runql/util';
import { type PanelTabs, QueryProvider, useOrg, useRunQueries, useWorkspaces } from '../hooks';
import { UpgradeModal } from '../pages';
import { QueryPanelProvider } from '../hooks/QueryPanelContext';

type WorkspaceQueryVersions = {
   id: number;
   name: string;
   queryVersions: QueryVersion[];
};

const SideNav = ({
   workspaceQueryVersions,
}: {
   workspaceQueryVersions: WorkspaceQueryVersions[];
}) => {
   const [show, setShow] = useState(new Set<Number>());
   return (
      <Nav className="flex-column navigation-menu-width pt-1">
         {workspaceQueryVersions.map(({ id, name, queryVersions }) => (
            <div
               key={id}
               onClick={() =>
                  show.has(id)
                     ? setShow(new Set([...show].filter((i) => i !== id)))
                     : setShow(new Set([...show, id]))
               }
            >
               <Nav.Link
                  className="py-1 ps-1 pe-2 fw-600 fs-13p border-bottom-line top-nav-bar-location-title hover-highlight d-flex align-items-center overflow-hidden text-truncate"
                  href={`#workspace-${id}`}
                  key={id}
               >
                  {show.has(id) ? <BiChevronDown size={16} /> : <BiChevronRight size={16} />}
                  {name}
               </Nav.Link>
               <Collapse in={show.has(id)}>
                  <div onClick={(e) => e.stopPropagation()}>
                     {queryVersions.map((version) => (
                        <div className="px-1 mt-3" key={version.id}>
                           <div className="dimmed-queries">
                              <div className="border-0 query-card card query-card-hover">
                                 <Nav.Link
                                    className="d-flex flex-column"
                                    href={`#query-${version.id}`}
                                 >
                                    <QueryHeader queryVersion={version} showDescription />
                                 </Nav.Link>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               </Collapse>
            </div>
         ))}
      </Nav>
   );
};

export const QueryReviewPage = ({
   children,
   defaultTab,
   diffVersion,
   expandDetails,
   extraActions,
   extraTabs,
   action,
   hideTabs,
   queryVersions,
   upgradeMessage,
}: {
   action?: React.ReactNode;
   children?: React.ReactNode;
   defaultTab: PanelTabs;
   diffVersion?: (queryVersion: QueryVersion) => QueryVersion | undefined;
   expandDetails?: boolean;
   extraActions?: (queryVersion: QueryVersion) => React.ReactNode[];
   extraTabs?: (queryVersion?: QueryVersion) => Tab[];
   hideTabs?: {
      comments?: boolean;
      logs?: boolean;
   };
   queryVersions?: QueryVersion[];
   upgradeMessage: string;
}) => {
   const [showDetailsState, setShowDetailsState] = useState<Set<number>>(new Set());
   const [showUpgradeModal, setShowUpgradeModal] = useState(false);
   const org = useOrg();
   const showDetails = (queryVersion: QueryVersion) =>
      queryVersion.id && showDetailsState.has(queryVersion.id);
   const setShowDetails = useCallback((queryVersion: QueryVersion, show = true) => {
      setShowDetailsState((prev) => {
         if (show) {
            return new Set([...prev, queryVersion.id!]);
         } else {
            const newSet = new Set(prev);
            newSet.delete(queryVersion.id!);
            return newSet;
         }
      });
   }, []);

   const workspaces = useWorkspaces();
   const workspaceQueryVersions: WorkspaceQueryVersions[] = useMemo(
      () =>
         Array.from(
            Map.groupBy(queryVersions ?? [], (v) => v.query?.workspaceId ?? 0).entries()
         ).map(([id, queryVersions]) => ({
            id,
            name: workspaces?.find((w) => w.id === id)?.name ?? `Workspace ${id}`,
            queryVersions,
         })),
      [queryVersions, workspaces]
   );

   const { modals } = useRunQueries({
      onStatusChange: (queryVersion, status) => {
         if (status.results && queryVersion.id) {
            setShowDetails(queryVersion, true);
         }
      },
   });

   const reviewAllowed = useMemo(() => {
      return org?.plan !== undefined && SERVICE_PLANS[org.plan].certification;
   }, [org]);

   if (queryVersions === undefined) {
      return <LoadingSpinner />;
   }
   return (
      <>
         {!reviewAllowed && (
            <Page action={action}>
               <UpgradeModal onClose={() => setShowUpgradeModal(false)} show={showUpgradeModal} />
               <p
                  className="pt-2"
                  style={{ fontSize: '12px', color: '#4c82f7', fontWeight: 'bold' }}
               >
                  {upgradeMessage} Aavailable in <strong>team</strong> and{' '}
                  <strong>enterprise</strong> plans.
               </p>
               <Button onClick={() => setShowUpgradeModal(true)} size="sm">
                  Upgrade Now
               </Button>
            </Page>
         )}
         {reviewAllowed && (
            <Page
               action={action}
               nav={
                  workspaceQueryVersions.length ? (
                     <SideNav workspaceQueryVersions={workspaceQueryVersions} />
                  ) : undefined
               }
            >
               <>
                  {children}
                  <Stack gap={4}>
                     {workspaceQueryVersions.map(({ id, name, queryVersions }) => (
                        <div id={`workspace-${id}`} key={id}>
                           <Stack className="query-review-page-queries" gap={3}>
                              <div className="fs-14p fw-600">{name}</div>
                              {queryVersions.map((version) => {
                                 return (
                                    <QueryProvider key={version.id} queryVersion={version}>
                                       <QueryPanelProvider defaultTab={defaultTab}>
                                          <QueryReviewItem
                                             diffVersion={diffVersion}
                                             expandDetails={expandDetails}
                                             extraActions={extraActions}
                                             extraTabs={extraTabs}
                                             hideTabs={hideTabs}
                                             setShowDetails={setShowDetails}
                                             showDetails={showDetails}
                                             version={version}
                                          />
                                       </QueryPanelProvider>
                                    </QueryProvider>
                                 );
                              })}
                           </Stack>
                        </div>
                     ))}
                  </Stack>
                  {modals}
               </>
            </Page>
         )}
      </>
   );
};

export default QueryReviewPage;
