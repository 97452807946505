import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useGetAuthorizedExplorerQuery } from './hooks';
import { LoadingSpinner } from './components';
import { SESSION_STORAGE_AUTHN_PERSON_ID_KEY } from './services/LocalCredentialService';

const Layout = (): JSX.Element => {
   const location = useLocation();
   const redirectTo = location.state?.redirectTo;

   const closeOnAuth = sessionStorage.getItem('closeOnAuth');
   useEffect(() => {
      if (closeOnAuth) {
         sessionStorage.removeItem('closeOnAuth');
         window.opener?.postMessage('authed', '*');
         window.close();
      }
   }, [closeOnAuth]);

   const query = useGetAuthorizedExplorerQuery({ storePersonId: true });

   useEffect(() => {
      // This will run when the component unmounts
      return () => {
         sessionStorage.removeItem(SESSION_STORAGE_AUTHN_PERSON_ID_KEY);
      };
   }, []);

   // Set the user in Sentry
   useEffect(() => {
      if (!query.data?.authenticated) {
         Sentry.setUser(null);
         return;
      }

      if (typeof query.data.person.id === 'number') {
         Sentry.setUser({ id: query.data.person.id });
         return;
      }
   }, [query.data?.authenticated, query.data?.person.id]);

   if (query.isFetching || closeOnAuth) {
      return <LoadingSpinner />;
   }

   if (!query.data?.authenticated) {
      return (
         <Navigate
            state={{
               redirectTo: `${location.pathname}${location.search}`,
            }}
            to="/signin"
         />
      );
   }

   if (redirectTo) {
      return <Navigate to={redirectTo} />;
   }

   return <Outlet />;
};

export default Layout;
