import { memo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Modal, Stack } from 'react-bootstrap';

import { QueryVersion } from '../entities';
import { useExploreTab } from '../hooks';
import { useDeleteQueryMutator } from '../hooks/entities';
import { QueryKey } from '../enums';
import { usePublish } from '../utilities';

export const QueryAdmin = memo(({ queryVersion }: { queryVersion: QueryVersion }) => {
   const query = queryVersion.query;
   const [promptDelete, setPromptDelete] = useState(false);

   const queryClient = useQueryClient();
   const exploreTab = useExploreTab();
   const closeTab = usePublish<number>('close-tab');
   const deleteQueryMutation = useDeleteQueryMutator({
      async onSuccess(_data, _id, _context) {
         if (!query) return;
         if (exploreTab?.id) closeTab(exploreTab?.id);
         await queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', query.workspaceId]);
      },
   });

   const onDeleteQuery = async () => {
      if (!query?.id) return;
      await deleteQueryMutation.mutateAsync(query.id);
   };

   return (
      <Stack gap={3}>
         <div>
            <Button onClick={() => setPromptDelete(true)} size="sm" variant="danger">
               Delete Query
            </Button>
         </div>
         <Modal onHide={() => setPromptDelete(false)} show={promptDelete}>
            <Modal.Header closeButton>
               <Modal.Title className="fs-14p">Delete Query</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div>
                  Delete {queryVersion?.title ? `"${queryVersion?.title}"` : 'this saved query'}?
               </div>
               <div>This will delete all versions of this query.</div>
            </Modal.Body>
            <Modal.Footer>
               <Stack className="justify-content-end" direction="horizontal" gap={2}>
                  <Button onClick={() => setPromptDelete(false)} size="sm" variant="secondary">
                     Cancel
                  </Button>
                  <Button onClick={() => onDeleteQuery()} size="sm" type="submit" variant="danger">
                     Delete
                  </Button>
               </Stack>
            </Modal.Footer>
         </Modal>
      </Stack>
   );
});

export default QueryAdmin;
