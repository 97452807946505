import { zodResolver } from '@hookform/resolvers/zod';
import { RunaMode, walkthroughStep } from '@runql/util';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Dropdown, Form, InputGroup, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IconType } from 'react-icons';
import { BiChevronDown, BiGitCompare, BiSolidEdit } from 'react-icons/bi';
import { IoIosDocument, IoIosInformationCircle, IoMdRocket } from 'react-icons/io';
import { RiFileEditFill, RiFileEditLine } from 'react-icons/ri';
import Markdown from 'react-markdown';
import Loader from 'react-spinners/PulseLoader';
import { z } from 'zod';
import { Button as SuggestionButton } from '../components/Button';
import { StepType, useOpenQuery } from '../entities';
import {
   useExplorer,
   useExploreTab,
   useExtension,
   useListWorkspaceConnectionsQuery,
   useWalkthroughStep,
} from '../hooks';
import { useAskRunaContext } from '../hooks/askHooks';
import { SQL_TEMPLATE } from '../pages';
import { AskRunaResponse, RunaStep } from '../services';
import { formatQuery, getShortDateTimeString, IconUse } from '../utilities';
import CodeViewer from './UI/CodeViewer';
import DiffViewer from './UI/DiffViewer';
import LoadingError from './UI/LoadingError';

const askRunaSchema = z.object({
   prompt: z.string().min(1),
   excludeQuery: z.boolean().optional(),
   mode: z.nativeEnum(RunaMode),
});

export type AskRunaFormData = z.infer<typeof askRunaSchema>;

const INLINE_COMMENTS_PROMPT = 'Generate inline comments';
const OPTIMIZE_PROMPT = 'Optimize my query';
const EXPLAIN_PROMPT = 'Explain my query';

const RunaResponse = ({
   editorCode,
   explorerName,
   index,
   onClick,
   response,
}: {
   editorCode?: string;
   explorerName?: string;
   index: number;
   onClick?: () => void;
   response: AskRunaResponse;
}) => {
   const [showDiff, setShowDiff] = useState(false);

   const responseQueries = useMemo(() => {
      return response.steps.map((step) => step.currentQuery || '');
   }, [response.steps]);
   return (
      <Stack
         className={classNames('border-0 query-card card pt-3 pb-2 px-2 query-card', {
            'query-card-hover':
               response.prompt === EXPLAIN_PROMPT ? response.answer : responseQueries.join('\n\n'),
         })}
         gap={0}
         onClick={onClick}
      >
         <Stack>
            {(responseQueries.length > 0 || response.prompt === EXPLAIN_PROMPT) && (
               <div className="query-card-top-right">
                  <Button className="query-card-action border-0 fs-10p" size="sm" variant="white">
                     {response.prompt === EXPLAIN_PROMPT ? 'Add to docs' : 'Use'}
                     <IconUse size={10} />
                  </Button>
               </div>
            )}

            <div className="d-flex">
               <div className="flex-grow-1">
                  <Stack className="flex-wrap" direction="horizontal" gap={1}>
                     Q: {response.prompt}
                     <Badge bg="info" pill>
                        <div>{response.label}</div>
                     </Badge>
                  </Stack>
                  <div className="justify-content-between">
                     <Stack className="align-items-start" direction="horizontal" gap={1}>
                        <div className="fs-9p text-muted">
                           {getShortDateTimeString(response.timestamp)}
                        </div>
                        <div className="fs-9p text-muted fw-500 potential-badge">
                           {explorerName}
                        </div>
                     </Stack>
                  </div>
               </div>
            </div>
            {responseQueries.length ? (
               <>
                  <Markdown>{response.answer}</Markdown>
                  <div className="queryFontSmall cm-editor">
                     <div className="card border-0 queryCardCode" style={{ position: 'relative' }}>
                        {showDiff ? (
                           <DiffViewer
                              newCode={responseQueries.join('\n') || ''}
                              oldCode={editorCode || ''}
                           />
                        ) : (
                           <CodeViewer
                              query={
                                 responseQueries.length
                                    ? responseQueries.join('\n')
                                    : `Runa didn't generate a query. Try refining your query and/or question.`
                              }
                           />
                        )}
                     </div>
                  </div>
                  <div onClick={(event) => event.stopPropagation()} style={{ alignSelf: 'end' }}>
                     <Dropdown key={`compare-${index}`}>
                        <Dropdown.Toggle className="btn-sm" variant={'secondary'}>
                           <Stack direction="horizontal" gap={1}>
                              <BiGitCompare size={13} />
                              <div>Compare</div>
                              <BiChevronDown size={16} />
                           </Stack>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <Dropdown.Item
                              active={showDiff}
                              onClick={() => {
                                 setShowDiff(true);
                              }}
                           >
                              Version in Editor
                           </Dropdown.Item>
                           <Dropdown.Item
                              active={!showDiff}
                              onClick={() => {
                                 setShowDiff(false);
                              }}
                           >
                              None
                           </Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                  </div>
               </>
            ) : (
               <Markdown className="fs-16p fw-normal">{response.answer}</Markdown>
            )}
         </Stack>
      </Stack>
   );
};

const AskRuna = ({
   onRunaSetExplanation,
   setShowUpgradeModal,
}: {
   onRunaSetExplanation?: (explanation: string) => void;
   setShowUpgradeModal?: () => void;
}) => {
   const {
      register,
      setFocus,
      setValue,
      handleSubmit,
      reset,
      formState: { isValid },
   } = useForm<AskRunaFormData>({
      resolver: zodResolver(askRunaSchema),
      defaultValues: {
         prompt: '',
         mode: RunaMode.Runa,
      },
   });
   const { askRuna, isLoading, clearConversation, conversation } = useAskRunaContext();
   const exploreTab = useExploreTab();
   const extension = useExtension();
   const explorer = useExplorer();
   const openQuery = useOpenQuery(extension ? { handler: extension.openTab } : {});
   const scrollRef = useRef<HTMLDivElement | null>(null);
   const lastCardRef = useRef<HTMLDivElement | null>(null);
   const [stepSix, setStepSix] = useWalkthroughStep(walkthroughStep.GENERATE_INLINE_COMMENTS);

   const workspaceConnectionList = useListWorkspaceConnectionsQuery({
      workspaceId: exploreTab?.workspaceId,
      includeConnectionDetails: true, // only including to match query key from WorkspaceConnectionSelector
   });

   const steps = useMemo((): RunaStep[] => {
      return (
         exploreTab?.queryVersion?.steps?.map((step) => {
            return {
               dataConnectionId:
                  step.dataConnectionId ??
                  (workspaceConnectionList.isLoading
                     ? undefined
                     : workspaceConnectionList?.data?.[0]?.dataConnectionId ?? undefined),
               defaultSchema: step.schemaName ?? undefined,
               order: step.order,
               query: step.queryText !== SQL_TEMPLATE ? step.queryText : undefined,
               schemas: step.schemaName ? [step.schemaName] : [],
            };
         }) || []
      );
   }, [exploreTab?.queryVersion?.steps, workspaceConnectionList]);

   let explorerName = 'UNKNOWN';
   if (explorer) explorerName = explorer.firstName + ' ' + explorer.lastName;

   // Handlers
   const onSubmit = useCallback(
      async (data: AskRunaFormData) => {
         const prompt = data.prompt;
         reset();

         // TODO: Extract extra schemas from query and include in schemas array

         await askRuna({
            prompt: data.mode === RunaMode.Optimize ? undefined : prompt,
            excludeQuery: data.excludeQuery,
            mode: data.mode,
            steps: steps,
         });
      },
      [askRuna, steps, reset]
   );

   useEffect(() => {
      if (scrollRef.current) {
         scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [isLoading]);

   useEffect(() => {
      if (lastCardRef.current) {
         lastCardRef.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [conversation]);

   const handleUse = (response: AskRunaResponse) => {
      if (!response?.steps?.length) {
         if (response.prompt === EXPLAIN_PROMPT && response.answer !== undefined) {
            onRunaSetExplanation?.(response.answer);
            return;
         } else {
            return;
         }
      }
      openQuery({
         newTab: false,
         queryVersion: {
            steps: response.steps.map((step, index) => ({
               dataConnectionId:
                  step.dataConnectionId ??
                  (workspaceConnectionList.isLoading
                     ? undefined
                     : workspaceConnectionList?.data?.[0]?.dataConnectionId ?? undefined),
               schemaName: step.defaultSchema ?? null,
               queryText: formatQuery(step.currentQuery || ''),
               order: step.order || index,
               type: StepType.DATA_CONNECTION,
            })),
         },
         source: 'askRuna',
         workspaceId: exploreTab?.workspaceId,
      });
   };

   // Render
   if (exploreTab?.queryVersion?.steps[0]?.type === StepType.PYTHON) {
      return (
         <div>
            <LoadingError message="Ask Runa is not supported for Python" />
         </div>
      );
   }

   const handleRunaClick = (response: AskRunaResponse) => {
      if (response.isUpgradePrompt && setShowUpgradeModal) {
         setShowUpgradeModal();
      } else {
         handleUse(response);
      }
   };

   const handleClear = () => {
      clearConversation();
   };

   const UsageSuggestion = ({
      CustomIcon,
      excludeQuery,
      mode,
      prompt,
      text,
      userComplete,
   }: {
      CustomIcon: IconType;
      excludeQuery?: boolean;
      mode?: RunaMode;
      prompt: string;
      text: string;
      userComplete?: boolean;
   }) => {
      const handleClick = () => {
         setValue('prompt', prompt);
         if (userComplete) {
            setFocus('prompt');
         } else {
            onSubmit({ prompt, excludeQuery, mode: mode ?? RunaMode.Runa });
         }
      };
      return (
         <SuggestionButton
            colorScheme="secondary"
            onClick={handleClick}
            size="md"
            style={{ borderRadius: '8px' }}
         >
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
               <CustomIcon size="20px" />
               <span style={{ fontSize: '14px', fontWeight: '400' }}>{text}</span>
            </div>
         </SuggestionButton>
      );
   };

   if (conversation.length === 0) {
      if (isLoading) {
         return <Loader color="#6366f1" size={6} />;
      }
      return (
         <div
            style={{
               display: 'flex',
               flexDirection: 'column',
               width: '100%',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <h1 style={{ opacity: '75%' }}>How can I help?</h1>
            <Form
               onSubmit={handleSubmit(onSubmit)}
               style={{ maxWidth: '768px', width: '100%', alignSelf: 'center' }}
            >
               <InputGroup>
                  <Form.Control
                     {...register('prompt')}
                     autoComplete="off"
                     className="small-form-control-input"
                     placeholder="Ask Runa a Question"
                  />
                  <button
                     className="btn btn-xs btn-primary plausible-event-name--askRuna"
                     disabled={isLoading || !isValid}
                     type="submit"
                  >
                     Ask Runa
                  </button>
               </InputGroup>
            </Form>
            <div style={{ display: 'flex', marginTop: '2rem', gap: '0.5rem' }}>
               <UsageSuggestion
                  CustomIcon={IoMdRocket}
                  mode={RunaMode.Optimize}
                  prompt={OPTIMIZE_PROMPT}
                  text="Optimize"
               />
               <UsageSuggestion
                  CustomIcon={IoIosInformationCircle}
                  excludeQuery={true}
                  mode={RunaMode.Explain}
                  prompt={EXPLAIN_PROMPT}
                  text="Explain"
               />
               <UsageSuggestion
                  CustomIcon={IoIosDocument}
                  mode={RunaMode.InlineComments}
                  prompt={INLINE_COMMENTS_PROMPT}
                  text="Generate inline comments"
               />
               <UsageSuggestion
                  CustomIcon={RiFileEditLine}
                  prompt="Write a query that "
                  text="Write a query that..."
                  userComplete={true}
               />
               <UsageSuggestion
                  CustomIcon={RiFileEditFill}
                  prompt="Modify this query to "
                  text="Modify this query to..."
                  userComplete={true}
               />
            </div>
         </div>
      );
   }

   return (
      <Stack className="ask-runa-container" gap={3}>
         <div className="runa-responses">
            <Stack gap={2}>
               {conversation.map((response, index) => (
                  <div
                     className={
                        stepSix && response.prompt === 'Generate inline comments'
                           ? 'rainbowAnimation'
                           : ''
                     }
                     key={index}
                     onClick={() => {
                        if (stepSix) {
                           setStepSix();
                        }
                     }}
                     ref={index === conversation.length - 1 ? lastCardRef : null}
                  >
                     <RunaResponse
                        editorCode={exploreTab?.queryVersion?.steps[0].queryText}
                        explorerName={explorerName}
                        index={index}
                        key={index}
                        onClick={() => handleRunaClick(response)}
                        response={response}
                     />
                  </div>
               ))}
               {isLoading && (
                  <Stack>
                     <Loader color="#6366f1" size={6} />
                  </Stack>
               )}
               <div ref={scrollRef} />
            </Stack>
         </div>
         <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <SuggestionButton
               colorScheme="secondary"
               onClick={handleClear}
               size="sm"
               style={{ height: '100%' }}
            >
               <BiSolidEdit size="20px" />
            </SuggestionButton>
            <Form
               onSubmit={handleSubmit(onSubmit)}
               style={{ maxWidth: '95%', width: '100%', alignSelf: 'center' }}
            >
               <InputGroup>
                  <Form.Control
                     {...register('prompt')}
                     autoComplete="off"
                     className="small-form-control-input"
                     placeholder="Ask Runa a Question"
                  />
                  <button
                     className="btn btn-xs btn-primary plausible-event-name--askRuna"
                     disabled={isLoading || !isValid}
                     type="submit"
                  >
                     Ask Runa
                  </button>
               </InputGroup>
            </Form>
         </div>
      </Stack>
   );
};

export default AskRuna;
