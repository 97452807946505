import { RunaMode } from '@runql/util';
import { useInjection } from 'inversify-react';
import React, { createContext, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { AskRunaRequest, AskRunaResponse, AskService, RunaStep } from '../services';
import { TYPES } from '../types';
import { formatQuery } from '../utilities';

const useAskService = (): AskService => {
   return useInjection<AskService>(TYPES.askService);
};

type AskRunaContextType = {
   askRuna: ({
      clearConversation,
      excludeQuery,
      mode,
      prompt,
      steps,
   }: {
      clearConversation?: boolean;
      excludeQuery?: boolean;
      mode: RunaMode;
      prompt?: string;
      steps: RunaStep[];
   }) => Promise<AskRunaResponse[] | undefined>;
   clearConversation: () => void;
   conversation: AskRunaResponse[];
   isLoading: boolean;
};

const AskRunaContext = createContext<AskRunaContextType>({
   async askRuna() {
      return undefined;
   },
   clearConversation() {
      return undefined;
   },
   isLoading: false,
   conversation: [],
});

export function AskRunaProvider({ children }: { children: React.ReactNode }) {
   const askService = useAskService();
   const askRunaRequest = useMutation({
      mutationFn: async (req: AskRunaRequest) => {
         return askService.askRuna(req);
      },
   });
   const [conversation, setConversation] = useState<AskRunaResponse[]>([]);

   const askRuna = useCallback(
      async ({
         clearConversation,
         excludeQuery,
         mode,
         prompt,
         steps,
      }: {
         clearConversation?: boolean;
         excludeQuery?: boolean;
         mode: RunaMode;
         prompt?: string;
         steps: RunaStep[];
      }) => {
         if (clearConversation) {
            setConversation([]);
         }
         const response = await askRunaRequest.mutateAsync({
            mode,
            prompt,
            previous: mode === RunaMode.Runa ? conversation : [],
            steps,
         });
         if (!response) return;
         if (excludeQuery) response.steps = [];
         else if (response?.steps?.length) {
            let steps = response.steps;
            steps = steps.map((step) => {
               try {
                  const formattedQuery = formatQuery(step.currentQuery || '');
                  step.currentQuery = formattedQuery;
                  return step;
               } catch (e) {
                  // ignore it
                  return step;
               }
            });
            response.steps = steps;
         }
         if (mode === RunaMode.Runa) {
            setConversation([...conversation, response]);
         } else {
            setConversation([response]);
         }

         return conversation;
      },
      [askRunaRequest, conversation]
   );

   const clearConversation = useCallback(() => {
      setConversation([]);
   }, []);

   return (
      <AskRunaContext.Provider
         value={{
            askRuna,
            clearConversation,
            conversation,
            isLoading: askRunaRequest.isLoading,
         }}
      >
         {children}
      </AskRunaContext.Provider>
   );
}

export function useAskRunaContext() {
   return React.useContext(AskRunaContext);
}
