import { useInjection } from 'inversify-react';
import { Stack } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { SnippetService } from '../services';
import { Snippet, StepType } from '../entities';
import { QueryKey } from '../enums';
import { TYPES } from '../types';
import { handleError, useTitle } from '../utilities';
import { QueryWidget } from '../components';

export const Snippets = (): JSX.Element => {
   useTitle('Snippets');
   const snippetService = useInjection<SnippetService>(TYPES.snippetService);
   const snippetKey = [QueryKey.Snippet];
   const snippets = useQuery<Snippet[] | undefined>(snippetKey, () => snippetService.list(), {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      onError(err) {
         handleError(err);
      },
   });

   return (
      <Stack className="dimmed-queries snippets" gap={2}>
         {!snippets.data?.length && (
            <div className="fs-11p text-muted">
               <span>It looks like you haven't saved any snippets yet.</span>
            </div>
         )}
         {snippets.data?.map((snippet, i) => (
            <QueryWidget
               action="none"
               queryVersion={{
                  steps: [
                     {
                        order: 0,
                        queryText: snippet.snippet,
                        type: StepType.DATA_CONNECTION,
                     },
                  ],
               }}
               source="snippet"
            />
         ))}
      </Stack>
   );
};

export default Snippets;
