"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.walkthroughStep = void 0;
var walkthroughStep;
(function (walkthroughStep) {
    walkthroughStep[walkthroughStep["PERSONAL_DEMO_WORKSPACE"] = 1] = "PERSONAL_DEMO_WORKSPACE";
    walkthroughStep[walkthroughStep["IDE_CELL"] = 2] = "IDE_CELL";
    walkthroughStep[walkthroughStep["SUGGESTED_QUERY"] = 3] = "SUGGESTED_QUERY";
    walkthroughStep[walkthroughStep["FIRST_RUN_BUTTON"] = 4] = "FIRST_RUN_BUTTON";
    walkthroughStep[walkthroughStep["CHART_TAB"] = 5] = "CHART_TAB";
    walkthroughStep[walkthroughStep["GENERATE_INLINE_COMMENTS"] = 6] = "GENERATE_INLINE_COMMENTS";
    walkthroughStep[walkthroughStep["SECOND_TAB"] = 7] = "SECOND_TAB";
    walkthroughStep[walkthroughStep["PYTHON_RUN_BUTTON"] = 8] = "PYTHON_RUN_BUTTON";
    walkthroughStep[walkthroughStep["PYTHON_SAVE"] = 9] = "PYTHON_SAVE";
    walkthroughStep[walkthroughStep["VERSIONS"] = 10] = "VERSIONS";
    walkthroughStep[walkthroughStep["SAVED_QUERIES"] = 11] = "SAVED_QUERIES";
    walkthroughStep[walkthroughStep["DATA_SOURCES"] = 12] = "DATA_SOURCES";
    walkthroughStep[walkthroughStep["METADATA_TAB"] = 13] = "METADATA_TAB";
    walkthroughStep[walkthroughStep["WORKSPACES_TAB"] = 14] = "WORKSPACES_TAB";
    walkthroughStep[walkthroughStep["CREATE_WORKSPACE"] = 15] = "CREATE_WORKSPACE";
    walkthroughStep[walkthroughStep["TEAM_PLAYGROUND"] = 16] = "TEAM_PLAYGROUND";
})(walkthroughStep || (exports.walkthroughStep = walkthroughStep = {}));
