import { Badge } from 'react-bootstrap';

import { QueryVersion } from '../entities';
import { IconSingleCertRequest } from '../utilities/icons';

export const QueryVersionBadge = ({
   approved,
   queryVersion,
}: {
   approved: boolean;
   queryVersion: QueryVersion;
}): JSX.Element => {
   let major, minor;
   if (queryVersion.version) {
      major = queryVersion.version;
      minor = queryVersion.minorVersion ?? 0;
   } else {
      return <></>;
   }

   return (
      <Badge bg="info" pill>
         v{major}.{minor}
         {approved && (
            <IconSingleCertRequest
               className="text-success"
               size="10px"
               style={{ marginLeft: '4px', marginBottom: '1px' }}
            />
         )}
      </Badge>
   );
};
