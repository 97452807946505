import { useInjection } from 'inversify-react';
import { useMutation } from 'react-query';
import { BulkUpdateSchemaCache, SchemaCacheService } from '../../services';
import { TYPES } from '../../types';

const useSchemaCacheService = () => {
   return useInjection<SchemaCacheService>(TYPES.schemaCacheService);
};

type BulkUpdateSchemaCacheMutationVariables = {
   dataConnectionId: number;
   schemaCache: BulkUpdateSchemaCache[];
};
export const useBulkUpdateSchemaCacheMutation = ({
   onSuccess,
}: {
   onSuccess?: (
      data: null,
      variables: BulkUpdateSchemaCacheMutationVariables,
      context: unknown
   ) => Promise<void> | void;
} = {}) => {
   const schemaCacheService = useSchemaCacheService();

   return useMutation({
      mutationFn: async (vars: BulkUpdateSchemaCacheMutationVariables) => {
         return schemaCacheService.bulkUpdate(vars.dataConnectionId, vars.schemaCache);
      },
      async onSuccess(data, variables, context) {
         if (onSuccess) {
            await onSuccess(data, variables, context);
         }
      },
   });
};
