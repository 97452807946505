import { format as formatDate, formatRelative as formatDateRelative, isToday } from 'date-fns';
import { useInjection } from 'inversify-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
   Button,
   Modal,
   Nav,
   NavItem,
   NavLink,
   OverlayTrigger,
   Popover,
   Stack,
   TabContainer,
   TabContent,
   TabPane,
   Tooltip,
} from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import { IoCodeSlashOutline } from 'react-icons/io5';
import { MdAddCircle, MdTableChart } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { AiPulse, StartingBlock } from '../../../components';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { ExploreTab, ExploreTabPost, StepType, WorkspaceSchemaConnection } from '../../../entities';
import { walkthroughStep } from '../../../entities/Walkthrough';
import { ExploreTabType, QueryKey } from '../../../enums';
import {
   useGetAuthorizedExplorerQuery,
   useHasUnsavedTab,
   useResultTableFilters,
   useSetUnsavedTab,
} from '../../../hooks';
import { useWalkthroughStep } from '../../../hooks/walkthrough';
import { ExploreTabService, PersonService } from '../../../services';
import { TYPES } from '../../../types';
import { handleError, IconUnsaved, IconX, notUndefined, useSubscribe } from '../../../utilities';
import { WorkspaceHeader } from '../WorkspaceHeader';
import ExploreTabContent from './ExploreTabContent';
import TableTabContent, { TableEditTab } from './Table';

import type { ResultTableFilterType } from '../../../hooks';
import { SERVICE_PLAN, SERVICE_PLANS } from '@runql/util';

const ExploreNavigation = ({
   exploreTab,
   tabId,
   onCloseTab,
}: {
   exploreTab: ExploreTab;
   onCloseTab: (tabId: number) => Promise<void>;
   tabId: number | undefined;
}) => {
   const [stepSeven, setStepSeven] = useWalkthroughStep(walkthroughStep.SECOND_TAB);
   const hasUnsavedTab = useHasUnsavedTab();

   const tabLabel = useMemo(() => {
      if (exploreTab.type === ExploreTabType.Query) {
         const queryVersionTitle = exploreTab.queryVersion?.title?.trim() ?? '';

         if (queryVersionTitle !== '') {
            return queryVersionTitle;
         }

         let dateSuffix = '';
         if (exploreTab.queryVersion?.modified) {
            const date = new Date(exploreTab.queryVersion.modified);

            dateSuffix = ` - ${
               isToday(date) ? formatDate(date, 'p') : formatDateRelative(date, new Date())
            }`;
         }

         // Has been saved?
         if (
            typeof exploreTab.queryVersion?.version === 'number' ||
            typeof exploreTab.queryVersion?.parentId === 'number'
         ) {
            return `Saved Query${dateSuffix}`;
         }

         const generatedLabel = (exploreTab.generatedLabel ?? '').trim();
         return `${generatedLabel !== '' ? generatedLabel : 'New Query'}${dateSuffix}`;
      }

      if (exploreTab.type === ExploreTabType.Table) {
         return `${exploreTab.tableSchema?.tableName ?? 'Table'}`;
      }

      return 'Query';
   }, [
      exploreTab.generatedLabel,
      exploreTab.queryVersion?.modified,
      exploreTab.queryVersion?.parentId,
      exploreTab.queryVersion?.title,
      exploreTab.queryVersion?.version,
      exploreTab.tableSchema?.tableName,
      exploreTab.type,
   ]);
   return (
      <AiPulse
         key={exploreTab.id}
         on={stepSeven && tabLabel === 'Demo Query #2'}
         onClick={() => {
            if (stepSeven && tabLabel === 'Demo Query #2') {
               setStepSeven();
            }
         }}
         sparkleAfter
      >
         <NavItem
            bsPrefix="explore-tab"
            className={`top-explore-tabs ${tabId === exploreTab.id ? 'active' : ''}`}
         >
            <OverlayTrigger
               delay={{ show: 100, hide: 0 }}
               key={`${exploreTab.id}-query-tab`}
               overlay={
                  <Popover className="tab-custom-tooltip">
                     {exploreTab.queryVersion && exploreTab.queryVersion.steps.length > 0 ? (
                        <>
                           <Popover.Header className="text-muted fs-11p">{tabLabel}</Popover.Header>
                           <Popover.Body className="text-muted fs-11p">
                              {exploreTab.queryVersion.steps.slice(0, 3).map((step, index) => (
                                 <div className="tab-code-block" key={index}>
                                    {step?.queryText
                                       ? step.queryText.length > 50
                                          ? step.queryText.slice(0, 50) + '...'
                                          : step.queryText
                                       : 'No text available'}
                                 </div>
                              ))}
                           </Popover.Body>
                        </>
                     ) : (
                        <Popover.Body className="text-muted fs-11p">
                           <div className="tab-code-block">{tabLabel}</div>
                        </Popover.Body>
                     )}
                  </Popover>
               }
               placement="bottom"
            >
               <NavLink
                  className="position-relative override-active-pointer top-explore-tab-spacing"
                  draggable={false}
                  eventKey={notUndefined(exploreTab.id).toString()}
                  role={'tab'}
               >
                  <Stack direction="horizontal" gap={1}>
                     <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        {exploreTab.queryVersion ? (
                           <IoCodeSlashOutline style={{ flexShrink: 0 }} />
                        ) : (
                           <MdTableChart style={{ flexShrink: 0 }} />
                        )}
                        <span>
                           {tabLabel.length > 20 ? `${tabLabel.substring(0, 20)}...` : tabLabel}
                        </span>
                     </div>
                  </Stack>
               </NavLink>
            </OverlayTrigger>
            <button
               className="btn btn-sm show-hide-on-hover"
               onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (exploreTab.id) onCloseTab(exploreTab.id);
               }}
            >
               {!hasUnsavedTab(exploreTab?.id) ? (
                  <IconX size={18} />
               ) : (
                  <div
                     className="d-flex flex-row align-items-center justify-content-center"
                     style={{ height: '18px', width: '18px' }}
                  >
                     <IconX className="show-on-hover" size={18} />
                     <IconUnsaved className="hide-on-hover" size={10} />
                  </div>
               )}
            </button>
         </NavItem>
      </AiPulse>
   );
};

const ExploreContent = ({
   exploreTab,
   onPendingUpdateChange,
   subTab,
   tabId,
   plan,
}: {
   exploreTab: ExploreTab;
   onPendingUpdateChange?: ((value: boolean, tabId: number) => void) | undefined;
   plan: SERVICE_PLAN;
   subTab: TableEditTab | undefined;
   tabId: number | undefined;
}) => {
   const onPendingUpdateChangeCallback = useCallback(
      (value: boolean) => {
         if (onPendingUpdateChange && exploreTab.id) {
            onPendingUpdateChange(value, exploreTab.id);
         }
      },
      [exploreTab.id, onPendingUpdateChange]
   );
   return (
      <TabPane className="h-100 overflow-hidden" eventKey={exploreTab.id} key={exploreTab.id}>
         {exploreTab.type === ExploreTabType.Query || !exploreTab.type ? (
            <ExploreTabContent
               active={exploreTab.id === tabId}
               exploreTab={exploreTab}
               plan={plan}
            />
         ) : (
            <TableTabContent
               defaultTab={subTab}
               exploreTab={exploreTab}
               onPendingUpdateChange={onPendingUpdateChangeCallback}
            />
         )}
      </TabPane>
   );
};

const localStorageDataConnectionDataSchema = z.object({
   dataConnectionId: z.number(),
   schemaName: z.string().nullable(),
});
const newQueryTab = ({
   workspaceId,
   workspaceSchemaConnections,
}: {
   workspaceId: number;
   workspaceSchemaConnections: WorkspaceSchemaConnection[];
}) => {
   // Set in packages/web/src/pages/Workspace/Explore/QueryPage.tsx
   const localStorageKey = `lastSelectedDataConnectionData-${workspaceId}`;

   const dataConnectionDataJson = localStorage.getItem(localStorageKey);

   if (dataConnectionDataJson === null) {
      return { workspaceId, type: ExploreTabType.Query };
   }

   try {
      const dataConnectionData = localStorageDataConnectionDataSchema.parse(
         JSON.parse(dataConnectionDataJson)
      );

      const match = workspaceSchemaConnections.find(
         ({ dataConnectionId, schemaName }) =>
            dataConnectionId === dataConnectionData.dataConnectionId &&
            (dataConnectionData.schemaName === null || schemaName === dataConnectionData.schemaName)
      );

      if (!match) {
         localStorage.removeItem(localStorageKey);
         return { workspaceId, type: ExploreTabType.Query };
      }

      return {
         workspaceId,
         type: ExploreTabType.Query,
         queryVersion: {
            steps: [
               {
                  ...dataConnectionData,
                  order: 0,
                  queryText: '',
                  type: StepType.DATA_CONNECTION,
               },
            ],
         },
      };
   } catch (err) {
      return { workspaceId, type: ExploreTabType.Query };
   }
};

export const ExploreTabNav = ({
   exploreTabList,
   workspaceId,
   workspaceSchemaConnections = [],
}: {
   exploreTabList: ExploreTab[];
   workspaceId: number;
   workspaceSchemaConnections: WorkspaceSchemaConnection[];
}): JSX.Element => {
   const pathParams = useParams();
   const [searchParams] = useSearchParams();
   const navigate = useNavigate();
   const queryId = pathParams['queryId'] ? Number(pathParams['queryId']) : undefined;
   const tabId = searchParams.has('t') ? Number(searchParams.get('t')) : undefined;
   const subTab = searchParams.has('subTab')
      ? (searchParams.get('subTab') as TableEditTab)
      : undefined;
   const queryVersionId = searchParams.has('v') ? Number(searchParams.get('v')) : undefined;
   const tableSchemaId = pathParams['tableSchemaId']
      ? Number(pathParams['tableSchemaId'])
      : undefined;
   // We hide a tab while deleting for immediate visual feedback
   const [hideTab, setHideTab] = useState<number>();
   const [showCloseConfirmId, setShowCloseConfirmId] = useState<number | undefined>(undefined);
   const [tabsPendingUpdate, setTabsPendingUpdate] = useState<number[]>([]);
   const hasUnsavedTab = useHasUnsavedTab();
   const setUnsavedTab = useSetUnsavedTab();
   const [, setResultTableFilters] = useResultTableFilters();

   // Data Services
   const exploreTabService = useInjection<ExploreTabService>(TYPES.exploreTabService);
   const queryClient = useQueryClient();

   // Mutators
   const addTabMutation = useMutation({
      mutationFn: async (newExploreTab: ExploreTabPost) => {
         return exploreTabService.post(newExploreTab).catch((err) => {
            handleError(err);
         });
      },
      onMutate: async (newExploreTab: ExploreTab) => {
         await queryClient.cancelQueries({
            queryKey: [QueryKey.ExploreTab, 'list', workspaceId],
         });
         const previousTabs = queryClient.getQueryData([QueryKey.ExploreTab, 'list', workspaceId]);

         await queryClient.setQueryData(
            [QueryKey.ExploreTab, 'list', workspaceId],
            (old: any | undefined) => {
               if (old === undefined) {
                  return old;
               } else {
                  return [...old, newExploreTab];
               }
            }
         );

         return { previousTabs };
      },
      onSettled: async () => {
         await queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', workspaceId]);
      },
      onError: (err, _newExploreTab, context) => {
         console.error(err);
         queryClient.setQueryData(
            [QueryKey.ExploreTab, 'list', workspaceId],
            context?.previousTabs
         );
      },
   });

   const ExploreTabCloseConfirm = () => (
      <Modal
         onHide={() => setShowCloseConfirmId(undefined)}
         show={showCloseConfirmId !== undefined}
      >
         <Modal.Header className="border-0 mb-0 pb-0" closeButton>
            <Modal.Title className="fs-14p">Close Tab</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <span>Closing will discard unsaved changes.</span>
            <div className="d-flex justify-content-end mt-2">
               <Button
                  onClick={() => setShowCloseConfirmId(undefined)}
                  size="sm"
                  variant="secondary"
               >
                  Cancel
               </Button>
               <Button
                  className="ms-2"
                  onClick={() => {
                     removeTabFromExplorer(showCloseConfirmId || -1);
                     setShowCloseConfirmId(undefined);
                     setUnsavedTab(tabId, false);
                  }}
                  size="sm"
                  type="button"
               >
                  Discard
               </Button>
            </div>
         </Modal.Body>
      </Modal>
   );

   const deleteMutation = useMutation({
      mutationFn: async (variables: { id: number; workspaceId: number }) => {
         await exploreTabService.delete(variables.id).catch((err) => {
            handleError(err);
         });
         return variables.workspaceId;
      },
      onSuccess: async (workspaceId) => {
         await queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', workspaceId]);
      },
   });

   // Page Functions
   const setActiveExploreTab = useCallback(
      (tab: ExploreTab) => {
         if (
            tab?.type === ExploreTabType.Query &&
            tab?.id &&
            tab?.queryVersion?.id &&
            tab?.queryVersion?.queryId
         ) {
            const params = new URLSearchParams({
               v: tab.queryVersion.id.toString(),
               t: tab.id.toString(),
            }).toString();
            navigate(`/workspaces/${workspaceId}/query/${tab.queryVersion.queryId}?${params}`, {
               replace: true,
            });
         } else if (tab?.id && tab?.type === ExploreTabType.Table && tab?.tableSchemaId) {
            const paramBuilder = new URLSearchParams({
               t: tab.id.toString(),
            });
            if (subTab) {
               paramBuilder.set('subTab', subTab);
            }
            const params = paramBuilder.toString();
            navigate(`/workspaces/${workspaceId}/table/${tab.tableSchemaId}?${params}`, {
               replace: true,
            });
         } else {
            navigate(`/workspaces/${workspaceId}`, { replace: true });
         }
      },
      [navigate, subTab, workspaceId]
   );

   const addTabMutate = addTabMutation.mutateAsync;
   const addTab = useCallback(
      async (tab: ExploreTabPost) => {
         await addTabMutate(tab).then((result) => result?.id && setActiveExploreTab(result));
      },
      [addTabMutate, setActiveExploreTab]
   );

   const selectTab = useCallback(
      (eventKey: string | null) => {
         if (eventKey === null) return;
         const tab = exploreTabList.find((et) => et.id === Number(eventKey));
         if (tab) {
            setActiveExploreTab(tab);
         }
      },
      [exploreTabList, setActiveExploreTab]
   );

   useHotkeys(`Alt+t`, () => addTab(newQueryTab({ workspaceId, workspaceSchemaConnections })), [
      addTab,
      workspaceId,
   ]);

   const removeTabFromExplorer = useCallback(
      async (tabId: number) => {
         setHideTab(tabId);
         const currIndex = exploreTabList.findIndex((tab) => tab.id === tabId);
         if (currIndex > 0) {
            setActiveExploreTab(exploreTabList[currIndex - 1]);
         } else if (currIndex + 1 < exploreTabList.length) {
            setActiveExploreTab(exploreTabList[currIndex + 1]);
         } else {
            await addTab(newQueryTab({ workspaceId, workspaceSchemaConnections }));
         }
         deleteMutation.mutateAsync({ id: tabId, workspaceId: workspaceId });
      },
      [
         exploreTabList,
         deleteMutation,
         workspaceId,
         setActiveExploreTab,
         addTab,
         workspaceSchemaConnections,
      ]
   );
   const closeTab = useCallback(
      async (tabId: number) => {
         const tab: ExploreTab | undefined = exploreTabList.find((et) => et.id === tabId);
         if (
            (!tab?.queryVersion?.version && tab?.tableSchemaId === undefined) ||
            tabsPendingUpdate.includes(tabId) ||
            hasUnsavedTab(tabId)
         ) {
            setShowCloseConfirmId(tabId);
         } else {
            removeTabFromExplorer(tabId);
         }
         // Any filters auto-applied when following FK table must be removed on close.
         setResultTableFilters((prev?: ResultTableFilterType) => {
            if (prev?.[workspaceId] && prev[workspaceId][tab?.tableSchemaId || -1]) {
               prev[workspaceId][tab?.tableSchemaId || -1] = {};
            }
            return prev;
         });
      },
      [
         exploreTabList,
         removeTabFromExplorer,
         tabsPendingUpdate,
         setResultTableFilters,
         workspaceId,
         hasUnsavedTab,
      ]
   );

   const setPendingUpdate = useCallback((value: boolean, tabId: number) => {
      setTabsPendingUpdate((pending) => {
         if (value === true) {
            return [...pending, tabId];
         } else {
            return pending.filter((id) => id !== tabId);
         }
      });
   }, []);

   useHotkeys(`Alt+w`, () => tabId && closeTab(tabId), [closeTab, tabId]);
   useSubscribe('close-tab', closeTab, [closeTab]);

   // Open (or create) the correct tab for the current URL
   useEffect(() => {
      // Check if the current tab already has the correct queryVersion
      if (
         tabId &&
         exploreTabList.find((et) => et.id === tabId)?.queryVersion?.id === queryVersionId
      ) {
         return;
      }

      // Check if the queryVersion is in another tab
      if (queryVersionId) {
         const tab = exploreTabList.find((et) => et.queryVersionId === queryVersionId);
         if (tab) {
            setActiveExploreTab(tab);
            return;
         }
      }

      // If a query was specified, create a new tab for it
      if (queryId) {
         if (searchParams.get('tabOpened') !== '1') {
            addTab({
               type: ExploreTabType.Query,
               workspaceId,
               queryId,
               queryVersionId,
            });
            return;
         }
         return;
      }

      // If a table was specified, check if it's in a tab already, if not create a new tab
      if (tableSchemaId) {
         const tab = exploreTabList.find((et) => et.tableSchemaId === tableSchemaId);
         if (tab) {
            setActiveExploreTab(tab);
            return;
         } else {
            addTab({
               type: ExploreTabType.Table,
               workspaceId,
               tableSchemaId,
            });
            return;
         }
      }

      if (exploreTabList.length) {
         // If there are open tabs, switch to the last one
         setActiveExploreTab(exploreTabList[exploreTabList.length - 1]);
      } else {
         // If there's no tab, create one
         addTab(newQueryTab({ workspaceId, workspaceSchemaConnections }));
      }
   }, [
      workspaceId,
      queryId,
      queryVersionId,
      tabId,
      exploreTabList,
      setActiveExploreTab,
      addTab,
      addTabMutate,
      searchParams,
      tableSchemaId,
      workspaceSchemaConnections,
   ]);

   const exploreTabsToDisplay = useMemo(
      () => exploreTabList.filter((tab) => tab.id !== hideTab),
      [exploreTabList, hideTab]
   );

   const personService = useInjection<PersonService>(TYPES.personService);
   const explorer = useGetAuthorizedExplorerQuery();
   const [plan, setPlan] = useState<SERVICE_PLAN>(SERVICE_PLANS[0]);
   useEffect(() => {
      const personId = explorer?.data?.person?.id;
      if (personId) {
         personService.getPlan(personId).then((plan) => {
            if (plan !== undefined) {
               setPlan(plan);
            }
         });
      }
   }, [personService, explorer?.data?.person?.id]);

   return (
      <PanelGroup autoSaveId="explore" className="flex-grow-1" direction="horizontal">
         <Panel className="h-100 starting-block-panel" id="secondary" minSize={14}>
            <Stack className="h-100">
               <WorkspaceHeader />
               <StartingBlock workspaceId={workspaceId} />
            </Stack>
         </Panel>
         <PanelResizeHandle className="panelHandle vertical">
            <div className="panelHandleLine" />
         </PanelResizeHandle>
         <Panel defaultSize={75} id="primary" minSize={40}>
            <TabContainer activeKey={tabId} onSelect={selectTab} transition={false}>
               <div className="h-100 d-flex flex-column overflow-hidden">
                  <header>
                     <Nav
                        className="d-flex flex-row flex-nowrap align-items-center bg-secondary"
                        id="exploreTabNav"
                        role="tablist"
                     >
                        <div className="explore-tabs explore-tabs-scrollable">
                           {exploreTabsToDisplay.map((et) => (
                              <ExploreNavigation
                                 exploreTab={et}
                                 key={et.id}
                                 onCloseTab={closeTab}
                                 tabId={tabId}
                              />
                           ))}
                        </div>
                        <NavItem bsPrefix="explore-tab tab-no-right-border">
                           <OverlayTrigger
                              key="start-a-new-query-tooltip"
                              overlay={<Tooltip placement="top">Start a new query</Tooltip>}
                           >
                              <button
                                 className="btn btn-sm btn-link opacity-75"
                                 onClick={() =>
                                    addTab(newQueryTab({ workspaceId, workspaceSchemaConnections }))
                                 }
                                 style={{ height: '27px', width: '36px' }}
                              >
                                 <MdAddCircle size={18} />
                              </button>
                           </OverlayTrigger>
                        </NavItem>
                     </Nav>
                  </header>
                  <PanelGroup
                     autoSaveId="exploreInner"
                     className="flex-grow-1"
                     direction="horizontal"
                  >
                     <Panel defaultSize={80} id="primary" minSize={40}>
                        <TabContent className="h-100">
                           {exploreTabsToDisplay.map((et) => (
                              <ErrorBoundary
                                 key={`eb-${et.id}`}
                                 message="Please try closing and reopening the tab. If this issue persists, contact us."
                              >
                                 <ExploreContent
                                    exploreTab={et}
                                    key={et.id}
                                    onPendingUpdateChange={setPendingUpdate}
                                    plan={plan}
                                    subTab={subTab}
                                    tabId={tabId}
                                 />
                              </ErrorBoundary>
                           ))}
                        </TabContent>
                     </Panel>
                  </PanelGroup>
                  <ExploreTabCloseConfirm />
               </div>
            </TabContainer>
         </Panel>
      </PanelGroup>
   );
};

export default ExploreTabNav;
