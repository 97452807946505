import * as Sentry from '@sentry/react';

const Fallback = ({
   title = 'Something went wrong.',
   message = 'Please try reloading the page. If this issue persists, contact us.',
}: {
   message?: string;
   title?: string;
}) => {
   return (
      <div className="error-boundary">
         <span style={{ fontSize: '18px', alignSelf: 'center' }}>{title}</span>
         <span style={{ fontSize: '14px', alignSelf: 'center' }}>{message}</span>
      </div>
   );
};

export const ErrorBoundary = ({
   message,
   title,
   ...props
}: React.ComponentProps<typeof Sentry.ErrorBoundary> & { message?: string; title?: string }) => {
   return (
      <Sentry.ErrorBoundary {...props} fallback={<Fallback message={message} title={title} />} />
   );
};

export default ErrorBoundary;
