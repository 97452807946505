import { useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '../enums';
import { GetTableMetaResponse, SchemaCacheService } from '../services';
import { queryBuilderFactory } from '../utilities';
import { TYPES } from '../types';
import { useRunSystemQuery } from '.';

import type { QueryKey as ReactQueryQueryKey } from 'react-query';
import type { QueryReturn } from '../interfaces';
import type { SchemaCache } from '../entities';

type FetchTableMetaQueryKeyParamsDataBase = {
   dataConnectionId: number;
};
type FetchTableMetaQueryKeyParamsFetch = {
   data: FetchTableMetaQueryKeyParamsDataBase & {
      schemaName: string;
      tableName: string;
   };
   type: 'fetch';
};
type FetchTableMetaQueryKeyParamsInvalidate = {
   data: FetchTableMetaQueryKeyParamsDataBase & {
      schemaName?: string;
      tableName?: string;
   };
   type: 'invalidate';
};
type FetchTableMetaQueryKeyParams =
   | FetchTableMetaQueryKeyParamsFetch
   | FetchTableMetaQueryKeyParamsInvalidate;
export const fetchTableMetaQueryKey = ({ type, data }: FetchTableMetaQueryKeyParams) => {
   const baseKey = ['dataConnection', data.dataConnectionId, QueryKey.MetaForTableId];

   if (type === 'fetch') {
      return [...baseKey, data.schemaName, data.tableName];
   }

   if (data.schemaName === undefined) {
      return baseKey;
   }

   const schemaKey = [...baseKey, data.schemaName];

   if (data.tableName === undefined) {
      return schemaKey;
   }

   return [...schemaKey, data.tableName];
};
export const useFetchTableMetaQuery = ({
   callbacks,
   tableSchema,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: GetTableMetaResponse | undefined) => void;
   };
   tableSchema: SchemaCache;
}) => {
   if (!tableSchema.dataConnection.id) {
      throw new Error('Bad implementation');
   }

   const schemaCacheService = useInjection<SchemaCacheService>(TYPES.schemaCacheService);
   const queryClient = useQueryClient();

   const QUERY_KEY = fetchTableMetaQueryKey({
      type: 'fetch',
      data: {
         dataConnectionId: tableSchema.dataConnection.id,
         schemaName: tableSchema.schemaName,
         tableName: tableSchema.tableName,
      },
   });

   const invalidate = async (additionalQueryKeys: ReactQueryQueryKey[] = []) => {
      await Promise.all(
         [QUERY_KEY, ...additionalQueryKeys].map((key) => queryClient.invalidateQueries(key))
      );
   };

   const tableMetaQuery = useQuery<GetTableMetaResponse | undefined>(
      QUERY_KEY,
      async () => {
         if (!tableSchema.id) {
            return undefined;
         }

         return schemaCacheService.getTableMeta(tableSchema.id);
      },
      {
         enabled: !!tableSchema.id,
         keepPreviousData: false,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess(data) {
            return callbacks?.onSuccess?.(data);
         },
         onError: (err) => {
            return callbacks?.onError?.(err);
         },
      }
   );

   return { tableMetaQuery, invalidate };
};

export const fetchTableDataQueryKey = ({
   dataConnectionId,
   schemaName,
   tableName,
}: {
   dataConnectionId: number;
   schemaName?: string;
   tableName?: string;
}) => {
   const baseKey = ['dataConnection', dataConnectionId, QueryKey.TableContentForTableSchemaId];

   if (schemaName === undefined) {
      return baseKey;
   }

   const schemaKey = [...baseKey, schemaName];

   if (tableName === undefined) {
      return schemaKey;
   }

   return [...schemaKey, tableName];
};
export const useFetchTableDataQuery = ({
   enable = true,
   tableSchema,
   workspaceId,
}: {
   enable?: boolean;
   tableSchema: SchemaCache;
   workspaceId: number;
}) => {
   if (!tableSchema.dataConnection.id || !tableSchema.dataConnection.dbms) {
      throw new Error('Bad implementation');
   }
   const queryClient = useQueryClient();

   const QUERY_KEY = fetchTableDataQueryKey({
      dataConnectionId: tableSchema.dataConnection.id,
      schemaName: tableSchema.schemaName,
      tableName: tableSchema.tableName,
   });

   const invalidate = async (additionalQueryKeys: ReactQueryQueryKey[] = []) => {
      await Promise.all(
         [QUERY_KEY, ...additionalQueryKeys].map((key) => queryClient.invalidateQueries(key))
      );
   };

   const qb = useMemo(
      () => queryBuilderFactory(tableSchema.dataConnection.dbms!),
      [tableSchema.dataConnection.dbms]
   );
   const query = useMemo(
      () =>
         qb.fetchTableContent({
            schema: tableSchema.schemaName,
            table: tableSchema.tableName,
            catalog: tableSchema.catalogName,
         }),
      [qb, tableSchema]
   );

   const { run } = useRunSystemQuery();

   const result = useQuery<QueryReturn>(
      QUERY_KEY,
      () =>
         run({
            dataConnection: tableSchema.dataConnection,
            query,
            workspaceId,
         }),
      {
         refetchOnWindowFocus: false,
         retry: false,
         enabled: enable,
      }
   );

   return { result, invalidate };
};
