import { SyntaxItem } from '../interfaces';
import { Base } from './Base';
import { QueryToken } from './QueryToken';
import { QueryStep } from './QueryStep';
import { Workspace } from './Workspace';
import { SchemaOverride } from './DataConnection';

export enum QueryState {
   PRIVATE = 0,
   SHARED = 1,
}

export interface Query extends Base {
   aiSuggestionVersion?: QueryVersion;
   aiSuggestionVersionId?: number;
   approvedVersion?: QueryVersion;
   approvedVersionId?: number;
   forkedFromVersion?: QueryVersion;
   forkedFromVersionId?: number;
   latestVersion?: QueryVersion;
   latestVersionId?: number;
   reviewVersion?: QueryVersion;
   reviewVersionId?: number;
   state?: QueryState;
   token?: QueryToken;
   versions?: QueryVersion[];
   workspace?: Workspace;
   workspaceId: number;
}

export interface QueryVersion extends Base {
   aiSuggestion?: boolean;
   chartConfig?: ChartConfig;
   chartSuggestions?: SuggestedCharts;

   description?: string;
   explanation?: string;
   generatedDescription?: boolean;
   generatedExplanation?: boolean;
   generatedQuestion?: boolean;
   generatedTitle?: boolean;
   minorVersion?: number;
   notes?: string;
   parameters?: QueryParameter[];
   parent?: QueryVersion;
   parentId?: number;
   query?: Query;
   queryCommentCount?: number;
   queryComments?: QueryComment[];
   queryId?: number;
   question?: string;
   schemaChanges?: {
      columnAdditions: { columnName: string; columnType: string; tableName: string }[];
      columnDeletions: { columnName: string; tableName: string }[];
      columnNameChanges: { newColumnName: string; oldColumnName: string; tableName: string }[];
      tableAdditions: { tableName: string }[];
      tableDeletions: { tableName: string }[];
      tableNameChanges: { newTableName: string; oldTableName: string }[];
   };
   steps: QueryStep[];
   tags?: SyntaxItem[];
   title?: string;
   version?: number;
   workspaceId?: number;
   workspaceName?: string;
}

export interface QueryVersionLog extends QueryVersion {
   isLog?: boolean;
   queryVersionId: number;
   runtime?: number;
}

export interface QueryComment extends Base {
   queryVersionId: number;
   text: string;
}

export interface QueryParameter {
   defaultValue: string;
   exclude: boolean;
   name: string;
}

export interface QueryVersionPost {
   publish?: boolean;
   queryVersion: QueryVersion;
   workspaceId: number;
}

export interface QueryVersionPatch {
   approve?: boolean;
   generateComments?: boolean;
   generateDocumentation?: boolean;
   generateExplanation?: boolean;
   newComment?: string;
   publish?: boolean;
   queryVersion: Partial<QueryVersion> & { id: number };
   review?: boolean;
   share?: boolean;
}

export interface QueryRunOptions {
   exploreTabId?: number;
   overrideSchema?: SchemaOverride;
   params?: Record<string, string>;
   query?: string;
   step?: number;
   stopAfterStep?: number;
}

export interface SystemQueryRun {
   dataConnectionId: number;
   exploreTabId?: number;
   query: string;
   updateSchema?: {
      target?: { schemaName: string; tableName: string; type: 'table' };
      value: true;
   };
   workspaceId?: number;
}

export interface ChartConfig {
   chartConfig: any;
   dataMapping?: { seriesName: string; x?: string; y: string }[];
}

export interface SuggestedCharts {
   chartTypes: string[];
   reasoning: string[];
}

export function nextQueryVersion(queryVersion?: QueryVersion) {
   const query = queryVersion?.query;
   let major, minor;
   if (
      query?.approvedVersion?.version &&
      query?.approvedVersion?.version === query?.latestVersion?.version
   ) {
      major = query?.approvedVersion.version + 1;
      minor = 0;
   } else {
      major = query?.latestVersion?.version ?? 1;
      minor = (query?.latestVersion?.minorVersion ?? -1) + 1;
   }
   return `v${major}.${minor}`;
}
