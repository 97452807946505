import { DBMS } from '../enums/dbms';

export type PromptType = {
   id: string;
   label: string;
   query: string;
};

export const PromptTypes: Partial<Record<DBMS, PromptType[]>> = {
   [DBMS.Hugging_Face]: [
      {
         id: 'question-answering',
         label: 'Question Answering',
         query: `{
    "inputs": [{
        "question": "",
        "context": ""
    }]
}`,
      },
      {
         id: 'sentence-similarity',
         label: 'Sentence Similarity',
         query: `{
    "inputs": {
        "source_sentence": "",
        "sentences": [
        ]
    }
}`,
      },
      {
         id: 'text-classification',
         label: 'Text Classification',
         query: `{
    "inputs": [
        ""
    ]
}`,
      },
      {
         id: 'zero-shot-classification',
         label: 'Zero Shot Classification',
         query: `{
    "inputs": [
    ],
    "parameters": {
        "candidate_labels": [
        ],
        "multi_label": false
    }
}`,
      },
   ],
};
