import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Stack } from 'react-bootstrap';

import { WEB_VERSION } from '../version';
import { Button } from '../components/Button';

// versionCheck.ts
export async function fetchCurrentVersion(): Promise<string | null> {
   try {
      const response = await fetch('/version.json', { cache: 'no-store' });
      const data = (await response.json()) as { version: string };
      return data.version;
   } catch (error) {
      console.error('Failed to fetch version file:', error);
      return null;
   }
}

const TOAST_ID = '__RUNQL_UPDATE_AVAILABLE__';

const ToastContainer = ({ children }: React.PropsWithChildren<{}>) => (
   <Stack className="align-items-center py-1 px-2" gap={1}>
      {children}
   </Stack>
);

export function useVersionCheck() {
   const [ready, setReady] = useState(false);
   const [upgrade, setUpgrade] = useState<string>();
   const [desktopUpdate, setDesktopUpdate] = useState<string>();
   const desktopUpdateDownloadMetaRef = useRef({
      isDownloading: false,
      numDownloadAttempts: 0,
      success: false,
   });

   useEffect(() => {
      const checkVersion = async () => {
         // Skip if we're already downloading an update
         if (desktopUpdateDownloadMetaRef.current.isDownloading) {
            return;
         }

         // -- Check for desktop update

         if (
            globalThis.runql?.checkForUpdates !== undefined &&
            !desktopUpdateDownloadMetaRef.current.success &&
            desktopUpdateDownloadMetaRef.current.numDownloadAttempts < 3
         ) {
            // If an update is available, it will be automatically downloaded.
            const desktopUpdateCheckResult = await globalThis.runql.checkForUpdates();

            if (desktopUpdateCheckResult !== null && desktopUpdateCheckResult.downloadPromise) {
               setReady(true);
               desktopUpdateDownloadMetaRef.current.isDownloading = true;
               desktopUpdateDownloadMetaRef.current.numDownloadAttempts += 1;

               try {
                  await desktopUpdateCheckResult.downloadPromise;
                  desktopUpdateDownloadMetaRef.current.success = true;
                  setDesktopUpdate(desktopUpdateCheckResult.updateInfo.version);
                  return;
               } catch (err) {
                  Sentry.captureException(err, {
                     extra: { debug: 'Error downloading desktop update' },
                  });
               } finally {
                  desktopUpdateDownloadMetaRef.current.isDownloading = false;
               }
            }
         }

         // -- Check for web update

         const latestVersion = await fetchCurrentVersion();
         if (latestVersion && latestVersion !== WEB_VERSION) {
            setUpgrade(latestVersion);
         } else {
            setReady(true);
         }
      };

      checkVersion();
      const intervalId = setInterval(checkVersion, 1000 * 60 * 5);

      return () => clearInterval(intervalId);
   }, []);

   useEffect(() => {
      if (desktopUpdate !== undefined) {
         toast(
            <ToastContainer>
               <span className="fw-semibold">A new version of runQL is available!</span>
               <span>Please restart to install the latest version</span>
               <Button
                  className="align-self-stretch"
                  onClick={() => {
                     globalThis.runql?.restartAndInstall();
                  }}
               >
                  Restart
               </Button>
            </ToastContainer>,
            {
               autoClose: false,
               toastId: TOAST_ID,
            }
         );
         return;
      }

      if (upgrade !== undefined) {
         // We only auto-refresh once for a given version (via else block below), just in case
         // something is wrong with the update process (e.g. version.txt is out of sync or there's a
         // caching issue).
         const attempt = localStorage.getItem('upgrade-attempt');
         if (ready || attempt === upgrade) {
            toast(
               <ToastContainer>
                  <span className="fw-semibold">runQL just got better!</span>
                  <Button
                     className="align-self-stretch"
                     onClick={() => {
                        window.location.reload();
                     }}
                  >
                     Update Now
                  </Button>
               </ToastContainer>,
               {
                  autoClose: false,
                  toastId: TOAST_ID,
               }
            );
            setReady(true);
         } else {
            // If we detect an upgrade on initial load, simply refresh
            localStorage.setItem('upgrade-attempt', upgrade);
            window.location.reload();
         }
      }
   }, [ready, upgrade, desktopUpdate]);

   return ready;
}
