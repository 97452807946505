import { useInjection } from 'inversify-react';
import { useMemo, useState } from 'react';
import { OverlayTrigger, Popover, Col, Dropdown, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
   CollaboratorRole,
   Person,
   Workspace,
   WorkspaceCollaborator,
   WorkspaceStatus,
} from '../../entities';
import { ADMIN_ROLES, PersonRole, QueryKey } from '../../enums';
import {
   useGetAuthorizedExplorerQuery,
   useUploadFileToWorkspaceMutator,
   useWorkspace,
   fetchWorkspaceQueryKey,
} from '../../hooks';
import { WorkspaceCollaboratorService, WorkspaceService } from '../../services';
import { TYPES } from '../../types';
import { handleError, IconMenu } from '../../utilities';
import { ManageWorkspaceCollaborators } from './ManageWorkspaceCollaborators';
import ManageWorkspaceConnections from '../../components/ManageWorkspaceConnections';
import { MdInfoOutline } from 'react-icons/md';
import { UpgradeModal } from '../../pages';

import type { FetchWorkspaceResult } from '../../services';

function getExplorerCanEdit(
   explorer: Person | undefined,
   workspace: FetchWorkspaceResult,
   explorerCollab: WorkspaceCollaborator | undefined
): boolean {
   if (explorer === undefined) {
      return false;
   }

   if (workspace.status === WorkspaceStatus.PERSONAL_DEMO) {
      return false;
   }

   if (ADMIN_ROLES.includes(explorer.role ?? PersonRole.ORG_EXPLORER)) {
      // Admins can edit
      return true;
   }

   if (explorerCollab !== undefined && explorerCollab.role === CollaboratorRole.ADMIN) {
      // workspace admins can edit
      return true;
   }

   if (workspace.membersCanInvite) {
      // if workspace allows members to invite all can edit
      return true;
   }

   return false;
}

export const EditWorkspaceModal = ({
   show,
   workspace,
   onClose,
   onSave,
}: {
   onClose(): void;
   onSave: (params: { body: Workspace; updatedWorkspace: FetchWorkspaceResult }) => void;
   show: boolean;
   workspace: FetchWorkspaceResult;
}): JSX.Element => {
   const [validated, setValidated] = useState(false);
   const [nameTouched, setNameTouched] = useState(false);
   const [workspaceName, setWorkspaceName] = useState<string>(workspace.name || 'My Workspace');
   const [description, setDescription] = useState<string>(workspace.description ?? '');
   const [canInvite, setCanInvite] = useState<boolean>(workspace.membersCanInvite ?? false);

   const handleWorkspaceChange = () => {
      if (
         workspace.name !== workspaceName ||
         workspace.description !== description ||
         workspace.membersCanInvite !== canInvite
      ) {
         const body = {
            name: workspaceName,
            description,
            membersCanInvite: canInvite,
         };
         onSave({ updatedWorkspace: { ...workspace, ...body }, body });
      }
   };

   const handleSubmit = (event: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
   }) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      }
      setValidated(true);
      setNameTouched(false);
   };

   return (
      <Modal centered={true} onHide={onClose} show={show}>
         <Modal.Header className="border-0 mb-0 pb-0" closeButton={true}>
            Edit Workspace
         </Modal.Header>
         <Modal.Body>
            <Row>
               <Col xs={12}>
                  <Form.Label className="fs-10p fw-normal text-muted">Workspace Name</Form.Label>
               </Col>
               <Col className="pe-0" xs={5}>
                  <Form noValidate onSubmit={handleSubmit} validated={validated}>
                     <Form.Control
                        className="form-control ps-2 pe-2 pt-2 pb-2"
                        isInvalid={
                           workspaceName ? nameTouched && workspaceName.length < 3 : nameTouched
                        }
                        onBlur={() => setNameTouched(true)}
                        onChange={(event) => setWorkspaceName(event.currentTarget.value)}
                        type="text"
                        value={workspaceName ?? 'My Workspace'}
                     />
                     <Form.Control.Feedback type="invalid">
                        Workspace name must be at least 3 characters.
                     </Form.Control.Feedback>
                  </Form>
               </Col>
               <Col className="gy-2" xs={12}>
                  <Form.Label className="fs-10p fw-normal text-muted">
                     Workspace Description
                  </Form.Label>
                  <Form.Control
                     as="textarea"
                     className="form-control ps-2 pe-2 pt-2 pb-2"
                     onChange={(event) => setDescription(event.currentTarget.value)}
                     type="text"
                     value={description}
                  />
               </Col>
               <Col className="gy-2" xs={12}>
                  <Form.Label className="fs-10p fw-normal text-muted">
                     Workspace members can invite others
                  </Form.Label>
                  <Form.Check
                     checked={canInvite}
                     onChange={(event) => setCanInvite(event.currentTarget.checked)}
                     type="switch"
                  />
               </Col>
            </Row>
         </Modal.Body>
         <Modal.Footer className="border-0 mt-0 pt-0">
            <button
               className="btn btn-sm btn-primary"
               disabled={workspaceName ? workspaceName.length < 3 : false}
               onClick={() => {
                  onClose();
                  handleWorkspaceChange();
               }}
            >
               Save
            </button>
         </Modal.Footer>
      </Modal>
   );
};

export const UploadModal = ({
   show,
   workspaceId,
   onClose,
}: {
   onClose(): void;
   show: boolean;
   workspaceId: number;
}): JSX.Element => {
   const [importFile, setImportFile] = useState<File | undefined>();
   const [saving, setSaving] = useState(false);
   const queryClient = useQueryClient();

   const onUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
         setImportFile(event.target.files[0]);
      }
   };

   const uploadFileMutation = useUploadFileToWorkspaceMutator();
   const uploadFile = async () => {
      if (!importFile) return;

      setSaving(true);
      try {
         const formData = new FormData();
         formData.append('file', importFile);
         formData.append('fileName', importFile.name);
         formData.append('type', importFile.type);
         await uploadFileMutation.mutateAsync({ id: workspaceId, uploadFile: formData });
         await queryClient.invalidateQueries([QueryKey.Queries, 'list']);
         onClose();
      } catch (e) {
         console.error(e);
         handleError(e);
      }
      setSaving(false);
   };

   return (
      <Modal centered={true} onHide={onClose} show={show}>
         <Modal.Header className="border-0 mb-0 pb-0" closeButton={true}>
            Import SQL Files
         </Modal.Header>
         <Modal.Body>
            <Form.Group>
               <Form.Label>Files (.zip or .sql)</Form.Label>
               <Form.Control accept=".zip, .sql" onChange={onUploadChange} type="file" />
            </Form.Group>
         </Modal.Body>
         <Modal.Footer className="border-0 mt-0 pt-0">
            <button className="btn btn-sm btn-primary" disabled={!importFile} onClick={uploadFile}>
               {saving && (
                  <>
                     <Spinner
                        animation="border"
                        aria-hidden="true"
                        as="span"
                        className="me-2"
                        role="status"
                        size="sm"
                     />{' '}
                     Importing...
                  </>
               )}
               {!saving && <>Import</>}
            </button>
         </Modal.Footer>
      </Modal>
   );
};

export const WorkspaceMenu = (): JSX.Element => {
   const workspace = useWorkspace();
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const workspaceCollaboratorService = useInjection<WorkspaceCollaboratorService>(
      TYPES.workspaceCollaboratorService
   );
   const workspaceId = workspace.id;
   const queryClient = useQueryClient();

   const [showModal, setShowModal] = useState<string | undefined>();
   const [showUpgradeModal, setShowUpgradeModal] = useState(false);

   const queryKey = fetchWorkspaceQueryKey({ workspaceId });

   const updateWorkspaceMutation = useMutation({
      mutationFn: async ({
         updatedWorkspace,
         body,
      }: {
         body: Workspace;
         updatedWorkspace: FetchWorkspaceResult;
      }) => {
         workspaceService.patch(updatedWorkspace.id, body).catch((err) => handleError(err));
      },
      async onMutate({
         updatedWorkspace,
         body,
      }: {
         body: Workspace;
         updatedWorkspace: FetchWorkspaceResult;
      }) {
         //optimistic update
         queryClient.cancelQueries({
            queryKey,
         });
         const previousWorkspace = queryClient.getQueryData(queryKey);
         queryClient.setQueryData(queryKey, updatedWorkspace);

         return { previousWorkspace, updatedWorkspace };
      },
      onError(error, variables, context) {
         //undo optimistic update
         queryClient.setQueryData(queryKey, context?.previousWorkspace);
         handleError(error);
      },
      onSettled: async () => {
         //Refresh after error or success
         await Promise.all([queryClient.invalidateQueries([QueryKey.Workspace, 'list']), queryKey]);
      },
   });

   const explorer = useGetAuthorizedExplorerQuery();

   const collaboratorFilters = {
      personDetails: true,
      workspaceId: workspaceId,
   };
   const workspaceCollaboratorQuery = useQuery<WorkspaceCollaborator[]>(
      [QueryKey.WorkspaceCollaborator, 'list', collaboratorFilters],
      () => workspaceCollaboratorService.listOptions(collaboratorFilters),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );
   const explorerCollaborator = useMemo(() => {
      return (workspaceCollaboratorQuery.data ?? []).find(
         (collab) => collab.personId === explorer.data?.person.id
      );
   }, [workspaceCollaboratorQuery.data, explorer.data]);

   const explorerCanEdit = useMemo(() => {
      return getExplorerCanEdit(explorer.data?.person, workspace, explorerCollaborator);
   }, [explorer.data, workspace, explorerCollaborator]);

   if (!explorerCanEdit) {
      return <></>;
   }

   return (
      <div className="d-flex align-items-center justify-content-end">
         <Dropdown>
            <Dropdown.Toggle
               className="text-primary p-1"
               style={{ minWidth: '26px' }}
               variant="link"
            >
               <IconMenu size={16} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
               {(explorerCollaborator?.role === CollaboratorRole.ADMIN ||
                  explorer.data?.person.role === PersonRole.ORG_ADMIN) && (
                  <Dropdown.Item onClick={() => setShowModal('workspace')}>
                     Edit Workspace
                  </Dropdown.Item>
               )}
               {(explorerCollaborator?.role === CollaboratorRole.ADMIN ||
                  explorer.data?.person.role === PersonRole.ORG_ADMIN) && (
                  <Dropdown.Item onClick={() => setShowModal('connections')}>
                     Attach/Remove Schemas
                  </Dropdown.Item>
               )}

               {explorerCollaborator?.role === CollaboratorRole.ADMIN ||
               workspace.membersCanInvite ||
               explorer.data?.person.role === PersonRole.ORG_ADMIN ? (
                  <Dropdown.Item onClick={() => setShowModal('collaborators')}>
                     Add/Remove/Edit Collaborators
                  </Dropdown.Item>
               ) : (
                  <></>
               )}
               <Dropdown.Item onClick={() => setShowModal('upload')}>Import SQL</Dropdown.Item>
               <Dropdown.Item
                  onClick={() =>
                     (window.location.href = workspaceService.exportWorkspaceUrl(workspaceId))
                  }
               >
                  Export Queries
               </Dropdown.Item>
            </Dropdown.Menu>
         </Dropdown>
         {workspace?.status !== WorkspaceStatus.PROTECTED && (
            <EditWorkspaceModal
               onClose={() => setShowModal(undefined)}
               onSave={(workspace) => updateWorkspaceMutation.mutateAsync(workspace)}
               show={showModal === 'workspace'}
               workspace={workspace}
            />
         )}
         {workspaceId && (
            <ManageWorkspaceConnections
               onClose={() => setShowModal(undefined)}
               show={showModal === 'connections'}
               workspaceId={workspaceId}
            />
         )}
         {workspace?.status !== WorkspaceStatus.PROTECTED && (
            <ManageWorkspaceCollaborators
               explorer={explorer.data?.person}
               onClose={() => setShowModal(undefined)}
               setShowUpgradeModal={() => setShowUpgradeModal(true)}
               show={showModal === 'collaborators'}
               workspaceId={workspaceId}
            />
         )}
         <UploadModal
            onClose={() => setShowModal(undefined)}
            show={showModal === 'upload'}
            workspaceId={workspaceId}
         />
         {showUpgradeModal && (
            <UpgradeModal onClose={() => setShowUpgradeModal(false)} show={showUpgradeModal} />
         )}
      </div>
   );
};

export const WorkspaceHeader = (): JSX.Element => {
   const workspace = useWorkspace();
   return (
      <header className="d-flex align-items-center justify-content-between w-100 px-2 py-workspace-description border-bottom-line">
         <div className="app-no-drag">
            <OverlayTrigger
               overlay={
                  workspace?.description ? (
                     <Popover className="p-2">{workspace.description}</Popover>
                  ) : (
                     <></>
                  )
               }
               placement="bottom"
            >
               <div className="d-flex align-items-center text-nowrap lh-1">
                  <div className="fw-600 text-truncate overflow-hidden">
                     {workspace?.name ?? 'Unnamed Workspace'}
                  </div>
                  {workspace?.description && (
                     <div className="ps-1 fw-400 opacity-50" style={{ marginTop: '-2px' }}>
                        <MdInfoOutline size={16} />
                     </div>
                  )}
               </div>
            </OverlayTrigger>
         </div>
         <div className="d-flex align-items-center">
            <WorkspaceMenu />
         </div>
      </header>
   );
};

export default WorkspaceHeader;
