import { useInjection } from 'inversify-react';
import { useCallback, useState } from 'react';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';

import { useAddCredentials, RunButton } from '../components';
import {
   ConnectionAccessType,
   QueryRunOptions,
   QueryVersion,
   QueryLog,
   StepType,
   SystemQueryRun,
   QueryStep,
   QueryLogContext,
   DataConnection,
   logsFromQueryReturn,
   SchemaOverride,
} from '../entities';

import { QueryKey, QueryKeyType } from '../enums';
import { QueryReturn } from '../interfaces';
import { QueryService, QueryLogService, isDesktop, DesktopQueryService } from '../services';
import { TYPES } from '../types';
import { handleError, queryIsDangerous } from '../utilities';
import { getQueryLogQueryKey, useFetchListWorkspaceConnections } from './';
import { useCurrentQuery } from '.';

import type { LocalCredentialService } from '../services/LocalCredentialService';
import type { DataConnectionService } from '../services/DataConnectionService';
import type { DataCredentialsModalProps } from '../components/DataCredentialsModal';

export type RunStatus = {
   isRunning?: boolean;
   results?: QueryReturn[];
};

const handleMissingCredentialsFactory =
   <T,>({
      addCredentials,
      dataConnectionId,
      runThunk,
      workspaceId,
   }: {
      addCredentials: (params: Omit<DataCredentialsModalProps, 'show'>) => void;
      dataConnectionId: number;
      runThunk: () => Promise<T>;
      workspaceId: number;
   }) =>
   () =>
      new Promise<T>((resolve, reject) => {
         addCredentials({
            connectionId: dataConnectionId,
            handleClose: async (success: boolean) => {
               if (!success) {
                  return reject(new Error('Please add credentials to run this query'));
               }

               try {
                  const result = await runThunk();
                  resolve(result);
               } catch (err) {
                  reject(err);
               }
            },
            workspaceId,
         });
      });

const getStepsToRun = (
   steps: QueryStep[],
   { onlyStep, stopAfterStep }: { onlyStep?: number; stopAfterStep?: number } = {}
) =>
   steps.filter(
      (step) =>
         (onlyStep === undefined || step.order === onlyStep) &&
         (stopAfterStep === undefined || step.order <= stopAfterStep)
   );

export const useRunQueries = ({
   exploreTabId,
   onStatusChange,
}: {
   exploreTabId?: number;
   onStatusChange?: (queryVersion: QueryVersion, status: RunStatus) => void;
} = {}) => {
   const [_runStatus, _setRunStatus] = useState<Record<number, RunStatus>>({});
   const { paramOverrides } = useCurrentQuery();

   const runStatus = useCallback(
      (queryVersion: QueryVersion) => _runStatus[queryVersion.query?.id ?? queryVersion.id!],
      [_runStatus]
   );
   const setRunStatus = useCallback(
      (queryVersion: QueryVersion, status: RunStatus) => {
         const id = queryVersion.query?.id ?? queryVersion.id;
         if (!id) return;
         _setRunStatus((runStatus) => ({
            ...runStatus,
            [id]: status,
         }));
         onStatusChange && onStatusChange(queryVersion, status);
      },
      [onStatusChange]
   );
   const [ignoreDangerous, setIgnoreDangerous] = useState(false);
   const [promptDangerousQuery, setPromptDangerousQuery] = useState<{
      queryVersion: QueryVersion;
      step?: number;
      stopAfterStep?: number;
   }>();

   const queryService = useInjection<QueryService>(TYPES.queryService);
   const queryLogService = useInjection<QueryLogService>(TYPES.querylogService);
   const desktopQueryService = useInjection<DesktopQueryService>(TYPES.desktopQueryService);
   const queryClient = useQueryClient();
   const refresh = (count: number) => {
      queryClient.invalidateQueries([QueryKey.QueryLog]);
      setTimeout(() => {
         count--;
         if (count > 0) {
            refresh(count);
         }
      }, 1000);
   };
   const runQuery = useMutation({
      onSuccess: () => {
         refresh(3);
      },
      mutationFn: async ({
         queryVersion,
         options,
      }: {
         options: QueryRunOptions;
         queryVersion: QueryVersion;
      }) => {
         options = {
            ...options,
            params: paramOverrides,
         };
         if (!isDesktop()) {
            return queryService.runQuery(queryVersion, options);
         }

         const stepsToRun = getStepsToRun(queryVersion.steps, {
            onlyStep: options.step,
            stopAfterStep: options.stopAfterStep,
         });

         if (options.overrideSchema) {
            stepsToRun.forEach((step) => {
               if (step.type === StepType.DATA_CONNECTION) {
                  step.dataConnection = options.overrideSchema?.dataConnection;
                  step.dataConnectionId = options.overrideSchema?.dataConnection.id;
                  step.schemaName = options.overrideSchema?.schemaName;
               }
            });
         }

         const [localSteps, cloudSteps] = stepsToRun.reduce(
            (acc, step) => {
               const isLocal =
                  step.type === StepType.DATA_CONNECTION &&
                  step.dataConnection?.connectionAccessType === ConnectionAccessType.INDIVIDUAL;

               acc[isLocal ? 0 : 1].push(step);

               return acc;
            },
            [[], []] as [QueryStep[], QueryStep[]]
         );

         // Local-only?
         if (localSteps.length === stepsToRun.length) {
            const results = await desktopQueryService.runQuery(
               stepsToRun as Array<{
                  dataConnection: DataConnection;
                  queryText: string;
                  schemaName?: string;
               }>,
               Object.fromEntries(
                  (queryVersion.parameters ?? []).map((p) => [
                     p.name,
                     (options.params ?? {})[p.name] ?? p.defaultValue,
                  ])
               ),
               options.step && options.query
                  ? { step: options.step, query: options.query }
                  : undefined
            );

            try {
               await queryLogService.post(
                  logsFromQueryReturn({
                     steps: stepsToRun,
                     queryVersion,
                     results,
                     exploreTabId: options.exploreTabId,
                  })
               );
            } catch (err) {
               // Don't fail the query if we fail to log (e.g. due to network issues)
               console.error('Error logging query', err);
            }
            return results;
         }

         // Cloud-only or Mixed
         // Include credential headers if local steps are present
         return queryService.runQuery(
            queryVersion,
            options,
            cloudSteps.length !== stepsToRun.length
         );
      },
   });

   const fetchWorkspaceConnections = useFetchListWorkspaceConnections();
   const addCredentials = useAddCredentials();
   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );

   const run = useCallback(
      async (
         queryVersion: QueryVersion,
         {
            overrideSchema,
            query,
            step: onlyStep,
            stopAfterStep,
            suppressDangerousWarning,
         }: {
            overrideSchema?: SchemaOverride;
            query?: string;
            step?: number;
            stopAfterStep?: number;
            suppressDangerousWarning?: boolean;
         } = {}
      ): Promise<void> => {
         if (!queryVersion.id) throw new Error('Query version must be saved before running');
         if (!queryVersion.query?.workspaceId) throw new Error('Query must have a workspace');
         if (runStatus(queryVersion)?.isRunning) return;
         try {
            if (
               !ignoreDangerous &&
               !suppressDangerousWarning &&
               queryVersion.steps.some(
                  (step) => suppressDangerousWarning || queryIsDangerous(step.queryText ?? '')
               )
            ) {
               setPromptDangerousQuery({
                  queryVersion,
                  step: queryVersion.steps.find(
                     (step) => suppressDangerousWarning || queryIsDangerous(step.queryText ?? '')
                  )?.order,
                  stopAfterStep,
               });
               return;
            }

            const workspaceSchemaConnections = await fetchWorkspaceConnections({
               workspaceId: queryVersion.query.workspaceId,
               includeConnectionDetails: true,
            });

            const stepsWithMissingCredentials = (
               await Promise.all(
                  getStepsToRun(queryVersion.steps, { onlyStep, stopAfterStep }).map(
                     async (step) => {
                        if (step.type !== StepType.DATA_CONNECTION || !step.dataConnectionId) {
                           return [];
                        }

                        const dataConnectionId = step.dataConnectionId;

                        const connection = workspaceSchemaConnections.find(
                           (wdc) => wdc.dataConnection?.id === dataConnectionId
                        )?.dataConnection;

                        if (
                           !connection?.id ||
                           connection.connectionAccessType !== ConnectionAccessType.INDIVIDUAL
                        ) {
                           return [];
                        }

                        const hasCredentials = await localCredentialService.has(connection.id);

                        return hasCredentials ? [] : [{ ...step, dataConnectionId }];
                     }
                  )
               )
            ).flat();

            if (stepsWithMissingCredentials.length > 0) {
               return handleMissingCredentialsFactory({
                  addCredentials,
                  dataConnectionId: stepsWithMissingCredentials[0].dataConnectionId,
                  runThunk: () =>
                     run(queryVersion, {
                        step: onlyStep,
                        stopAfterStep,
                        suppressDangerousWarning: true,
                     }),
                  workspaceId: queryVersion.query.workspaceId,
               })();
            }

            setRunStatus(queryVersion, {
               isRunning: true,
            });

            const results = await runQuery.mutateAsync({
               queryVersion,
               options: {
                  exploreTabId,
                  query,
                  step: onlyStep,
                  stopAfterStep,
                  overrideSchema,
               },
            });

            if (results !== undefined) {
               setRunStatus(queryVersion, {
                  results: results ?? undefined,
               });
               queryClient.invalidateQueries(getQueryLogQueryKey({ type: QueryKeyType.LIST }));
            } else {
               setRunStatus(queryVersion, {});
            }
         } catch (err) {
            handleError(err);
            setRunStatus(queryVersion, {});
         }
      },
      [
         addCredentials,
         queryClient,
         fetchWorkspaceConnections,
         runQuery,
         exploreTabId,
         ignoreDangerous,
         runStatus,
         setRunStatus,
         localCredentialService,
      ]
   );

   const runButton = (queryVersion: QueryVersion) => (
      <RunButton
         disabled={!queryVersion?.steps?.[0]?.queryText}
         key="run"
         onClick={() => run(queryVersion)}
         running={!!queryVersion.id && runStatus(queryVersion)?.isRunning}
      >
         Run
      </RunButton>
   );

   const modals = (
      <>
         <Modal show={!!promptDangerousQuery}>
            <Modal.Header>
               <Modal.Title className="fs-14p">Dangerous Query</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div>
                  This query will modify the database.
                  <br />
                  Do you want to continue?
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Form>
                  <Form.Check
                     checked={ignoreDangerous}
                     label="Don't warn for this query again"
                     onChange={(event) => setIgnoreDangerous(event.target.checked)}
                     type="checkbox"
                  />
                  <Stack className="justify-content-end" direction="horizontal" gap={2}>
                     <Button
                        className={'py-1 btn-secondary'}
                        onClick={() => setPromptDangerousQuery(undefined)}
                     >
                        Cancel
                     </Button>
                     <RunButton
                        onClick={() => {
                           if (!promptDangerousQuery) return;
                           run(promptDangerousQuery?.queryVersion, {
                              suppressDangerousWarning: true,
                              step: promptDangerousQuery?.step,
                              stopAfterStep: promptDangerousQuery?.stopAfterStep,
                           });
                           setPromptDangerousQuery(undefined);
                        }}
                        running={
                           !!promptDangerousQuery?.queryVersion.id &&
                           runStatus(promptDangerousQuery.queryVersion)?.isRunning
                        }
                     />
                  </Stack>
               </Form>
            </Modal.Footer>
         </Modal>
      </>
   );

   return { run, runButton, runStatus, modals, paramOverrides };
};

export const useRunQuery = (
   qv?: QueryVersion,
   {
      exploreTabId,
   }: {
      exploreTabId?: number;
   } = {}
) => {
   const { run, runButton, runStatus, modals } = useRunQueries({ exploreTabId });
   const runThis = useCallback(
      ({
         overrideSchema,
         query,
         queryVersion,
         step,
         stopAfterStep,
      }: {
         overrideSchema?: SchemaOverride;
         query?: string;
         queryVersion?: QueryVersion;
         step?: number;
         stopAfterStep?: number;
      } = {}) => qv && run(queryVersion ?? qv, { query, step, stopAfterStep, overrideSchema }),
      [qv, run]
   );

   return {
      run: runThis,
      runButton: qv && runButton(qv),
      modals,
      ...(qv?.id ? runStatus(qv) : {}),
   };
};

export const useRunSystemQuery = () => {
   const dataConnectionService = useInjection<DataConnectionService>(TYPES.dataConnectionService);
   const queryService = useInjection<QueryService>(TYPES.queryService);
   const queryLogService = useInjection<QueryLogService>(TYPES.querylogService);
   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );
   const desktopQueryService = useInjection<DesktopQueryService>(TYPES.desktopQueryService);
   const [isRunning, setIsRunning] = useState(false);
   const addCredentials = useAddCredentials();

   const run = useCallback(
      async ({
         dataConnection,
         query,
         exploreTabId,
         workspaceId,
         updateSchema,
      }: Omit<SystemQueryRun, 'dataConnectionId'> & {
         dataConnection: DataConnection | number;
      }): Promise<QueryReturn> => {
         if (!workspaceId) throw new Error('Workspace ID is required');

         // You can pass in a DataConnection object or just the id.
         if (typeof dataConnection === 'number') {
            const result = await dataConnectionService.get(dataConnection);

            if (!result) {
               throw new Error('DataConnection ID is required');
            }

            dataConnection = result;
         }

         if (!dataConnection.id) {
            throw new Error('DataConnection ID is required');
         }

         const handleMissingCredentials = handleMissingCredentialsFactory({
            addCredentials,
            dataConnectionId: dataConnection.id,
            runThunk: () =>
               run({
                  dataConnection,
                  query,
                  exploreTabId,
                  workspaceId,
                  updateSchema,
               }),
            workspaceId,
         });

         if (
            !isDesktop() ||
            dataConnection.connectionAccessType !== ConnectionAccessType.INDIVIDUAL
         ) {
            if (dataConnection.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
               const hasCredentials = await localCredentialService.has(dataConnection.id);

               if (!hasCredentials) {
                  return handleMissingCredentials();
               }
            }

            try {
               setIsRunning(true);
               const result = await queryService.runSystemQuery(
                  {
                     dataConnectionId: dataConnection.id!,
                     query,
                     exploreTabId,
                     workspaceId,
                     updateSchema,
                  },
                  !isDesktop() &&
                     dataConnection.connectionAccessType === ConnectionAccessType.INDIVIDUAL
               );

               return result;
            } finally {
               setIsRunning(false);
            }
         }

         // -- On desktop + ConnectionAccessType.INDIVIDUAL

         const hasCredentials = await localCredentialService.has(dataConnection.id);

         if (!hasCredentials) {
            return handleMissingCredentials();
         }

         try {
            setIsRunning(true);

            const [result] = await desktopQueryService.runQuery({
               dataConnection,
               queryText: query,
            });

            try {
               const log: QueryLog = {
                  context: QueryLogContext.SYSTEM,
                  dataConnectionId: dataConnection.id,
                  queryText: query,
                  runtime: result.runtime,
                  step: 1,
                  workspaceId,
                  exploreTabId,
               };
               await queryLogService.post([log]);
            } catch (err) {
               // Don't fail the query if we fail to log (e.g. due to network issues)
               console.error('Error logging system query', err);
            }

            if (result.error) {
               throw result.error instanceof Error ? result.error : new Error(result.error);
            }

            if (updateSchema?.value) {
               try {
                  await dataConnectionService.updateSchemaDesktop(
                     dataConnection,
                     updateSchema.target
                  );
               } catch (err) {
                  // Don't fail the query if we fail to update the schema
                  console.error('Error updating schema', err);
               }
            }

            return result;
         } finally {
            setIsRunning(false);
         }
      },
      [
         addCredentials,
         queryService,
         queryLogService,
         dataConnectionService,
         desktopQueryService,
         localCredentialService,
      ]
   );

   return { run, isRunning };
};
