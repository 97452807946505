import { memo, useEffect, useState, useMemo } from 'react';

import { BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';
import { DBMS } from '../../enums/dbms';
import { QueryInlineComment } from '../../entities';

const CodeViewer = memo(
   ({
      dialect,
      query,
      hideComments,
      maxLines,
      schema,
      comments, // deprecated
   }: {
      comments?: QueryInlineComment[];
      dialect?: DBMS;
      hideComments?: boolean;
      maxLines?: number;
      query?: string;
      schema?: Record<string, string[]>;
   }): JSX.Element => {
      query ??= '';

      const [collapsed, setCollapsed] = useState(!!maxLines);
      useEffect(() => {
         setCollapsed(!!maxLines);
      }, [maxLines]);

      const formattedQuery = useMemo(() => {
         return query
            .split('\n')
            .map((line) => line.split(/(?<=^|\s)--/))
            .map(([query, comment], index) => [
               query,
               comment?.trim() || comments?.find((c) => c.line === index)?.comment,
            ]);
      }, [query, comments]);

      if (comments && !Array.isArray(comments)) {
         comments = [comments];
      }

      return (
         <div style={{ position: 'relative' }}>
            <div className="dimmed-query">
               <code
                  className={`cm-editor code-viewer ${collapsed ? 'collapsed-code-viewer' : ''}`}
               >
                  {formattedQuery
                     .slice(0, collapsed ? maxLines : undefined)
                     .map(([query, comment], index) => (
                        <div className="query-line" key={index}>
                           {query}
                           {comment && (
                              <span className="query-comment">&nbsp;--&nbsp;{comment}</span>
                           )}
                        </div>
                     ))}
               </code>
            </div>

            {collapsed && formattedQuery.length > maxLines! && (
               <div
                  onClick={(e) => {
                     e.stopPropagation();
                     setCollapsed(false);
                  }}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
               >
                  <BiExpandVertical className="code-view-icon-overlay" size={14} />
               </div>
            )}
            {maxLines && !collapsed && (
               <div
                  onClick={(e) => {
                     e.stopPropagation();
                     setCollapsed(true);
                  }}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
               >
                  <BiCollapseVertical className="code-view-icon-overlay" size={14} />
               </div>
            )}
         </div>
      );
   }
);
export default CodeViewer;
