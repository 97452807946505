import { useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';
import { QueryKey, QueryKeyType } from '../../enums';
import {
   GetOptionsQueryLog,
   ListOptionsQueryLog,
   QueryLogService,
   SearchService,
} from '../../services';
import { LogSearchResponse, PaginatedResult, QueryLog } from '../../entities';
import { TYPES } from '../../types';

const useQueryLogService = () => {
   return useInjection<QueryLogService>(TYPES.querylogService);
};

export const useListQueryLogs = (
   {
      callbacks,
      listOptions,
      queryOptions,
   }: {
      callbacks?: {
         onError?: (err: unknown) => void;
         onSuccess?: (data: PaginatedResult<QueryLog>) => void;
      };
      listOptions?: ListOptionsQueryLog;
      queryOptions?: { enabled?: boolean };
   } = { callbacks: undefined, listOptions: undefined, queryOptions: undefined }
) => {
   const querylogService = useQueryLogService();
   return useQuery<PaginatedResult<QueryLog>>(
      getQueryLogQueryKey({ type: QueryKeyType.LIST, options: listOptions }),
      () => querylogService.listOptions(listOptions),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         enabled: queryOptions?.enabled,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export function getQueryLogQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsQueryLog | GetOptionsQueryLog;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.QueryLog, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}

export const usePerformLogSearch = ({
   dataConnectionIds,
   schemaNames,
   tableNames,
   creatorIds,
   skipSize,
   pageSize,
   workspaceIds,
   searchText,
   callbacks,
   allWorkspaces = false,
}: {
   allWorkspaces?: boolean;
   callbacks?: {
      onError?: (error: unknown) => void;
      onSuccess?: (data: LogSearchResponse | undefined) => void;
   };
   creatorIds: number[];
   dataConnectionIds: number[];
   pageSize: number;
   schemaNames: string[];
   searchText: string;
   skipSize: number;
   tableNames: string[];
   workspaceIds: number[];
}) => {
   const searchService = useInjection<SearchService>(TYPES.searchService);

   const queryKey = useMemo(
      () => [
         QueryKey.QueryLog,
         allWorkspaces,
         workspaceIds,
         dataConnectionIds,
         schemaNames,
         tableNames,
         creatorIds,
         searchText,
         { skipSize, pageSize },
      ],
      [
         allWorkspaces,
         workspaceIds,
         dataConnectionIds,
         schemaNames,
         tableNames,
         creatorIds,
         searchText,
         skipSize,
         pageSize,
      ]
   );

   return useQuery<LogSearchResponse | undefined>(
      queryKey,
      async () => {
         return await searchService.logSearch(
            dataConnectionIds,
            schemaNames,
            tableNames,
            creatorIds,
            workspaceIds,
            skipSize,
            pageSize,
            searchText,
            allWorkspaces
         );
      },
      {
         staleTime: 1000 * 60 * 2,
         refetchOnMount: 'always',
         retry: false,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};
