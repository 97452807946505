import React, { createContext, useState, useContext, useCallback } from 'react';

import { DataConnection, QueryVersion } from '../entities';

type QueryProviderProps = {
   // If not set, queryVersion is read-only
   onChange?: (
      changes: Partial<
         Pick<QueryVersion, 'steps' | 'title' | 'description' | 'question' | 'parameters'>
      >,
      options: { debounce?: boolean }
   ) => void;
   queryVersion?: QueryVersion;
};

type QueryContextType = QueryProviderProps & {
   editResultsDataConnections: DataConnection[];
   editResultsSchemaNames: string[];
   onParamChange: (key: string, value: string) => void;
   paramOverrides: Record<string, string>;
   setEditResultsDataConnections: React.Dispatch<React.SetStateAction<DataConnection[]>>;
   setEditResultsSchemaNames: React.Dispatch<React.SetStateAction<string[]>>;
};

const noopDC: React.Dispatch<React.SetStateAction<DataConnection[]>> = () => {};
const noopSN: React.Dispatch<React.SetStateAction<string[]>> = () => {};

const QueryContext = createContext<QueryContextType>({
   editResultsDataConnections: [],
   editResultsSchemaNames: [],
   onParamChange: (_, __) => {},
   paramOverrides: {},
   setEditResultsDataConnections: noopDC,
   setEditResultsSchemaNames: noopSN,
});

export function QueryProvider({
   onChange,
   children,
   ...rest
}: QueryProviderProps & {
   children: React.JSX.Element;
}) {
   const [paramOverrides, setParamOverrides] = useState<Record<string, string>>({});
   const [editResultsDataConnections, setEditResultsDataConnections] = useState<DataConnection[]>(
      []
   );
   const [editResultsSchemaNames, setEditResultsSchemaNames] = useState<string[]>([]);
   const onParamChange = useCallback(
      (key: string, value: string) => {
         // If changing the query is supported, we don't need param overrides
         if (onChange) return;
         setParamOverrides((params) => ({ ...params, [key]: value }));
      },
      [onChange]
   );

   return (
      <QueryContext.Provider
         value={{
            ...rest,
            paramOverrides,
            onChange,
            onParamChange,
            editResultsDataConnections,
            editResultsSchemaNames,
            setEditResultsDataConnections,
            setEditResultsSchemaNames,
         }}
      >
         {children}
      </QueryContext.Provider>
   );
}

export function useCurrentQuery() {
   return useContext(QueryContext);
}
