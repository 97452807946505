/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Button, Nav, Stack } from 'react-bootstrap';
import Page from '../../components/Page';
import { useTitle } from '../../utilities';
import Markdown from 'react-markdown';
import {
   dataConnectionQuestions,
   generalQuestions,
   runSourceQuestions,
   //securityQuestions,
} from './docs';
import SearchInput from '../../components/UI/SearchInput';

export type Section = {
   answer: string;
   group: string;
   id?: string;
   question: string;
};

const sections = [
   ...generalQuestions,
   ...dataConnectionQuestions,
   ...runSourceQuestions,
   //...securityQuestions,
];

export const DocumentationPage = (): JSX.Element => {
   useTitle('Documentation');

   const [selectedGroup, setSelectedGroup] = useState<string>(sections[0].group);
   const [groupedSections, setGroupedSections] = useState<
      Record<string, { answer: string; index: number; question: string }[]>
   >(groupSections(sections));
   const [filteredSections, setFilteredSections] = useState<Section[]>(sections);

   function groupSections(
      sectionsToGroup: Section[]
   ): Record<string, { answer: string; index: number; question: string }[]> {
      return sectionsToGroup.reduce((groups, section, index) => {
         const group = groups[section.group] || [];
         group.push({ index: index, ...section });
         groups[section.group] = group;
         return groups;
      }, {} as Record<string, { answer: string; index: number; question: string }[]>);
   }

   const filterDocs = (text: string) => {
      const lowercasedText = text.toLowerCase();
      const newFilteredSections = sections.filter(
         (section) =>
            section.question.toLowerCase().includes(lowercasedText) ||
            section.answer.toLowerCase().includes(lowercasedText)
      );

      setFilteredSections(newFilteredSections);
      const newGroupedSections = groupSections(newFilteredSections);
      setGroupedSections(newGroupedSections);

      const newGroups = Object.keys(newGroupedSections);
      if (newGroups.length > 0) {
         setSelectedGroup(newGroups[0]);
         const section = newGroupedSections[newGroups[0]][0];
         const hash = `${newGroups[0].trim().replace(/ /g, '_')}-${section.index}-${section.question
            .trim()
            .replace(/ /g, '_')}`;
         window.history.replaceState(null, '', `#${hash}`);
      } else {
         setSelectedGroup('');
         window.history.replaceState(null, '', '/documentation');
      }
   };

   const updateHash = (hash: string) => {
      window.history.pushState(null, '', `#${hash}`);
   };
   const hasScrolledRef = useRef(false);

   const handleHashChange = () => {
      const currentHash = window.location.hash.slice(1);
      const [, indexStr] = currentHash.split('-');
      const index = parseInt(indexStr, 10);
      if (!isNaN(index) && filteredSections[index]) {
         const section = filteredSections[index];
         setSelectedGroup(section.group);
         const currentWindowHash = `${section.group
            .trim()
            .replace(/ /g, '_')}-${index}-${section.question.trim().replace(/ /g, '_')}`;
         const element = document.getElementById(currentWindowHash);
         if (element) {
            element.scrollIntoView();
            hasScrolledRef.current = true;
         }
      }
   };

   useEffect(() => {
      handleHashChange();
      window.addEventListener('hashchange', handleHashChange);
      return () => window.removeEventListener('hashchange', handleHashChange);
   }, [filteredSections, groupedSections, selectedGroup]);

   useLayoutEffect(() => {
      const currentHash = window.location.hash.slice(1);
      if (currentHash) {
         const element = document.getElementById(currentHash);
         if (element) {
            element.scrollIntoView();
            hasScrolledRef.current = true;
         }
      }
   }, []);

   return (
      <Page
         nav={
            <Nav className="flex-column">
               <Stack className="position-relative p-2" gap={3}>
                  <SearchInput entityName="Documentation" onTextChanged={filterDocs} />
                  <Button
                     className="fs-10p"
                     onClick={() => window.open('mailto:support@runql.com', '_blank')}
                     size="sm"
                  >
                     Contact Us
                  </Button>
               </Stack>
               {Object.keys(groupedSections).length > 0 ? (
                  Object.keys(groupedSections)?.map((group) => (
                     <div key={group}>
                        <div className="mt-3 ms-3 fw-bold fs-16">{group}</div>
                        {groupedSections[group]?.map((section) => {
                           const hashId = `${group.trim().replace(/ /g, '_')}-${
                              section.index
                           }-${section.question.trim().replace(/ /g, '_')}`;
                           return (
                              <Nav.Link
                                 href={`#${hashId}`}
                                 key={section.index}
                                 onClick={() => {
                                    setSelectedGroup(group);
                                    updateHash(hashId);
                                    hasScrolledRef.current = false;
                                 }}
                              >
                                 {section.question}
                              </Nav.Link>
                           );
                        })}
                     </div>
                  ))
               ) : (
                  <div className="ms-3 mt-3">No results found for your search.</div>
               )}
            </Nav>
         }
      >
         {Object.keys(groupedSections).length > 0 &&
            Object.entries(groupedSections).map(([group, sections]) =>
               sections.map((section) => {
                  const hashId = `${group.trim().replace(/ /g, '_')}-${
                     section.index
                  }-${section.question.trim().replace(/ /g, '_')}`;
                  return (
                     <div id={hashId} key={hashId}>
                        {group === selectedGroup && <Markdown>{section.answer}</Markdown>}
                     </div>
                  );
               })
            )}
      </Page>
   );
};

export default DocumentationPage;
