import _keyBy from 'lodash/keyBy';
import { memo, useMemo } from 'react';
import { Badge, NavDropdown, Stack } from 'react-bootstrap';
import { MdCable, MdKeyboardArrowDown } from 'react-icons/md';

import { DBMS_SCHEMA_SELECT_SUPPORTED } from '@runql/util';
import { FaDatabase, FaLock } from 'react-icons/fa';
import LoadingError from '../components/UI/LoadingError';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import {
   ConnectionAccessType,
   DataConnection,
   StepType,
   WorkspaceSchemaConnection,
} from '../entities';
import { useFeature, useListWorkspaceConnectionsQuery, useOverrideSchema } from '../hooks';
import { getErrorMessage } from '../utilities';

const WorkspaceConnectionSelector = memo(
   ({
      dataConnectionId,
      onChange,
      onlyShared,
      readOnly,
      schemaName = null,
      type,
      workspaceId,
   }: {
      dataConnectionId?: number;
      onChange: (
         dataConnection: DataConnection | undefined,
         schemaName: string | null | undefined,
         type: StepType
      ) => void;
      onlyShared?: boolean;
      readOnly?: boolean;
      schemaName?: string | null;
      type: StepType;
      workspaceId: number;
   }): JSX.Element => {
      const federationEnabled = useFeature('federation');
      const pythonEnabled = useFeature('python');

      const workspaceSchemaConnectionsResult = useListWorkspaceConnectionsQuery({
         workspaceId: workspaceId,
         includeConnectionDetails: true,
      });

      const dataConnectionsFilteredAndSorted = useMemo(
         () =>
            (workspaceSchemaConnectionsResult.data ?? [])
               .map((val) => val.dataConnection)
               .filter(
                  (val, idx, array): val is DataConnection =>
                     val !== undefined &&
                     (!onlyShared ||
                        val.connectionAccessType !== ConnectionAccessType.INDIVIDUAL) &&
                     idx === array.findIndex((v) => v?.id === val.id)
               )
               .sort((a, b) =>
                  (a.name ?? 'Unknown').localeCompare(b.name ?? 'Unknown', undefined, {
                     sensitivity: 'base',
                  })
               ),
         [workspaceSchemaConnectionsResult.data, onlyShared]
      );

      const dataConnectionsById = useMemo(
         () => _keyBy(dataConnectionsFilteredAndSorted, 'id'),
         [dataConnectionsFilteredAndSorted]
      );

      const selectedDataConnection = useMemo(() => {
         if (type !== StepType.DATA_CONNECTION || dataConnectionId === undefined) {
            return undefined;
         }

         return dataConnectionsById[dataConnectionId];
      }, [type, dataConnectionId, dataConnectionsById]);

      const workspaceSchemaConnectionsGroupedAndSorted = useMemo(
         () =>
            Object.fromEntries(
               Object.entries(
                  (workspaceSchemaConnectionsResult.data ?? []).reduce<
                     Record<number, WorkspaceSchemaConnection[]>
                  >((acc, workspaceSchemaConnection) => {
                     acc[workspaceSchemaConnection.dataConnectionId] ??= [];
                     acc[workspaceSchemaConnection.dataConnectionId].push(
                        workspaceSchemaConnection
                     );
                     return acc;
                  }, {})
               ).map(([dataConnectionId, workspaceSchemaConnections]) => [
                  dataConnectionId,
                  workspaceSchemaConnections.sort((a, b) =>
                     a.schemaName.localeCompare(b.schemaName, undefined, { sensitivity: 'base' })
                  ),
               ])
            ),
         [workspaceSchemaConnectionsResult.data]
      );

      const overrideSchema = useOverrideSchema();

      const overrideConnection = useMemo(() => {
         if (overrideSchema) {
            return dataConnectionsFilteredAndSorted.find(
               (val) => val.id === overrideSchema.dataConnection.id
            );
         }
         return undefined;
      }, [dataConnectionsFilteredAndSorted, overrideSchema]);

      // Render component
      if (workspaceSchemaConnectionsResult.isLoading) {
         return <LoadingSpinner />;
      }

      if (workspaceSchemaConnectionsResult.isError) {
         return <LoadingError message={getErrorMessage(workspaceSchemaConnectionsResult.error)} />;
      }

      const pythonLabel = (
         <Stack direction="horizontal" gap={1}>
            <div>Python</div>
            <Badge>beta</Badge>
         </Stack>
      );
      const federatedLabel = (
         <Stack direction="horizontal" gap={1}>
            <div>Federated</div>
            <Badge>beta</Badge>
         </Stack>
      );

      let currentDataConnectionEventKey: string | null = null;
      let connectionLabel = <>None</>;
      switch (type) {
         case StepType.DATA_CONNECTION:
            if (overrideSchema) {
               currentDataConnectionEventKey =
                  overrideSchema.dataConnection?.id?.toString() ?? null;
               connectionLabel = <>{overrideConnection?.name ?? 'Unknown'}</>;
            } else if (dataConnectionId) {
               currentDataConnectionEventKey = dataConnectionId.toString();
               connectionLabel = <>{selectedDataConnection?.name ?? 'Unknown'}</>;
            }
            break;
         case StepType.FEDERATED:
            currentDataConnectionEventKey = '__RUNQL_FEDERATED__';
            connectionLabel = federatedLabel;
            break;
         case StepType.PYTHON:
            currentDataConnectionEventKey = '__RUNQL_PYTHON__';
            connectionLabel = pythonLabel;
            break;
      }

      if (readOnly) {
         return (
            <Stack className="fs-11p" direction="horizontal" gap={1}>
               <MdCable size={14} />
               {connectionLabel}
            </Stack>
         );
      }

      return (
         <Stack
            className={`workspaceSelector ${overrideSchema ? 'schema-highlighted' : ''}`}
            direction="horizontal"
            gap={1}
         >
            {/* Data connections */}
            <NavDropdown
               className="fs-10p ps-0 dropdownMenuSmall w-auto"
               disabled={overrideSchema !== undefined}
               onSelect={(eventKey) => {
                  // No change?
                  if (eventKey === null || eventKey === currentDataConnectionEventKey) {
                     return;
                  }

                  if (eventKey === '__RUNQL_FEDERATED__') {
                     onChange(undefined, undefined, StepType.FEDERATED);
                     return;
                  }

                  if (eventKey === '__RUNQL_PYTHON__') {
                     onChange(undefined, undefined, StepType.PYTHON);
                     return;
                  }

                  const nextDataConnection = dataConnectionsById[eventKey];

                  if (!nextDataConnection?.id) {
                     return;
                  }

                  const workspaceSchemaConnectionsForNextDataConnection =
                     workspaceSchemaConnectionsGroupedAndSorted[nextDataConnection.id] ?? [];

                  onChange(
                     nextDataConnection,
                     workspaceSchemaConnectionsForNextDataConnection[0]?.schemaName ?? null,
                     StepType.DATA_CONNECTION
                  );
               }}
               title={
                  <>
                     {overrideSchema ? <FaLock size={14} /> : <MdCable size={14} />}
                     &nbsp;
                     <span
                        style={{
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                        }}
                     >
                        {connectionLabel}
                     </span>
                     <MdKeyboardArrowDown size={14} />
                  </>
               }
            >
               {dataConnectionsFilteredAndSorted.map((dataConnection) => {
                  return (
                     <NavDropdown.Item
                        className="fs-11p"
                        eventKey={dataConnection.id}
                        key={dataConnection.id}
                     >
                        {dataConnection.name ?? 'Unknown'}
                     </NavDropdown.Item>
                  );
               })}
               {pythonEnabled && (
                  <NavDropdown.Item className="fs-11p" eventKey="__RUNQL_PYTHON__">
                     {pythonLabel}
                  </NavDropdown.Item>
               )}
               {federationEnabled && (
                  <NavDropdown.Item className="fs-11p" eventKey="__RUNQL_FEDERATED__">
                     {federatedLabel}
                  </NavDropdown.Item>
               )}
            </NavDropdown>

            {/* Schemas */}
            {selectedDataConnection?.dbms &&
               DBMS_SCHEMA_SELECT_SUPPORTED.includes(selectedDataConnection.dbms) && (
                  <NavDropdown
                     className="fs-10p ps-0 dropdownMenuSmall w-auto"
                     disabled={overrideSchema !== undefined}
                     onSelect={(eventKey) => {
                        if (eventKey === null || eventKey === '__RQL_NONE__') {
                           onChange(selectedDataConnection, null, StepType.DATA_CONNECTION);
                           return;
                        }
                        onChange(selectedDataConnection, eventKey, StepType.DATA_CONNECTION);
                     }}
                     title={
                        <>
                           {overrideSchema ? <FaLock size={14} /> : <FaDatabase size={14} />}
                           &nbsp;
                           <span
                              style={{
                                 whiteSpace: 'nowrap',
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                              }}
                           >
                              {overrideSchema
                                 ? overrideSchema.schemaName
                                    ? overrideSchema.schemaName
                                    : 'None Selected'
                                 : typeof schemaName === 'string'
                                 ? schemaName
                                 : 'None Selected'}
                           </span>
                           <MdKeyboardArrowDown size={14} />
                        </>
                     }
                  >
                     {(dataConnectionId !== undefined
                        ? workspaceSchemaConnectionsGroupedAndSorted[dataConnectionId] ?? []
                        : []
                     ).map((val) => {
                        return (
                           <NavDropdown.Item
                              className="fs-11p"
                              eventKey={val.schemaName}
                              key={val.id}
                           >
                              {val.schemaName}
                           </NavDropdown.Item>
                        );
                     })}
                     <NavDropdown.Item className="fs-11p" eventKey="__RQL_NONE__">
                        None Selected
                     </NavDropdown.Item>
                  </NavDropdown>
               )}
         </Stack>
      );
   }
);

export default WorkspaceConnectionSelector;
