import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Link, NavLink } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { PersonRole, QueryKey } from '../enums';
import { useGetAuthorizedExplorerQuery } from '../hooks';
import { api } from '../utilities';
import { ThemeContext } from './ThemeContext';
import { IconSettings } from '../utilities';
import { WEB_VERSION } from '../version';
import { desktopVersionSuffix } from '../services/DesktopQueryService';

export const GlobalMenu = (): JSX.Element => {
   const themeContext = useContext(ThemeContext);
   const [isDarkMode, setIsDarkMode] = useState(false);

   const authedExplorer = useGetAuthorizedExplorerQuery().data;

   useEffect(() => {
      if (themeContext && themeContext.mode) {
         setIsDarkMode(themeContext.mode === 'dark');
      }
   }, [themeContext]);

   const handleToggle = () => {
      if (themeContext && themeContext.toggleMode) {
         themeContext.toggleMode();
      }
      setIsDarkMode((prevState) => !prevState);
   };

   const queryClient = useQueryClient();

   const signOutClicked = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      await api('DELETE', '/auth/1');
      queryClient.invalidateQueries([QueryKey.AUTH]);
      Sentry.setUser(null);
   };

   const hasPassword = !!authedExplorer?.hasPassword;

   return (
      <Dropdown>
         <Dropdown.Toggle className="nav-link" variant="link">
            <IconSettings size={20} />
         </Dropdown.Toggle>
         <Dropdown.Menu>
            <Dropdown.ItemText className="fs-11p">
               runQL v{WEB_VERSION}
               {desktopVersionSuffix()}
            </Dropdown.ItemText>
            <Dropdown.Divider />
            {authedExplorer?.person.role === PersonRole.ORG_ADMIN && (
               <>
                  <Dropdown.Item as={NavLink} to={'/admin/accounts'}>
                     Org Accounts
                  </Dropdown.Item>
                  <Dropdown.Item as={NavLink} to={'/admin/settings'}>
                     Org Settings
                  </Dropdown.Item>
                  <Dropdown.Item as={NavLink} to={'/admin/compliance'}>
                     Compliance
                  </Dropdown.Item>
                  <Dropdown.Divider />
               </>
            )}
            <Dropdown.Item as={'button'} onClick={() => handleToggle()}>
               {isDarkMode ? 'Light' : 'Dark'} Mode
            </Dropdown.Item>
            {hasPassword && (
               <Dropdown.Item as={NavLink} to={'/change-password'}>
                  Change Password
               </Dropdown.Item>
            )}
            <Dropdown.Divider />
            <Dropdown.Item as={Link} onClick={signOutClicked} to={'/'}>
               Sign out
            </Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
   );
};

export default GlobalMenu;
