import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Alert, Button, Col, Form, FormText, Modal, Row, Spinner } from 'react-bootstrap';
import { useInjection } from 'inversify-react';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

import { TYPES } from '../../types';
import { QueryKey } from '../../enums';
import type { Plan, Pricing } from '../../services';
import { Person } from '../../entities';
import { PersonService, SubscriptionService } from '../../services';
import { useOrg } from '../../hooks';

interface Props {
   onClose(): void;
   show: boolean;
}

type ChangePlanResult = {
   amountDue?: number;
   checkoutSecret?: string;
   items?: any;
};

function formatPrice(cents: number) {
   let dollars = Math.floor(cents / 100);
   let remainingCents = cents % 100;
   return `$${dollars}.${remainingCents.toString().padStart(2, '0')}`;
}

export const UpgradeModal = ({ show, onClose }: Props): JSX.Element => {
   const [error, setError] = useState<string>();
   const [success, setSuccess] = useState<string>();
   const [busy, setBusy] = useState(false);
   const [newPlan, setNewPlan] = useState<number>();
   const [plans, setPlans] = useState<Plan[]>();
   const [paidSeats, setPaidSeats] = useState<number>();
   const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);
   const [checkoutSecret, setCheckoutSecret] = useState<string>();
   const [pricing, setPricing] = useState<Pricing>();
   const [waitForUpdate, setWaitForUpdate] = useState(false);

   const subscriptionService = useInjection<SubscriptionService>(TYPES.subscriptionService);
   const personService = useInjection<PersonService>(TYPES.personService);

   const queryClient = useQueryClient();
   const org = useOrg();
   const plan = org?.plan;
   const accounts = useQuery<Person[]>([QueryKey.Person, 'list'], () =>
      personService.listOptions({})
   );

   useEffect(() => setNewPlan(plan), [plan]);

   const freePlan = newPlan === 0;

   useEffect(() => {
      if (show) {
         subscriptionService.listPlans().then((plans) => {
            setPlans(plans.plans);
            setStripe(loadStripe(plans.stripeKey, {}));
         });
      }
   }, [subscriptionService, show]);

   useEffect(() => {
      if (show) {
         subscriptionService.getSeats().then((seats) => {
            setPaidSeats(seats);
         });
      }
   }, [subscriptionService, show]);

   const dirty = plan !== newPlan && !success;
   const ready = org && accounts && plans;

   useEffect(() => {
      if (newPlan === undefined || paidSeats === undefined) return;
      setPricing(undefined);
      subscriptionService.getPricing(newPlan, paidSeats).then((p) => setPricing(p ?? undefined));
   }, [subscriptionService, newPlan, paidSeats]);

   const changePlan = useCallback(async () => {
      if (newPlan === undefined) return;
      try {
         setError(undefined);
         setBusy(true);
         const ret = (await subscriptionService.changePlan(newPlan)) as ChangePlanResult;
         if (ret.checkoutSecret) {
            setCheckoutSecret(ret.checkoutSecret);
         } else {
            setWaitForUpdate(true);
            setSuccess('Plan updated!');
         }
      } catch (e) {
         setError((e as Error).message ?? 'Failed to change plan');
      }
      setBusy(false);
   }, [subscriptionService, newPlan]);

   useEffect(() => {
      if (!waitForUpdate) {
         return undefined;
      }
      if (plan === newPlan) {
         setWaitForUpdate(false);
         return undefined;
      }
      const invalidate = () => {
         queryClient.invalidateQueries([QueryKey.Org, 'list']);
         queryClient.invalidateQueries([QueryKey.Person, 'list']);
      };
      invalidate();
      const intervalId = setInterval(() => {
         invalidate();
      }, 750);
      return () => {
         clearInterval(intervalId);
      };
   }, [waitForUpdate, plan, newPlan, queryClient]);

   const close = useCallback(() => {
      setError(undefined);
      setSuccess(undefined);
      setCheckoutSecret(undefined);
      onClose();
   }, [onClose]);

   const downgradeToFree = !!plan && freePlan && !success;
   const callToAction =
      plan === 0 ? (downgradeToFree ? 'Downgrade to free' : 'Upgrade') : 'Change Plan';

   if (checkoutSecret) {
      return (
         <Modal centered={true} className="p-0" onHide={() => close()} show={show}>
            <Modal.Body>
               <EmbeddedCheckoutProvider
                  options={{
                     clientSecret: checkoutSecret,
                     onComplete: () => {
                        setSuccess('Subscription activated!');
                        setWaitForUpdate(true);
                     },
                  }}
                  stripe={stripe}
               >
                  <EmbeddedCheckout />
               </EmbeddedCheckoutProvider>
            </Modal.Body>
            {success && !waitForUpdate && (
               <Modal.Footer>
                  <Button className="btn btn-sm" onClick={close}>
                     Close
                  </Button>
               </Modal.Footer>
            )}
         </Modal>
      );
   }
   return (
      <Modal centered={true} onHide={() => close()} show={show}>
         <Modal.Header className="border-0" closeButton={true}>
            {callToAction}
         </Modal.Header>
         <Modal.Body>
            {!ready && <Spinner />}
            {ready && (
               <Form onSubmit={changePlan}>
                  <Row>
                     <Col sm={6} xs={12}>
                        <Form.Label>Plan</Form.Label>
                        <Form.Select
                           onChange={(event) => {
                              setNewPlan(parseInt(event.currentTarget.value));
                           }}
                           required
                           value={newPlan}
                        >
                           {plans.map((plan) => (
                              <option key={plan.id} value={plan.id}>
                                 {plan.name}
                              </option>
                           ))}
                        </Form.Select>
                     </Col>
                     <Col sm={6} xs={12}>
                        <Form.Label>Paid seats</Form.Label>
                        <Form.Control readOnly type="text" value={paidSeats} />
                     </Col>
                  </Row>
               </Form>
            )}
            {error && (
               <Alert className="mt-3" variant="danger">
                  {error}
               </Alert>
            )}
            {success && !waitForUpdate && (
               <Alert className="mt-3" variant="success">
                  {success}
               </Alert>
            )}
            {downgradeToFree && (
               <Alert className="mt-3" variant="danger">
                  All roles except the Account Administrator will be downgraded to Basic Explorer.
               </Alert>
            )}
         </Modal.Body>
         <Modal.Footer>
            <div
               style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
               }}
            >
               <div>
                  {dirty && !pricing && <Spinner className="spinner-border-sm" />}
                  {dirty && pricing && (
                     <FormText style={{ lineHeight: 1 }}>
                        <div>Due now: {formatPrice(pricing.dueNow)} USD + tax</div>
                        <div className="mt-1">
                           Due monthly: {formatPrice(pricing.dueMonthly)} USD + tax
                        </div>
                     </FormText>
                  )}
               </div>
               <div>
                  {!success && (
                     <Button
                        className={`btn btn-sm ${downgradeToFree ? 'btn-danger' : ''}`}
                        disabled={busy || waitForUpdate || !ready || !dirty}
                        onClick={changePlan}
                     >
                        {callToAction}
                     </Button>
                  )}
                  {waitForUpdate && <Spinner className="spinner-border-sm" />}
                  {success && !waitForUpdate && (
                     <Button className="btn btn-sm" onClick={close}>
                        Close
                     </Button>
                  )}
               </div>
            </div>
         </Modal.Footer>
      </Modal>
   );
};

export default UpgradeModal;
