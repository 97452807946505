import { inject, injectable } from 'inversify';
import { QuerySuggestion } from '../entities/QuerySuggestion';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { LogSearchResponse, QuerySuggestionResponse } from '../entities';

const PATH = '/v1/search';

// URLs > 10,000 characters will result in request errors (e.g. net::ERR_FAILED 414 (URI Too Large)
// from Chrome). Also, the URLSearchParams encoding adds additional characters to the URL, so we are
// leaving some buffer room.
const MAX_QUERY_LENGTH = 6000;

@injectable()
export class SearchService {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async querySuggest({
      workspaceId,
      query,
      dataConnectionId,
   }: {
      dataConnectionId?: number;
      query: string;
      workspaceId: number;
   }): Promise<QuerySuggestion[]> {
      if (query.length > MAX_QUERY_LENGTH) {
         // Truncate the query to prevent errors
         query = query.slice(0, MAX_QUERY_LENGTH);
      }

      const result = await this.apiService.get<QuerySuggestion[]>(`${PATH}/querySuggest`, {
         workspaceId: workspaceId.toString(),
         query: query,
         ...(dataConnectionId !== undefined
            ? { dataConnectionId: dataConnectionId.toString() }
            : {}),
      });

      if (!result) {
         return [];
      }

      return result;
   }

   async globalSearch(q: string): Promise<QuerySuggestionResponse> {
      const result = await this.apiService.get<QuerySuggestionResponse>(`${PATH}/globalSearch`, {
         searchText: q,
      });
      return result as any;
   }

   async search(
      dataConnectionIds: number[],
      schemaNames: string[],
      tableNames: string[],
      creatorIds: number[],
      workspaceIds: number[],
      from: number,
      pageSize: number,
      searchText?: string,
      allWorkspaces: boolean = false
   ): Promise<QuerySuggestionResponse> {
      // Convert arrays to comma-separated strings for query parameters
      const params = {
         dataConnectionIds: dataConnectionIds.join(','),
         schemaNames: schemaNames.join(','),
         tableNames: tableNames.join(','),
         creatorIds: creatorIds.join(','),
         workspaceIds: workspaceIds.join(','),
         from: from.toString(),
         pageSize: pageSize.toString(),
         searchText: searchText ?? '',
         ...(allWorkspaces ? { allWorkspaces: 'true' } : {}),
      };

      if (!workspaceIds) {
         return this.globalSearch(searchText ?? '');
      }
      const result = await this.apiService.get<QuerySuggestionResponse>(`${PATH}`, params);

      return result as any;
   }

   async logSearch(
      dataConnectionIds: number[],
      schemaNames: string[],
      tableNames: string[],
      createdByPersonIds: number[],
      workspaceIds: number[],
      from: number,
      pageSize: number,
      searchText?: string,
      allWorkspaces: boolean = false
   ): Promise<LogSearchResponse | undefined> {
      // Convert arrays to comma-separated strings for query parameters
      const params = {
         dataConnectionIds: dataConnectionIds.join(','),
         schemaNames: schemaNames.join(','),
         tableNames: tableNames.join(','),
         creatorIds: createdByPersonIds.join(','),
         workspaceIds: workspaceIds.join(','),
         from: from.toString(),
         pageSize: pageSize.toString(),
         searchText: searchText ?? '',
         ...(allWorkspaces ? { allWorkspaces: 'true' } : {}),
      };

      const result = await this.apiService.get<LogSearchResponse>(`${PATH}/logSearch`, params);

      if (!result) {
         return undefined;
      }

      return result;
   }

   cast(item: QuerySuggestion): void {
      if (!item) {
         return;
      }
   }
}
