import { useState, useMemo, createContext, useContext, useCallback, memo } from 'react';
import { Button, Modal, Collapse, Spinner, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useRunSystemQuery } from '../hooks';
import CodeViewer from '../components/UI/CodeViewer';
import { queryBuilderFactory } from '../utilities/QueryBuilder';

import { handleError } from '../utilities/error';
import { DBMS } from '../enums/dbms';
import { ConnectionAccessType } from '../entities';

type TableData = {
   dataConnection: { catalogName: string | null; dbms: DBMS; id: number };
   schemaName: string;
   tableName: string;
};

export const canDeleteTable = ({
   connectionAccessType,
   dbms,
}: {
   connectionAccessType: ConnectionAccessType;
   dbms: DBMS;
}) => {
   if (
      ![ConnectionAccessType.INDIVIDUAL, ConnectionAccessType.SHARED].includes(connectionAccessType)
   ) {
      return false;
   }

   switch (dbms) {
      case DBMS.Big_Query:
      case DBMS.MSSQL:
      case DBMS.MySQL:
      case DBMS.Postgres:
      case DBMS.Redshift:
      case DBMS.Snowflake:
      case DBMS.Databricks:
      case DBMS.Oracle:
      case DBMS.Trino:
         return true;
      default:
         return false;
   }
};

type OnHideFn = (success?: boolean) => void;

const DeleteTableModalBody = ({
   onHide,
   tableData,
   workspaceId,
}: {
   onHide: OnHideFn;
   tableData: TableData;
   workspaceId: number;
}): JSX.Element => {
   const [showQuery, setShowQuery] = useState(false);
   const [force, setForce] = useState(false);

   const { run, isRunning } = useRunSystemQuery();

   const qb = useMemo(
      () => queryBuilderFactory(tableData.dataConnection.dbms),
      [tableData.dataConnection.dbms]
   );

   const query = useMemo(
      () =>
         qb
            .deleteTable({
               catalog: tableData.dataConnection.catalogName ?? undefined,
               schema: tableData.schemaName,
               table: tableData.tableName,
               force: qb.supportsDisablingForeignKeyChecks && force,
            })
            .join('\n'),
      [qb, tableData, force]
   );

   const handleChangeForce = (e: React.ChangeEvent<HTMLInputElement>) => {
      setForce(e.target.checked);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
         await run({
            dataConnection: tableData.dataConnection.id,
            query,
            workspaceId,
            updateSchema: { value: true },
         });

         toast.success('Table deleted successfully');
         onHide(true);
      } catch (err) {
         handleError(err);
      }
   };

   return (
      <>
         <Modal.Header closeButton>
            <Modal.Title className="fs-14p">Delete Table '{tableData.tableName}'?</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row">
               <div className="col-12 fs-14p">
                  <p>
                     Are you sure you want to delete the table{' '}
                     <span className="fw-medium">
                        `{tableData.schemaName}`.`
                        {tableData.tableName}`
                     </span>
                     ?
                  </p>
                  <p> This operation cannot be undone.</p>
               </div>
            </div>
            <Form id="deleteTableForm" onSubmit={handleSubmit}>
               {qb.supportsDisablingForeignKeyChecks ? (
                  <div className="row">
                     <div className="col-12">
                        <Form.Check
                           checked={force}
                           id="toggleIntegrityChecksSwitch"
                           label="Disable integrity checks?"
                           onChange={handleChangeForce}
                           type="switch"
                        />
                     </div>
                  </div>
               ) : null}
               <div className="row">
                  <div className="col-12 d-flex justify-content-end mt-3">
                     <Button
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => {
                           setShowQuery(!showQuery);
                        }}
                     >
                        {showQuery ? `Hide` : `Show`} Query
                     </Button>
                     <Button
                        className="btn btn-sm btn-secondary me-1"
                        disabled={isRunning}
                        onClick={() => {
                           onHide();
                        }}
                     >
                        Cancel
                     </Button>
                     <Button className="btn btn-sm btn-primary" disabled={isRunning} type="submit">
                        {isRunning ? (
                           <>
                              <Spinner
                                 animation="border"
                                 aria-hidden="true"
                                 as="span"
                                 role="status"
                                 size="sm"
                              />
                              <span className="visually-hidden">Loading...</span>&nbsp;
                           </>
                        ) : null}
                        Confirm
                     </Button>
                  </div>
               </div>
            </Form>
            <div className="row">
               <div className="col-12">
                  <Collapse in={showQuery}>
                     <div className="card query-card border-1 mt-4 p-4 fs-14p">
                        <CodeViewer query={query} />
                     </div>
                  </Collapse>
               </div>
            </div>
         </Modal.Body>
      </>
   );
};

type ModalProps = {
   onHide?: OnHideFn;
   tableData: TableData | null;
   workspaceId?: number;
};
export const DeleteTableModal = memo(
   ({ onHide = () => {}, tableData, workspaceId }: ModalProps) => {
      return (
         <Modal
            onHide={() => {
               onHide();
            }}
            show={tableData !== null}
         >
            {workspaceId !== undefined && tableData !== null ? (
               <DeleteTableModalBody
                  onHide={onHide}
                  tableData={tableData}
                  workspaceId={workspaceId}
               />
            ) : null}
         </Modal>
      );
   }
);

export default DeleteTableModal;

type OpenModalInput = {
   onHide?: (success?: boolean) => Promise<void> | void;
   tableData: TableData;
   workspaceId: number;
};
const DeleteTableModalContext = createContext<
   { openModal: (params: OpenModalInput) => void } | undefined
>(undefined);

const defaultModalProps = {
   tableData: null,
};
export const DeleteTableModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
   const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);

   const openModal = useCallback(({ tableData, workspaceId, onHide }: OpenModalInput) => {
      const onHideWrapped = async (success?: boolean) => {
         await onHide?.(success);
         setModalProps(defaultModalProps);
      };
      setModalProps({ tableData, workspaceId, onHide: onHideWrapped });
   }, []);

   return (
      <DeleteTableModalContext.Provider value={{ openModal }}>
         <>
            {children}
            <DeleteTableModal {...modalProps} />
         </>
      </DeleteTableModalContext.Provider>
   );
};

export const useDeleteTableModal = () => {
   const context = useContext(DeleteTableModalContext);

   if (context === undefined) {
      throw new Error('useDeleteTableModal must be used within a DeleteTableModalProvider');
   }

   return context.openModal;
};
