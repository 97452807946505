import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { WorkspaceSchemaConnection } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';
import {
   WorkspaceSchemaConnectionGetOptions,
   WorkspaceSchemaConnectionListOptions,
   WorkspaceSchemaConnectionService,
} from '../../services';
import { TYPES } from '../../types';
import { notUndefined } from '../../utilities';
import { getDataConnectionQueryKey } from './dataConnectionHooks';
import { getWorkspaceQueryKey } from './workspaceHooks';

const useWorkspaceSchemaConnectionService = () => {
   return useInjection<WorkspaceSchemaConnectionService>(TYPES.workspaceSchemaConnectionService);
};

export const useGetWorkspaceSchemaConnectionQuery = ({
   callbacks,
   id,
   options,
}: {
   callbacks?: {
      errorCallback?: (err: unknown) => void;
      successCallback?: (data: WorkspaceSchemaConnection | undefined) => void;
   };
   id: number | undefined;
   options?: WorkspaceSchemaConnectionGetOptions;
}) => {
   const workspaceConnectionService = useWorkspaceSchemaConnectionService();
   return useQuery<WorkspaceSchemaConnection | undefined>(
      getWorkspaceConnectionQueryKey({ type: QueryKeyType.GET, id: id, options: options }),
      () => (id !== undefined ? workspaceConnectionService.getOptions(id, options) : undefined),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback,
      }
   );
};

export const useListWorkspaceConnectionsQuery = (
   filters?: WorkspaceSchemaConnectionListOptions,
   callbacks?: {
      errorCallback?: (error: unknown) => void;
      successCallback?: (data: WorkspaceSchemaConnection[]) => void;
   }
) => {
   const workspaceConnectionService = useWorkspaceSchemaConnectionService();
   return useQuery<WorkspaceSchemaConnection[]>(
      getWorkspaceConnectionQueryKey({ type: QueryKeyType.LIST, options: filters }),
      () => workspaceConnectionService.listOptions(filters),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback,
      }
   );
};

export const useFetchListWorkspaceConnections = () => {
   const workspaceConnectionService = useInjection<WorkspaceSchemaConnectionService>(
      TYPES.workspaceSchemaConnectionService
   );
   const queryClient = useQueryClient();
   return useCallback(
      (filters?: WorkspaceSchemaConnectionListOptions) =>
         queryClient.fetchQuery<WorkspaceSchemaConnection[]>(
            getWorkspaceConnectionQueryKey({ type: QueryKeyType.LIST, options: filters }),
            () => workspaceConnectionService.listOptions(filters)
         ),
      [queryClient, workspaceConnectionService]
   );
};

export const useNewWorkspaceConnectionMutation = () => {
   const queryClient = useQueryClient();
   const workspaceConnectionService = useInjection<WorkspaceSchemaConnectionService>(
      TYPES.workspaceSchemaConnectionService
   );
   return useMutation({
      mutationFn: async (newWorkspaceConnection: WorkspaceSchemaConnection) => {
         return workspaceConnectionService.put(newWorkspaceConnection);
      },
      async onSuccess(data, newWorkspaceConnection, context) {
         await Promise.all([
            queryClient.invalidateQueries(
               getWorkspaceConnectionQueryKey({ type: QueryKeyType.LIST })
            ),
            queryClient.invalidateQueries(
               getWorkspaceQueryKey({
                  type: QueryKeyType.GET,
                  id: newWorkspaceConnection.workspaceId,
               })
            ),
         ]);
      },
   });
};

export const useDeleteWorkspaceConnectionMutation = () => {
   const queryClient = useQueryClient();
   const workspaceConnectionService = useInjection<WorkspaceSchemaConnectionService>(
      TYPES.workspaceSchemaConnectionService
   );
   return useMutation({
      mutationFn: async (toDelete: WorkspaceSchemaConnection | number) => {
         if (typeof toDelete === 'number') {
            return workspaceConnectionService.delete(toDelete);
         } else {
            if (toDelete.id === undefined) {
               throw new Error('Deleting a workspace connection required an ID');
            }
            return workspaceConnectionService.delete(toDelete.id);
         }
      },
      async onSuccess(data, toDelete, context) {
         let toDeleteId: number;
         let workspaceId: number | undefined = undefined;
         let dataConnectionId: number | undefined = undefined;

         if (typeof toDelete === 'number') {
            toDeleteId = toDelete;
         } else {
            toDeleteId = notUndefined(toDelete.id);
            workspaceId = toDelete.workspaceId;
            dataConnectionId = toDelete.dataConnectionId;
         }

         const promiseList: Promise<unknown>[] = [
            queryClient.invalidateQueries(
               getWorkspaceConnectionQueryKey({ type: QueryKeyType.LIST })
            ),
            queryClient.invalidateQueries(
               getWorkspaceConnectionQueryKey({ type: QueryKeyType.GET, id: toDeleteId })
            ),
            queryClient.invalidateQueries(getWorkspaceQueryKey({ type: QueryKeyType.LIST })),
         ];

         if (workspaceId) {
            promiseList.push(
               queryClient.invalidateQueries(
                  getWorkspaceQueryKey({ type: QueryKeyType.GET, id: workspaceId })
               )
            );
         }

         if (dataConnectionId) {
            promiseList.push(
               queryClient.invalidateQueries(
                  getDataConnectionQueryKey({ type: QueryKeyType.GET, id: dataConnectionId })
               )
            );
         }

         await Promise.all(promiseList);
      },
   });
};

export function getWorkspaceConnectionQueryKey(keyParams: {
   id?: number;
   options?: WorkspaceSchemaConnectionListOptions | WorkspaceSchemaConnectionGetOptions;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.WorkspaceSchemaConnection, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}
