import { useState } from 'react';
import classNames from 'classnames';
import { Nav, NavItem, NavLink, Stack, TabContainer, TabContent, TabPane } from 'react-bootstrap';

export type PageTab = {
   content: React.ReactNode;
   title: string;
};
export const PageTabs = ({
   action,
   tabs,
   className,
   noScroll,
}: {
   action?: React.ReactNode;
   className?: string;
   noScroll?: boolean;
   tabs: PageTab[];
}): JSX.Element => {
   const [tab, setTab] = useState('0');
   return (
      <TabContainer activeKey={tab} onSelect={(t) => t && setTab(t)} transition={false}>
         <div className="h-100 d-flex flex-column overflow-hidden">
            <Stack
               className={classNames('align-items-end w-100', className)}
               direction="horizontal"
               gap={2}
            >
               <Nav className="d-flex flex-row flex-nowrap align-items-center" role="tablist">
                  <Stack className="nav-tabs-alt d-flex flex-row" direction="horizontal" gap={2}>
                     {tabs.map((t, i) => (
                        <NavItem className={tab === i.toString() ? 'active' : ''} key={i}>
                           <NavLink
                              className="position-relative override-active-pointer"
                              eventKey={i.toString()}
                              role={'tab'}
                           >
                              {t.title}
                           </NavLink>
                        </NavItem>
                     ))}
                  </Stack>
               </Nav>
               {action}
            </Stack>

            <TabContent className="flex-grow-1 overflow-hidden">
               {tabs.map((t, i) => (
                  <TabPane
                     className={classNames('h-100 p-3 pb-0', { 'overflow-auto': !noScroll })}
                     eventKey={i}
                     key={i}
                  >
                     {t.content}
                  </TabPane>
               ))}
            </TabContent>
         </div>
      </TabContainer>
   );
};

export default PageTabs;
