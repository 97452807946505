import classNames from 'classnames';
import { Badge, Nav, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { NavLink, To, useLocation } from 'react-router-dom';
import { IconType } from 'react-icons';

export const NavRailButton = ({
   count,
   eventKey,
   icon,
   title,
   to,
   secret,
   exact,
}: {
   count?: number;
   eventKey?: string;
   exact?: boolean;
   icon: IconType;
   secret?: boolean;
   title: string;
   to?: To;
}): JSX.Element => {
   const location = useLocation();
   return (
      <>
         <OverlayTrigger
            delay={{ show: 500, hide: 0 }}
            key={title}
            overlay={secret ? <></> : <Tooltip>{title}</Tooltip>}
            placement="right"
         >
            <Nav.Link
               as={to ? NavLink : undefined}
               className={classNames('position-relative', {
                  sectionActive:
                     !!to &&
                     (exact
                        ? location.pathname === to.toString()
                        : location.pathname.startsWith(to.toString() || '')),
                  secret,
               })}
               end
               eventKey={eventKey}
               role={'tab'}
               // @ts-ignore
               to={to}
            >
               {icon({ size: '20px' })}
               {!!count && (
                  <Badge
                     className="fs-10p position-absolute"
                     pill
                     style={{
                        top: '4px',
                        right: '2px',
                        height: '14px',
                        lineHeight: '14px',
                        padding: '0 4px',
                     }}
                  >
                     {count}
                  </Badge>
               )}
            </Nav.Link>
         </OverlayTrigger>
      </>
   );
};

export const NavRail = ({
   bottom,
   children,
}: {
   bottom?: React.ReactNode;
   children: React.ReactNode;
}): JSX.Element => {
   return (
      <Nav className="nav-rail bg-nav">
         <Stack className="flex-grow-0 flex-shrink-0">
            <Stack className="flex-grow-1 margin-left-right-8">{children}</Stack>
            <Stack className="flex-grow-0 margin-left-right-8">{bottom}</Stack>
         </Stack>
      </Nav>
   );
};

export default NavRail;
