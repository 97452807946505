import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { useMutation, useQueryClient } from 'react-query';

import { SnippetService } from '../services';
import { QueryKey } from '../enums';
import { TYPES } from '../types';
import { useCurrentStep } from '../hooks';

export const useSaveSnippet = () => {
   const [currentStep] = useCurrentStep();
   const dataConnectionId = currentStep?.queryStep.dataConnectionId;
   const snippetService = useInjection<SnippetService>(TYPES.snippetService);
   const snippetKey = [QueryKey.Snippet, dataConnectionId];

   const queryClient = useQueryClient();
   const saveSnippet = useMutation({
      mutationFn: async (snippet: string) => {
         if (!dataConnectionId) return;
         await snippetService.create(dataConnectionId, snippet);
         await queryClient.invalidateQueries(snippetKey);
      },
   });
   return useCallback(
      (snippet: string) => {
         saveSnippet.mutate(snippet);
      },
      [saveSnippet]
   );
};
