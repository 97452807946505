import { format } from 'sql-formatter';
/** Takes a Date object and returns a string in the short date format
 *
 * @param toFormat The Date object to format
 * @returns A string in the format YYYY-MM-dd HH:mm:ss AA
 */
export function getShortDateTimeString(toFormat?: Date | string): string {
   if (toFormat === undefined) {
      toFormat = new Date();
   }

   // Desired format: YYYY-MM-dd HH:mm:ss AA
   // Example: 2023-02-02 10:40 AM
   return `${new Date(toFormat).toLocaleDateString('sv-SE', {
      dateStyle: 'short',
   })} ${new Date(toFormat).toLocaleTimeString('en', { timeStyle: 'medium' })}`;
}

export function getShorterDateTimeString(toFormat?: Date | string): string {
   if (toFormat === undefined) {
      toFormat = new Date();
   }

   // Desired format: YYYY-MM-dd HH:mm:ss AA
   // Example: 2023-02-02 10:40 AM
   return `${new Date(toFormat)
      .toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
      .replace(/AM|PM/g, '')
      .trim()}`;
}

export function getShortDateString(toFormat?: Date | string): string {
   if (toFormat === undefined) {
      toFormat = new Date();
   }
   // Desired format: YYYY-MM-dd
   // Example: 2023-02-02
   return `${new Date(toFormat).toLocaleDateString('sv-SE', {
      dateStyle: 'short',
   })}`;
}

export function removeNull<T>(obj: T | any) {
   Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') removeNull(obj[key]);
      else if (obj[key] == null) delete obj[key];
   });
   return obj;
}

export function notUndefined<T>(obj: T | undefined, undefinedValue?: T): T {
   if (obj === undefined) {
      if (undefinedValue === undefined) {
         throw new Error('Undefined value');
      } else {
         return undefinedValue;
      }
   }

   return obj;
}

// eslint-disable-next-line no-extend-native
export const toTitleCase = (toConvert: string): string => {
   var i, j, str, lowers, uppers;
   str = toConvert.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
   });

   // Certain minor words should be left lowercase unless
   // they are the first or last words in the string
   lowers = [
      'A',
      'An',
      'The',
      'And',
      'But',
      'Or',
      'For',
      'Nor',
      'As',
      'At',
      'By',
      'For',
      'From',
      'In',
      'Into',
      'Near',
      'Of',
      'On',
      'Onto',
      'To',
      'With',
   ];
   for (i = 0, j = lowers.length; i < j; i++)
      str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), function (txt) {
         return txt.toLowerCase();
      });

   // Certain words such as initialisms or acronyms should be left uppercase
   uppers = ['Id', 'Tv'];
   for (i = 0, j = uppers.length; i < j; i++)
      str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), uppers[i].toUpperCase());

   return str;
};

export function normalizeType(type: string) {
   if (!type) return 'STRING';
   type = type.toUpperCase();
   if (/^INT\d*$/.test(type)) {
      return 'INT';
   }
   return type;
}

export const formatQuery = (unformattedQuery: string) => {
   try {
      let formattedQuery = unformattedQuery;
      // temporary replace parameters so formatter can work
      formattedQuery = formattedQuery.replaceAll('{{', '`{{');
      formattedQuery = formattedQuery.replaceAll('}}', '}}`');
      formattedQuery = format(formattedQuery);
      // change parameters back
      formattedQuery = formattedQuery.replaceAll('`{{', '{{');
      formattedQuery = formattedQuery.replaceAll('}}`', '}}');
      return formattedQuery;
   } catch (err) {
      return unformattedQuery;
   }
};

/**
 * Parses a date and time string in the format "YYYY-MM-DD HH:MM:SS" and returns a Date object.
 */
export function parseDateFromDateTime(input: string): Date {
   const [year, month, day, hours, minutes, seconds] = input
      .trim()
      .replace(/ +/, ' ')
      .split(/[- :]/)
      .map(Number);
   return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
}

export function formatDateToYYYYMMDD(date: Date): string {
   const year = date.getFullYear();
   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
   const day = date.getDate().toString().padStart(2, '0');

   return `${year}-${month}-${day}`;
}
export function parseDateFromYYYYMMDD(input: string): Date {
   const [year, month, day] = input.split('-').map(Number);
   return new Date(year, month - 1, day);
}
