import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
   createRoutesFromChildren,
   matchRoutes,
   useLocation,
   useNavigationType,
} from 'react-router-dom';

Sentry.init({
   dsn: process.env.REACT_APP_SENTRY_DSN,
   environment: process.env.REACT_APP_APP_ENV,
   release: process.env.REACT_APP_SENTRY_RELEASE,
   integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
         useEffect,
         useLocation,
         useNavigationType,
         createRoutesFromChildren,
         matchRoutes,
      }),
   ],
   tracesSampleRate:
      typeof process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE === 'string' &&
      process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE.trim() !== ''
         ? parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE.trim())
         : 1.0,

   // Session replay will not be enabled until we add the Sentry.replayIntegration integration.
   replaysSessionSampleRate: 0.1,
   replaysOnErrorSampleRate: 1.0,
});
