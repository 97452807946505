import { Image } from 'react-bootstrap';
import classNames from 'classnames';

import { desktopPlatform } from '../services/DesktopQueryService';
import { useListQueryQuery } from '../hooks';
import logo from '../assets/img/runQL-logo-prompt.svg';
import { MdQuestionAnswer } from 'react-icons/md';

import { NavRail, NavRailButton, GlobalMenu, AiPulse } from '.';
import {
   IconMenuCertRequests,
   IconMenuDataSources,
   IconMenuDocs,
   IconMenuHome,
   IconMenuQueryChanges,
   IconMenuWorkspaces,
} from '../utilities/icons';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { walkthroughStep } from '../entities/Walkthrough';

export const GlobalNavRail = (): JSX.Element => {
   const queryUpdateCount = useListQueryQuery({
      listOptions: {
         version: 'ai-suggestions',
         countOnly: true,
      },
   });
   const queryReviewCount = useListQueryQuery({
      listOptions: {
         version: 'review',
         countOnly: true,
      },
   });
   const queryUpdates = queryUpdateCount?.data?.totalItems;
   const queryReviews = queryReviewCount?.data?.totalItems;
   const [stepTwelve, setStepTwelve] = useWalkthroughStep(walkthroughStep.DATA_SOURCES);

   return (
      <NavRail
         bottom={
            <>
               <AiPulse
                  on={stepTwelve}
                  onClick={() => {
                     if (stepTwelve) {
                        setStepTwelve();
                     }
                  }}
                  sparkleAfter
               >
                  <NavRailButton icon={IconMenuDataSources} title="Data Sources" to="/sources" />
               </AiPulse>
               <NavRailButton icon={IconMenuDocs} title="Documentation" to="/documentation" />
               <GlobalMenu />
            </>
         }
      >
         <a
            className={classNames('m-1 p-2', { 'mt-4': desktopPlatform() === 'darwin' })}
            href="https://runql.com"
            rel="noreferrer"
            target="_blank"
         >
            <Image alt="runQL Logo" src={logo} style={{ height: 'auto', width: '24px' }} />
         </a>
         <NavRailButton exact icon={IconMenuHome} title="Home" to="/" />
         <NavRailButton icon={IconMenuWorkspaces} title="Workspaces" to="/workspaces" />
         <NavRailButton
            count={queryUpdates}
            icon={IconMenuQueryChanges}
            title="Schema Change - Recommended Query Changes"
            to="/query-updates"
         />
         <NavRailButton
            count={queryReviews}
            icon={IconMenuCertRequests}
            title="Certification Requests"
            to="/query-review"
         />
         <NavRailButton icon={MdQuestionAnswer} secret title="Answers" to="/answers" />
      </NavRail>
   );
};

export default GlobalNavRail;
