import { inject, injectable } from 'inversify';

import { TYPES } from '../types';
import { SSHAuthenticationMethod } from '../entities';

import type { Query, DbmsConfig, CredentialData, ReadSchemaTarget } from '@runql/util';
import type { LocalCredentialService } from './LocalCredentialService';
import type { DataConnection } from '../entities';

type Step = {
   dataConnection: DataConnection;
   queryText: string;
   schemaName?: string;
};

export function isDesktop() {
   return globalThis.runql !== undefined;
}

export function desktopPlatform() {
   if (!globalThis.runql) return undefined;
   return globalThis.runql.platform;
}

export function desktopVersionSuffix() {
   if (!globalThis.runql) return '';
   return `-${globalThis.runql.platform}-${globalThis.runql.version}`;
}

@injectable()
export class DesktopQueryService {
   constructor(
      @inject(TYPES.localCredentialService) private localCredentialService: LocalCredentialService
   ) {}

   // Mutates sshKeyFileCache.
   private async generateDbmsConfig({
      dataConnection,
      credentialData,
      sshKeyFileCache = {},
   }: {
      credentialData?: CredentialData;
      dataConnection: DataConnection;
      sshKeyFileCache?: Record<string, { encoding: string; file: string }>;
   }): Promise<DbmsConfig> {
      let sshKeyFile: { encoding: string; file: string } | undefined = undefined;
      if (
         globalThis.runql &&
         credentialData?.sshAuthMethod === SSHAuthenticationMethod.KEY_FILE &&
         credentialData?.sshKeyFile
      ) {
         const filePath = credentialData.sshKeyFile;
         if (!sshKeyFileCache[filePath]) {
            const readFileResult = await globalThis.runql.readFile(filePath);

            if (readFileResult === null) {
               throw new Error('Error reading SSH key file');
            }

            sshKeyFileCache[filePath] = readFileResult;
         }

         sshKeyFile = sshKeyFileCache[filePath];
      }

      return {
         password: '',
         ...dataConnection,
         ...(credentialData
            ? {
                 username: credentialData.accountName,
                 password: credentialData.accountPassword ?? '',
                 sshAuthMethod: credentialData.sshAuthMethod,
                 sshUsername: credentialData.sshUsername,
                 sshPassword: credentialData.sshPassword,
              }
            : {}),
         sshKeyFile,
      } as DbmsConfig;
   }

   async readSchema(dataConnection: DataConnection, target?: ReadSchemaTarget) {
      if (!globalThis.runql) {
         throw new Error('Local schema reads are not supported');
      }

      if (!dataConnection.id) {
         return [];
      }

      const credentialData = await this.localCredentialService.get(dataConnection.id);

      const config = await this.generateDbmsConfig({
         dataConnection,
         credentialData,
      });

      return globalThis.runql.readSchema(config, target);
   }

   async runQuery(
      steps: Step | Step[],
      params: Record<string, string> = {},
      subquery?: { query: string; step: number }
   ) {
      if (!globalThis.runql) {
         throw new Error('Local queries not supported');
      }

      if (!Array.isArray(steps)) {
         steps = [steps];
      }

      // Find unique dataConnectionIds
      const dataConnectionIds = Array.from(
         new Set(steps.map((step) => step.dataConnection.id))
      ).filter((n) => n !== undefined);

      const localCredentialsMap = await this.localCredentialService.getMap(
         dataConnectionIds as number[]
      );

      const sshKeyFileCache = {};
      const queries: Query[] = await Promise.all(
         steps.map(async (step, index) => {
            const config = await this.generateDbmsConfig({
               dataConnection: step.dataConnection,
               credentialData: step.dataConnection.id
                  ? localCredentialsMap[step.dataConnection.id]
                  : undefined,
               sshKeyFileCache,
            });
            config.schemaName = step.schemaName;
            config.dbName = step.schemaName;
            return {
               query:
                  subquery?.step === index + 1 && subquery?.query
                     ? subquery?.query
                     : step.queryText,
               config,
            } as Query;
         })
      );

      return globalThis.runql.run(queries, params);
   }

   // When testing changes to an existing connection, `credentialData` will contain `undefined`
   // values for 'accountPassword' and/or 'sshPassword' if their values were not changed.
   async testConnection({
      credentialData = {},
      dataConnection,
   }: {
      credentialData?: CredentialData;
      dataConnection: DataConnection;
   }) {
      if (!globalThis.runql) {
         throw new Error('Local queries not supported');
      }

      const existingCredentialData = dataConnection.id
         ? await this.localCredentialService.get(dataConnection.id)
         : undefined;

      const { accountPassword, sshPassword } = existingCredentialData ?? {};

      // We need to omit undefined values from `credentialData` for the merging to work as desired.
      const credentialDataToTest = {
         accountPassword,
         sshPassword,
         ...Object.fromEntries(
            Object.entries(credentialData).filter(([_, value]) => value !== undefined)
         ),
      };

      const config = await this.generateDbmsConfig({
         credentialData: credentialDataToTest,
         dataConnection,
      });

      return globalThis.runql.testConnection(config);
   }
}
