export enum QueryKey {
   AUTH = 'auth',
   DataConnection = 'dataConnection',
   DataConnectionSchemas = 'DataConnectionSchemas',
   EditorSchemaForDataConnection = 'EditorSchemaForDataConnection',
   EligibleCollaborators = 'EligibleCollaborators',
   ExploreTab = 'exploreTab',
   Invite = 'Invite',
   MetaForTableId = 'MetaForTableId',
   Org = 'org',
   OrgRolePermission = 'OrgRolePermission',
   Person = 'person',
   Queries = 'queries',
   QueryComments = 'QueryComments',
   QueryLog = 'queryLog',
   QueryResult = 'queriesResult',
   QueryStep = 'queryStep',
   SavedQuery = 'savedQuery',
   SchemaCacheForOrg = 'schemaCacheForOrg',
   SchemaForDataSource = 'schemaForDataSource',
   Snippet = 'snippet',
   TableContentForTableSchemaId = 'TableContentForTableSchemaId',
   Walkthrough = 'Walkthrough',
   WorkflowCount = 'WorkflowCount',
   Workspace = 'workspace',
   WorkspaceCollaborator = 'workspaceCollaborator',
   WorkspaceCustomTags = 'workspaceCustomTags',
   WorkspaceLog = 'workspaceLog',
   WorkspaceSchema = 'WorkspaceSchema',
   WorkspaceSchemaConnection = 'WorkspaceSchemaConnection',
}

export enum QueryKeyType {
   GET = 'get',
   LIST = 'list',
}
