import { useState, useEffect } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

import { Button } from '../../../components';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { useUpdateSchemaVisibilityMutator, useUpdateSchema } from '../../../hooks';
import { useSchemaRoots, fetchSchemaRootsQueryKey } from './hooks';
import {
   fetchSchemaContentQueryKey,
   fetchTableContentQueryKey,
} from '../../../components/SchemaTree';

import type { DataConnection } from '../../../entities';
import type { SchemaRoot } from '../../../components/SchemaTree';

type FormData = {
   schemaRoots: SchemaRoot[];
};
const SelectVisibleSchemasForm = ({
   dataConnection,
   schemaRoots,
   refetchData,
}: {
   dataConnection: DataConnection;
   refetchData: () => Promise<void>;
   schemaRoots: SchemaRoot[];
}) => {
   const queryClient = useQueryClient();

   const {
      handleSubmit,
      register,
      formState: { isDirty, isSubmitting },
      reset,
   } = useForm<FormData>({ defaultValues: { schemaRoots } });

   // If we receive new data, reset the form. Keep dirty values if the table has not changed.
   useEffect(() => {
      reset({ schemaRoots }, { keepDirtyValues: true });
   }, [schemaRoots, reset]);

   const [isRefreshingSchema, setIsRefreshingSchema] = useState(false);
   const { updateSchema } = useUpdateSchema({
      onSuccessCallback: async () => {
         const keysToInvalidate = [
            // Schema roots
            fetchSchemaRootsQueryKey({
               type: 'invalidate',
               data: { dataConnectionId: dataConnection.id! },
            }),
            // Schema content
            fetchSchemaContentQueryKey({
               type: 'invalidate',
               data: { dataConnectionId: dataConnection.id! },
            }),
            // Table content
            fetchTableContentQueryKey({
               type: 'invalidate',
               data: { dataConnectionId: dataConnection.id! },
            }),
         ];
         await Promise.all(keysToInvalidate.map((key) => queryClient.invalidateQueries(key)));
      },
   });
   const handleClickRefreshSchema = async () => {
      const catalogName = dataConnection.catalogName ?? null;

      setIsRefreshingSchema(true);

      toast.info(
         <>
            Refreshing{' '}
            <span className="fw-semibold">{`${dataConnection.name}${
               catalogName !== null ? ` | ${catalogName}` : ''
            }`}</span>{' '}
            schema and generating metadata. This may take few minutes.
         </>
      );

      try {
         await updateSchema(dataConnection);

         await refetchData();
      } finally {
         setIsRefreshingSchema(false);
      }
   };

   const handleClickReset = () => {
      reset({ schemaRoots });
   };

   const updateVisibilityMutator = useUpdateSchemaVisibilityMutator({
      onSuccess: async () => {
         await queryClient.invalidateQueries(
            fetchSchemaRootsQueryKey({
               type: 'invalidate',
               data: { dataConnectionId: dataConnection.id! },
            })
         );
      },
   });
   const handleSave = async (values: FormData) => {
      const payload = {
         dataConnectionId: dataConnection.id!,
         body: {
            schemas: values.schemaRoots.map((schemaRoot) => ({
               catalog: dataConnection.catalogName ?? undefined,
               schema: schemaRoot.schemaName,
               visible: schemaRoot.isVisible,
            })),
         },
      };

      await updateVisibilityMutator.mutateAsync(payload);

      await refetchData();
   };

   return (
      <Form onSubmit={handleSubmit(handleSave)}>
         <div className="mb-3">
            {schemaRoots.map((schemaRoot, idx) => (
               <Form.Check
                  className="d-flex align-items-center gap-2 mb-0"
                  id={`schema-${schemaRoot.id}`}
                  key={schemaRoot.id}
                  type="checkbox"
               >
                  <Form.Check.Input
                     className="mt-0"
                     {...register(`schemaRoots.${idx}.isVisible`)}
                  />
                  <Form.Check.Label>{schemaRoot.schemaName}</Form.Check.Label>
               </Form.Check>
            ))}
         </div>
         <Stack
            className="justify-content-between"
            direction="horizontal"
            style={{ maxWidth: '450px' }}
         >
            <Button
               className="ms-0"
               colorScheme="secondary"
               disabled={isRefreshingSchema || isSubmitting}
               isLoading={isRefreshingSchema}
               onClick={handleClickRefreshSchema}
               variant="outline"
            >
               Refresh Schema
            </Button>
            <Stack direction="horizontal" gap={2}>
               <Button
                  colorScheme="secondary"
                  disabled={!isDirty || isSubmitting || isRefreshingSchema}
                  onClick={handleClickReset}
                  size="sm"
                  type="button"
               >
                  Reset
               </Button>
               <Button
                  disabled={!isDirty || isRefreshingSchema}
                  isLoading={isSubmitting}
                  size="sm"
                  type="submit"
               >
                  Save
               </Button>
            </Stack>
         </Stack>
      </Form>
   );
};

export const VisibleSchemasTabContent = ({
   dataConnection,
}: {
   dataConnection: DataConnection;
}) => {
   const dataConnectionId = dataConnection.id!;

   const schemaRootsQueryResult = useSchemaRoots({ dataConnectionId, includeHidden: true });

   if (schemaRootsQueryResult.isLoading) {
      return <LoadingSpinner />;
   }

   if (!schemaRootsQueryResult.data) {
      throw new Error('Error loading schemas');
   }

   const schemaRoots = schemaRootsQueryResult.data;

   if (schemaRoots.length === 0) {
      return <p>No schemas available!</p>;
   }

   return (
      <div>
         <span className="d-inline-block fs-14p fw-medium mb-2">
            Select the schemas you want to be visible for this connection:
         </span>
         <SelectVisibleSchemasForm
            dataConnection={dataConnection}
            refetchData={async () => {
               await schemaRootsQueryResult.refetch();
            }}
            schemaRoots={schemaRoots}
         />
      </div>
   );
};
